<template>
	<div>
        <div>
            <div v-if="!is_loading" class="bysuplier-table">
                <div class="bysuplier-itens-container" >
                    <div class="bysuplier-title-container">
                        <div class="bysuplier-title-text">Empresa: {{ user.group }}</div>
                        <div class="bysuplier-title-button">Total: {{ calculate_total(store) | customCurrency }}</div>
                    </div>
                    <div class="bysuplier-itens-header">
                        <div class="bysuplier-name">
                            <div class="bysuplier-icons"></div>
                            Fornecedor
                        </div>
                        <div class="bysuplier-date">Data Entrega</div>
                        <div class="bysuplier-cond">Cond. de Pgto.</div>
                        <div class="bysuplier-value">Valor</div>
                        <div class="bysuplier-min">Fat. Min.</div>
                        <div class="bysuplier-delivered">Tipo de Entrega</div>
                    </div>

                    <div v-for="(provider, idx) in pageSellers" :key="idx">
                        <div class="bysuplier-itens-body">
                            <div class="bysuplier-name">
                                <div class="bysuplier-icons">
                                    <span class="material-icons-outlined bysuplier-expand-icon" @click="show_products(provider)" :style="getExpandStyle(provider)">expand_more</span>
                                    <router-link :to="{ name: 'request-matrix-overview', params: { id: $route.params.id, sellerid: provider.idVendedor, providerid: provider.idFornecedor } }">
                                        <span class="material-icons-outlined bysuplier-cart-icon">shopping_cart</span>
                                    </router-link>
                                </div>
                                {{provider.nomeFornecedor}}
                            </div>
                            <div class="bysuplier-date">{{ provider.entrega ? provider.entrega.data_entrega : '' | formatDateOnly }}</div>
                            <div class="bysuplier-cond">{{ provider.entrega ? provider.entrega.forma : '' }}</div>
                            <div class="bysuplier-value">{{ provider.valorTotal | customCurrency }}</div>
                            <div class="bysuplier-min">{{ provider.entrega ? parseFloat(provider.entrega.faturamento_minimo) : '' | customCurrency }}</div>
                            <div class="bysuplier-delivered">{{ provider.tpc_nome }}</div>
                        </div>
                        <!-- mobile -->
                         <div class="mobile-itens-body">
                            <div class="bysuplier-name">
                                <div class="bysuplier-icons">
                                    <span class="material-icons-outlined bysuplier-expand-icon" @click="show_products(provider)" :style="getExpandStyle(provider)">expand_more</span>
                                    <router-link :to="{ name: 'request-matrix-overview', params: { id: $route.params.id, sellerid: provider.idVendedor, providerid: provider.idFornecedor } }">
                                        <span class="material-icons-outlined bysuplier-cart-icon">shopping_cart</span>
                                    </router-link>
                                </div>
                                {{provider.nomeFornecedor}}
                            </div>
                            <div style="display: flex; justify-content: space-between;">
                                <div>
                                    <h6>Data Entrega</h6>
                                    <span>{{ provider.entrega ? provider.entrega.data_entrega : '' | formatDateOnly }}</span>
                                </div>
                                <div class="display-center">
                                    <h6>Cond. de Pgto.</h6>
                                    <span>{{ provider.entrega ? provider.entrega.forma : '' }}</span>
                                </div>
                                <div class="display-end">
                                    <h6>Valor</h6>
                                    <span>{{ provider.valorTotal | customCurrency }}</span>
                                </div>
                            </div>
                         </div>
                    <cc-loader v-if="provider.loading_products" style="margin-top: 3vh; height: 90%;"/>
                    <div v-if="provider.open_products">
                        <div class="bysuplier-itens-header">
                            <div class="bysuplier-name">
                                <div class="bysuplier-products-icons"></div>
                                Produto
                            </div>
                            <div class="bysuplier-quantity">Quantidade</div>
                            <div class="bysuplier-price">Embalagem</div>
                            <div class="bysuplier-price">Preço</div>
                            <div class="bysuplier-last">Ultima Compra</div>
                            <div class="bysuplier-last">Variação</div>
                        </div>
                        <div v-for="(prod, idx) in provider.products" :key="idx">
                            <!-- desktop -->
                            <div :class="getSpecialBackground(idx)" class="bysuplier-product-row">
                                <div class="bysuplier-name">
                                    <div class="bysuplier-products-icons">
                                        <span class="material-icons-outlined bysuplier-expand-icon" @click="show_winners(prod)" :style="getExpandStyle(prod)">expand_more</span>
                                    </div>
                                    <div class="bysuplier-name-text" :title="prod.name">{{prod.name}}</div>
                                </div>
                                <div class="bysuplier-quantity">
                                    {{ prod.requested_quantity }}
                                </div>
                                <div class="bysuplier-price">
                                    {{ prod.packing }}
                                </div>
                                <div class="bysuplier-price">
                                    {{ prod.offer_price | customCurrency }}
                                </div>
                                <div class="bysuplier-last">
                                    {{ prod.last_purchase | customCurrency }}
                                </div>
                                <div class="bysuplier-last">
                                    <span :class="{
                                    'bysuplier-negative': prod.last_purchase && prod.last_purchase - prod.offer_price < 0,
                                    'bysuplier-positive': prod.last_purchase && prod.last_purchase - prod.offer_price >= 0
                                    }">
                                        {{ prod.last_purchase ? (prod.last_purchase - prod.offer_price).toFixed(2) : '--'    }}
                                    </span>
                                </div>
                            </div>
                            <!-- mobile -->
                             <div class="mobile-itens-body"  :class="getSpecialBackground(idx)">
                                <div class="bysuplier-name" style="gap: 0;">
                                    <div class="bysuplier-products-icons">
                                        <span class="material-icons-outlined bysuplier-expand-icon" @click="show_winners(prod)" :style="getExpandStyle(prod)">expand_more</span>
                                    </div>
                                    <div class="bysuplier-name-text" :title="prod.name">{{prod.name}}</div>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <div>
                                        <h6>Quantidade</h6>
                                        <span>{{ prod.requested_quantity }}</span>
                                    </div>
                                    <div class="display-center">
                                        <h6>Embalagem</h6>
                                        <span>{{ prod.packing }}</span>
                                    </div>
                                    <div class="display-end">
                                        <h6>Preço</h6>
                                        <span>{{ prod.offer_price | customCurrency }}</span>
                                    </div>
                                </div>
                                <div style="display: flex; justify-content: space-between;">
                                    <div>
                                        <h6>Ultima Compra</h6>
                                        <span>{{ prod.last_purchase | customCurrency }}</span>
                                    </div>
                                    <div class="display-end">
                                        <h6>Variação</h6>
                                        <span :class="{
                                    'bysuplier-negative': prod.last_purchase && prod.last_purchase - prod.offer_price < 0,
                                    'bysuplier-positive': prod.last_purchase && prod.last_purchase - prod.offer_price >= 0
                                    }">{{ prod.last_purchase ? (prod.last_purchase - prod.offer_price).toFixed(2) : '--'    }}</span>
                                    </div>
                                </div>
                             </div>

                            <cc-loader v-if="prod.isLoading" style="margin-top: 3vh; height: 90%;"/>
                            <div v-if="prod.open_winners" >
                                <div class="bysuplier-itens-header">
                                    <div class="bysuplier-name">
                                        <div class="bysuplier-icons-products"></div>
                                        Fornecedor
                                    </div>
                                    <div class="bysuplier-brand">marca</div>
                                    <div class="bysuplier-quantity">Qtd. Emb.</div>
                                    <div class="bysuplier-payment">Forma de PGTO.</div>

                                    <div class="bysuplier-unity-price">Valor Unitário</div>
                                    <div class="bysuplier-obs">Observação</div>
                                </div>
                                <div v-for="(winner, idx) in prod.vencedores" v-bind:key="winner.id + '-' + winner.seller_id">
                                    <!-- desktop -->
                                    <div class="bysuplier-winner-row" :class="getSpecialBackground(idx)">
                                        <div class="bysuplier-name">
                                            <div class="bysuplier-icons-products">
                                                <div style="width: 50%; text-align: center;">
                                                    <span class="material-icons-outlined bysuplier-winner-icon" v-if="winner.is_winner" @click="() => open_subrequests_modal(winner, prod)">shopping_cart</span>
                                                </div>
                                                <div style="width: 25%">
                                                    <span v-if="winner.is_winner" :title="winner.has_any_order?'Não é possível alterar itens em fornecedores que já possuem pedidos':'Desativar item'" @click="() => toggle_winner(winner, winner.seller_id, false)" class="material-icons bysuplier-winner-icon">check_box</span>
                                                    <span v-else-if="winner.has_any_order" title="Não é possível alterar itens em fornecedores que já possuem pedidos" class="material-icons bysuplier-winner-icon-disabled">check_box_outline_blank</span>
                                                    <span v-else @click="() => toggle_winner(winner, winner.seller_id, true)" class="material-icons bysuplier-winner-icon">check_box_outline_blank</span>
                                                </div>
                                                <div v-if="winner.is_loading" style="width: 25%">
                                                    <cc-loader style="height: 20px !important;" :show_txt="false"/>
                                                </div>
                                            </div>
                                            {{winner.provider_name}}
                                        </div>
                                        <div class="bysuplier-brand">{{ winner.brand || '--' }}</div>
                                        <div class="bysuplier-quantity">{{get_quantity(winner)}}</div>
                                        <div class="bysuplier-payment">{{ winner.payment_condition ? winner.payment_condition: '-' }}</div>
                                        <div class="bysuplier-unity-price">{{winner.offer_price | customCurrency}}</div>
                                        <div class="bysuplier-obs bysupliers-text-overflow" :title="winner.observations">{{winner.observations}}</div>
                                    </div>
                                    <!-- mobile -->
                                     <div class="mobile-itens-body"  :class="getSpecialBackground(idx)">
                                        <div class="bysuplier-name">
                                            <div class="bysuplier-icons-products">
                                                <div style="width: 50%; text-align: center;">
                                                    <span class="material-icons-outlined bysuplier-winner-icon" v-if="winner.is_winner" @click="() => open_subrequests_modal(winner, prod)">shopping_cart</span>
                                                </div>
                                                <div style="width: 25%">
                                                    <span v-if="winner.is_winner" :title="winner.has_any_order?'Não é possível alterar itens em fornecedores que já possuem pedidos':'Desativar item'" @click="() => toggle_winner(winner, winner.seller_id, false)" class="material-icons bysuplier-winner-icon">check_box</span>
                                                    <span v-else-if="winner.has_any_order" title="Não é possível alterar itens em fornecedores que já possuem pedidos" class="material-icons bysuplier-winner-icon-disabled">check_box_outline_blank</span>
                                                    <span v-else @click="() => toggle_winner(winner, winner.seller_id, true)" class="material-icons bysuplier-winner-icon">check_box_outline_blank</span>
                                                </div>
                                                <div v-if="winner.is_loading" style="width: 25%">
                                                    <cc-loader style="height: 20px !important;" :show_txt="false"/>
                                                </div>
                                            </div>
                                            {{winner.provider_name}}
                                        </div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <div>
                                                <h6>Marca</h6>
                                                <span>{{ winner.brand || '--'}}</span>
                                            </div>
                                            <div class="display-center">
                                                <h6>Quantidade</h6>
                                                <span>{{get_quantity(winner)}}</span>
                                            </div>
                                            <div class="display-end">
                                                <h6>Forma de PGTO.</h6>
                                                <span>{{ winner.payment_condition ? winner.payment_condition: '-' }}</span>
                                            </div>
                                        </div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <div>
                                                <h6>Valor Unitário</h6>
                                                <span>{{winner.offer_price | customCurrency}}</span>
                                            </div>
                                            <div class="display-end">
                                                <h6>Observação</h6>
                                                <span>{{winner.observations || '--'}}</span>
                                            </div>
                                        </div>
                                     </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>


                </div>
            </div>


        </div>

        <cc-product-sub-requests v-if="current_seller && show_current_seller"
            :salesman="current_seller"
            :quotationId="$route.params.id"
            :product="current_product"
            :stores_only="true"
            @close="current_seller = null;show_current_seller=false"
            @loadProviders="() => {}" :sellers="sellers" />
	</div>
</template>


<script>
import QuotationService from "@/services/QuotationService";
import RequestService from "@/services/v3/request.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import Button from "@/components/ui/buttons/button.component"
import NoDataAvailable from "@/components/utils/no-data.utils"
import RequestsService from "@/services/v2/requests.service"
import ProductSubRequestsModal from "@/modals/product-sub-requests/product-sub-requests.modal";
import { mapState } from 'vuex'
import currencyPrecision from '../../mixins/currencyPrecision';
export default {
    components: {
        ccButton: Button,
        'cc-product-sub-requests': ProductSubRequestsModal,
        'no-data-available': NoDataAvailable
    },
    props: ['type', 'data', 'wraps', 'is_loading', 'loadPreOrders', 'loadTotalOrders', 'priceOrder'],
    mixins: [currencyPrecision],
    data() {
        return {
            store: {},
            sellers: [],
            temp_sellers: [],
            productService: new QuotationService(),
            svc: new RequestsService(),
            v3_svc: new RequestService(),
            collapsed: [],
            current_seller: null,
            current_product: null,
            is_loading_pdf: false,
            show_current_seller: false
        };
    },
    methods: {
        getSpecialBackground (index) { if (index % 2 != 0) return 'page-table-line-special' },
        getExpandStyle(value){
            if (value.open_products) return
            else if (value.open_winners) return
            return 'transform: rotate(-90deg);'
        },
        open_subrequests_modal(seller, product) {
            this.current_seller = {
                ...seller,
                pro_id: seller.id,
                nomeVendedor: seller.seller_name,
                nomeFornecedor: seller.provider_name,
                embalagem: seller.packing,
                valor: seller.offer_price,
                idVendedor: seller.seller_id
            }
            this.current_product = {
                ...product,
                descricaoProduto: product.name,
                prod_id: product.id,
                flag_generico: product.is_generic,
                embalagem: product.packing,
                qtd_embalagem: product.embalagem_quantity
            }
            this.show_current_seller = true
        },
        get_quantity(winner) {
            return winner.is_generic ? winner.offer_quantity : winner.packing.split(" ")[1]
        },
        toggle_winner(winner, seller_id, is_winner) {
            if(winner.has_any_order) return
            this.$set(winner, "is_loading", true);
            this.$forceUpdate()
            this.productService.toggleQuotationWinner(
                this.$route.params.id,
                this.loj_id,
                winner.id,
                seller_id,
                is_winner
            ).then(response => response.data).then(() => {
                // this.$emit('loadPreOrders', false, this.process_sellers)
                this.$set(winner, "is_loading", false);
                this.$set(winner, "is_winner", is_winner);
                this.$forceUpdate()
            }).catch(() => {
                const notification = {
                    type: 'error',
                    message: 'Falha na requisição, tente novamente.'
                }
                this.$store.dispatch('notification/add', notification)
                this.$forceUpdate()
            })
        },
        show_winners(prod) {
            if(!prod.open_winners) {
                prod.isLoading = true
                this.$forceUpdate()
                this.load_offers(prod).then(offers => {
                    prod.open_winners = !prod.open_winners
                    this.sort_winners(offers)
                    prod.vencedores = offers
                    prod.isLoading = false
                    this.$forceUpdate()
                }).catch(() => {
                    prod.open_winners = false
                    prod.isLoading = false
                    this.$forceUpdate()
                })
            } else {
                prod.open_winners = !prod.open_winners
                this.$forceUpdate()
            }
        },
        show_products(provider) {
            if(!provider.open_products) {
                provider.loading_products = true
                this.load_provider_products(provider).then((prods) => {
                    provider.open_products = !provider.open_products
                    provider.products = prods
                    provider.loading_products = false
                    this.$forceUpdate()
                }).catch(error => {
                    provider.loading_products = false
                    ErrorHandlerService.handle(error, this.$store);
                    const notification = {
                        type: 'error',
                        message: 'Ocorreu um erro ao alterar vencedor. Tente novamente.'
                    }
                    this.$store.dispatch('notification/add', notification)
                    this.$forceUpdate()
                });
                this.$forceUpdate()
            } else {
                provider.open_products = !provider.open_products
                this.$forceUpdate()
            }
        },
        load_provider_products(provider) {

            return this.v3_svc.request_offers(
                this.$route.params.id,
                provider.idVendedor
            ).then(response => response.data).then(data => {
                for (var i = 0; i < data.length; i++) {
                    var embalagem_split = data[i].packing.split(" ");
                    var p = data[i]
                    p.embalagem_units = embalagem_split[0];
                    p.embalagem_quantity = embalagem_split[1];
                }
                return data
            })
        },
        load_offers(product) {
            return this.v3_svc.request_offers_by_product(
                this.$route.params.id,
                product.id
            ).then(response => response.data)
        },
        calculate_total(seller) {
            let result = 0;
            this.sellers.forEach(p => result += p.valorTotal)
            return result;
        },
        ordered_providers(seller) {
            return seller.fornecedores.filter(p => p.possuiPedido == 1);
        },
        unordered_providers(seller) {
            return seller.fornecedores.filter(p => p.possuiPedido == 0);
        },
        process_sellers() {
            let result = []
            let all = this.data.map(s => s.fornecedores).flat().filter(s => this.isVisible(s))
            let sells = []
            all.forEach(s => {
                var found = sells.find(ss => ss.idFornecedor == s.idFornecedor && ss.idVendedor == s.idVendedor)
                if(found) {
                    found.valorTotal += s.valorTotal
                    return
                } else {
                    found = s
                }
                sells.push(Object.assign({}, found))
            })

            result = sells.filter(s => this.isVisible(s) && s.entrega)
                .sort((a, b) => { return a.nomeFornecedor.localeCompare(b.nomeFornecedor) })

            result = result.concat(sells.filter(s => this.isVisible(s) && !s.entrega));

            if(this.sellers && this.sellers.length > 0) {
                result.forEach(re => {
                    if(!this.sellers.some(s => {
                        return s.idFornecedor == re.idFornecedor
                    })) {
                        this.sellers.push(re)
                    }
                })
                this.sellers.forEach(s => {
                    if(!result.some(re => {
                        return s.idFornecedor == re.idFornecedor
                    })) {
                        this.sellers = this.sellers.filter(ss => ss.idFornecedor != s.idFornecedor)
                    }
                })
            } else {
                this.sellers = result
            }
            this.$forceUpdate()
        },
        sort_winners(offers) {
            offers.sort((a, b) => {
                let total1 = a.offer_price
                let total2 = b.offer_price
                if (total1 > total2) return 1
                if (total2 > total1) return -1
                return 0
            })
        },
        isVisible(provider) {
            if(provider && (provider.possuiPedido == 1 && this.type == "ORDERED" || provider.possuiPedido == 0 && this.type == "NOT_ORDERED")) {
                return true;
            } else {
                return false;
            }
        },
        generate_pdf(numeroSolicitacao, seller) {
            this.$set(seller, 'is_loading_pdf', true);
            this.productService
            .generate_pdf(this.$route.params.id, numeroSolicitacao)
            .then(response => response.data)
            .then(data => {
                var file = new Blob([data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                var el = document.getElementById("download-pdf");
                el.download = "Conferencia-" + seller.loj_nomeFantasia + "-" + this.$route.params.id + ".pdf";
                el.href = fileURL;
                el.click();
                setTimeout(function(){
                    window.URL.revokeObjectURL(fileURL);
                }, 100);
                this.$set(seller, 'is_loading_pdf', false);
            })
            .catch(error => {
                this.$set(seller, 'is_loading_pdf', false);
                ErrorHandlerService.handle(error, this.$store);
            });
        }
    },
    watch: {
        'data': function(dat) {
            this.temp_sellers = dat
            this.store = this.temp_sellers[0]
            this.process_sellers()
        }
    },
    computed: {
        ...mapState([ 'user' ]),
        pageSellers(){
            if (this.priceOrder) return [...this.sellers].sort((b,a) => a.valorTotal - b.valorTotal)
            else return this.sellers
        }
    },
    mounted() {
        this.temp_sellers = this.data
        this.store = this.temp_sellers[0]
        this.process_sellers()
    }
}
</script>

<style lang="scss" scoped>
@import "./_pre_orders_by_seller";
.empresa-titulo {
	height: 55px;
  font-family: Rubik !important;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: black !important;
}
.span-button{
	font-family: Rubik !important;
  font-size: 13px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #a3a6b4 !important;
}
label{
  color:#4D4F5C !important
}
.bysuplier-table{
    overflow-x: auto;
}
.bysuplier-itens-container{
    border: 1px solid #E5E5E5;
    margin-bottom: 2vh;
    border-radius: 8px;
    padding: 1vh 0;
    font-size: 1vw;
}
.bysuplier-title-container{
    display: flex;
    justify-content: space-between;
    padding: 0 1vw;
    align-items: center;
}
.bysuplier-title-text{
    font-weight: 400;
    font-size: 1.3em;
    color: #606060;
}
.bysuplier-title-button{
    background: rgba(23, 162, 184, 0.1);
    border: 0.6px solid #E5E5E5;
    border-radius: 9.5px;
    padding: 1.5vh 1vw;
    font-weight: 600;
    font-size: 1.3em;
    color: #5281FF;
}
.bysuplier-itens-header{
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0;
    margin: 2vh 1vw 1.5vh 1vw;
    font-weight: 600;
    font-size: 1.19em;
    color: #605F5F;
    display: flex;
}
.bysuplier-name{flex: 1; display: flex; align-items: center;}
.bysuplier-date{width: 15%;}
.bysuplier-cond{width: 12%;}
.bysuplier-value{width: 12%;}
.bysuplier-min{width: 12%;}
.bysuplier-delivered{width: 12%;}
.bysuplier-icons{
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: baseline;
}
.mobile-itens-body{
    display: none;
}
.bysuplier-icons-products{
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: baseline;

}
.bysuplier-products-icons{
    width: 16%;
    display: flex;
    justify-content: end;
    align-items: baseline;
}
.bysuplier-itens-body{
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
    padding: 1.5vh 1vw 1vh 1vw;
    border-bottom: 1px solid #E5E5E5;
}
.bysuplier-negative{
    background-color: lightcoral;
    color: white;
    border-radius: 8px;
    padding: 0 10%;
}
.bysuplier-positive{
    background-color: lightgreen;
    color: white;
    border-radius: 8px;
    padding: 0 10%;
}
.bysuplier-expand-icon{
    color: var(--primary-color);
    margin-right: 20%;
    font-size: 2em;
    cursor: pointer;
}
.bysuplier-cart-icon{
    color: var(--primary-color);
    font-size: 2em;
}
.bysuplier-quantity{width: 12%;}
.bysuplier-price{width: 12%}
.bysuplier-last{width: 12%}
.bysuplier-product-row{
    display: flex;
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
    padding-top: 1.5vh;
    padding-bottom: 1vh;
    border-bottom: 1px solid #E5E5E5;
}
.bysuplier-name-text{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 20vw;
}
.bysuplier-brand{width: 13%;}
.bysuplier-payment{width: 11%;}
.bysuplier-unity-price{width: 11%;}
.bysuplier-obs{width: 22%;}
.bysuplier-winner-row{
    display: flex;
    padding: 1vh 1vw;
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
}
.page-table-line-special{ background: #F7F7F7; }
.bysupliers-text-overflow{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 1vw;
}
.bysuplier-winner-icon{
    color: var(--primary-color);
    cursor: pointer;
}
.bysuplier-winner-icon-disabled{
    color: lightgrey;
    cursor: default;
}
@media only screen and (max-width: 700px) {
    .bysuplier-itens-container{font-size: 14px;}
    .bysuplier-title-container{
        flex-direction: column;
    }
    .bysuplier-itens-header{
        display: none;
    }
    .mobile-itens-body{
        display: block;
        padding-top: 1vh;
        border-bottom: 1px solid #E5E5E5;
        padding-bottom: 1vh;
    }
    .bysuplier-itens-body{
        display: none;
    }
    .bysuplier-name{
        text-align: center;
        gap: 15%;
    }
    h6{
        font-weight: bold;
        color: #605F5F;
    }
    .display-center{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .display-end{
        display: flex;
        flex-direction: column;
        align-items: end;
    }
    span{
        color: #606060
    }
    .bysuplier-product-row{
        display: none;
    }
    .bysuplier-name-text{
        max-width: unset;
    }
    .bysuplier-winner-row{
        display: none;
    }
}
</style>

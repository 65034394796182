<template>
    <div class="mobile-sub-product-container">
        <h6>
            {{ product.pro_descricao }}
        </h6>
        <div class="mobile-table-row">
            <div class="unit-width">
                <h6 class="page-table-mobile-header">
                    Quantidade
                </h6>
                {{ product.quantidade }}
            </div>
            <div class="unit-width" style="text-align: center;">
                <h6 class="page-table-mobile-header">
                    Valor Total
                </h6>
                {{ product.valorTotal | currency }}
            </div>
            <div class="unit-width" style="text-align: end;">
                <h6 class="page-table-mobile-header">
                    Valor Unit
                </h6>
                {{ cat.cli_novoPedido == 1 && cat.cli_tipoPedido == 1 ? formartNumber(product.valorTotal / product.quantidade ) : formartNumber(product.valorTotal / (product.quantidade)) }}
            </div>
        </div>
        <div class="mobile-table-row">
            <div class="unit-width">
                <h6 class="page-table-mobile-header">
                    %
                </h6>
                {{ product.percent }}%
            </div>
            
            <div class="unit-width" style="text-align: end;">
                <h6 class="page-table-mobile-header">
                    % Acumulado
                </h6>
                {{ product.percentAcumulate }}%
            </div>
        </div>
    </div>
</template>

<script>
import numeral from "numeral";

export default {
    name: 'SubCategoryMobileRow',
    props: {
        produtoInt: Object,
        cat: Object
    },
    data() {
        return {
            product: this.produtoInt
        }
    },
    methods: {
        formartNumber(n) {
            if (n) {
                return numeral(n.toString().replace(".", ",")).format("0,0.00");
            } else {
                return n;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.mobile-table-row {
    justify-content: space-between;
    display: flex;
}

.mobile-sub-product-container {
    border-top: 1px solid lightgray;
    padding-bottom: 3vh;
    padding-top: 2vh;
}

.unit-width {
    width: 30%;
    font-size: 1.4em;
}

h6 {
    font-weight: bold;
    margin: 0;
}

.product-tab-colapsed-icon {
    font-size: 1em;
    margin-right: 1vw;
}

.product-tab-expanded-icon {
    color: var(--primary-color);
    font-size: 1em;
    rotate: 90deg;
    margin-right: 1vw;
}

.page-table-mobile-header {
    font-size: 1.5em;
    font-weight: normal;
}
</style>
<template>
  <div class="clients-analysis">
    <div class="clients-analysis__container">
      <div class="row only-print">
        <div class="col p-4 text-center">
          <img class="logo" style="width:100px;"  :src="whitelabel ? whitelabel.data.imgs.img_logo : require('@/assets/logo.png')"/>
        </div>
        <div class="not-print" style="margin-top: 0px;margin-bottom: 5px;text-transform: uppercase;width: 50%;float: left;text-align: center;">
          <h4>Produtos</h4>
        </div>
      </div>
      <div class="row only-print">
        <div style="margin-top: 0px;margin-bottom: 5px;text-transform: uppercase;float: left;">
          <h5>{{$store.state.current_user.cliente ? $store.state.current_user.cliente.cli_nome : "" }}</h5>
          <h5>CNPJ: {{$store.state.current_user.cliente ? $store.state.current_user.cliente.cli_cnpj : "" | cnpj }}</h5>
        </div>
      </div>
      <div class="row only-print">
        <div style="width: 120px;float: left;">
          <p style="padding: 0px;margin: 0px">Nome Produto:</p>
          <span>{{filtroProduto ? filtroProduto : "Todos"}}</span>
        </div>
        <div style="width: 120px;float: left;">
          <p style="padding: 0px;margin: 0px">Requisitante:</p>
          <span>{{getNomeRequisitante ? getNomeRequisitante : "Todos"}}</span>
        </div>
        <div style="width: 120px;float: left;">
          <p style="padding: 0px;margin: 0px">Fornecedor:</p>
          <span>{{getNomeFornecedor ? getNomeFornecedor : "Todos"}}</span>
        </div>
        <div style="width: 120px;float: left;">
          <p style="padding: 0px;margin: 0px">Categoria:</p>
          <span>{{getNomeCategoria ? getNomeCategoria : "Todos"}}</span>
        </div>
        <div style="width: 90px;float: left;" v-if="fDateIni">
          <p style="padding: 0px;margin: 0px">Data Inicial:</p>
          <span>{{fDateIni}}</span>
        </div>
        <div style="width: 90px;float: left;" v-if="fDateFim">
          <p style="padding: 0px;margin: 0px">Data Final:</p>
          <span>{{fDateFim}}</span>
        </div>
      </div>
      <div class="page-buttons-container not-print">
        <div class="page-filters__datetime-container">
          <div class="page-filters__right-wrapper">
            <div class="page-filters__right-title-wrapper">
              <div class="page-filters__right-title">Data Inicial</div>
            </div>
            <div class="page-filters__right-select-wrapper end-date">
              <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker" v-model="$store.state.dateIniRelatorio" auto type="date"></datetime>
              <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                today
              </span>
            </div>
          </div>
          <div class="page-filters__right-wrapper">
            <div class="page-filters__right-title-wrapper">
              <div class="page-filters__right-title">Data Fim</div>
            </div>
            <div class="page-filters__right-select-wrapper end-date">
              <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker" v-model="$store.state.dateFimRelatorio" auto type="date"></datetime>
              <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                today
              </span>
              <span @click="getProdutos(1, false)" class="material-icons-outlined search-icon">
                search
              </span>
            </div>
          </div>
        </div>
        <div class="page-filters__actions-container">
          <Button class="action-buttons-width" title="Imprimir produtos" :text="'Imprimir'" :iconName="'print'" :action="print"/>
          <Button class="action-buttons-width" title="Limpar Filtros" :text="'Limpar Filtros'" :iconName="'cancel'" :action="reset_filter"/>
          <div class="reports-page__title-button-total action-buttons-width">
            Valor Total: R$ {{formartNumber(totalPedido)}}
          </div>
        </div>
        <!-- <Button class="action-buttons-width" title="Visualizar Tabela" :text="'Visualizar Tabela'" :iconName="'visibility'" :action="openTable"/> -->
        <!-- <div class="page-buttons-container__left-container">
          <div class="page-buttons-wrapper">
            <div class="page-buttons-wrapper__title">Fornecedor</div>
            <div class="page-buttons-wrapper__select">
              <cc-select-v2 :tracker="'for_id'" :text="'for_nome'" :options="$store.state.fornecedorFiltro" v-model="filtroFornecedor"></cc-select-v2>
            </div>
          </div>
          <div class="page-buttons-wrapper">
            <div class="page-buttons-wrapper__title">Categoria</div>
            <div class="page-buttons-wrapper__select">
              <cc-select-v2 :tracker="'cat_id'" :text="'cat_descricao'" :options="$store.state.categoriasFiltro" v-model="filtroCategoria"></cc-select-v2>
            </div>
          </div>
        </div>
        <div class="page-buttons-container__right-container">
          <div class="page-buttons-wrapper">
            <Button title="Imprimir produtos" :text="'Imprimir'" :iconName="'print'" :action="print"/>
          </div>
          <div class="page-buttons-wrapper action-button">
            <div class="page-filters__total-value">
              <span class="page-filters__total-value-text">Total: R$ {{formartNumber(totalPedido)}}</span>
            </div>
          </div>
        </div> -->
      </div>
      <div class="page-filters-container not-print">
          <StandardInput class="standard-input-width"
                         :title="'Empresa'"
                         :action="setRequisitanteValue"
                         :width="'22%'"
                         :type="'select'"
                         :list="$store.state.requisitantesFiltroArray"
                         :placeholder="'Selecione a empresa'"/>
          <StandardInput class="standard-input-width"
                         :title="'Fornecedor'"
                         :action="setFornecedorValue"
                         :width="'22%'"
                         :type="'select'"
                         :list="$store.state.fornecedorFiltroArray"
                         :placeholder="'Selecione o fornecedor'"/>
          <StandardInput class="standard-input-width"
                         :title="'Categoria'"
                         :action="setCategoriaValue"
                         :width="'22%'"
                         :type="'select'"
                         :list="$store.state.categoriasFiltroArray"
                         :placeholder="'Selecione a categoria'"/>
        <!-- <div class="page-filters__left-container">
          <div class="page-filters__left-wrapper search-input">
            <div class="page-filters__left-title-wrapper">
              <div class="page-filters__left-title">Empresa</div>
            </div>
            <div class="page-filters__left-select-wrapper">
              <cc-select-v2 :tracker="'loj_id'" :text="'loj_descricao'" :options="$store.state.requisitantesFiltro" v-model="filtroRequisitante"></cc-select-v2>
              <span @click="getProdutos(1, false)" class="material-icons input-icon">search</span>
            </div>
          </div>
        </div>
        <div class="page-filters__right-container">
          <div class="page-filters__right-wrapper">
            <div class="page-filters__right-title-wrapper">
              <div class="page-filters__right-title">Data Inicial</div>
            </div>
            <div class="page-filters__right-select-wrapper">
              <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker" v-model="$store.state.dateIniRelatorio" auto type="date"></datetime>
              <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                today
              </span>
            </div>
          </div>
          <div class="page-filters__right-wrapper">
            <div class="page-filters__right-title-wrapper">
              <div class="page-filters__right-title">Data Fim</div>
            </div>
            <div class="page-filters__right-select-wrapper">
              <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker" v-model="$store.state.dateFimRelatorio" auto type="date"></datetime>
              <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                today
              </span>
              <span @click="getProdutos(1, false)" class="material-icons input-icon">search</span>
            </div>
          </div>
        </div> -->
      </div>
      <div class="page-selected-filters-container">
        <div v-if="filtroRequisitante" class="page-selected-filters">
          <div class="page-selected-filters-text">{{ filtroRequisitante.loj_descricao }}</div>
          <span @click="setRequisitanteValue('')" class="material-icons page-selected-filters-icon">close</span>
        </div>
        <div v-if="filtroFornecedor" class="page-selected-filters">
          <div class="page-selected-filters-text">{{ filtroFornecedor.for_nome }}</div>
          <span @click="setFornecedorValue('')" class="material-icons page-selected-filters-icon">close</span>
        </div>
        <div v-if="filtroCategoria" class="page-selected-filters">
          <div class="page-selected-filters-text">{{ filtroCategoria.cat_descricao }}</div>
          <span @click="setCategoriaValue('')" class="material-icons page-selected-filters-icon">close</span>
        </div>
      </div>
      <no-data-available v-if="produtos.length == 0 && !loading" :text="'Nenhum resultado encontrado'" />
      <div v-if="produtos.length > 0 && !loading" class="page-table-container not-print">
        <div class="page-table-content">
          <div class="page-table-header">
            <div class="page-table-header-text category-width">Loja</div>
            <div class="page-table-header-text qty-width">
              Quantidade
              <cc-label-sort :showicon="ordernar=='quantidade'" :direction="tipoOrder == 'asc' ? 1 : 0" @click="setFiltroOrder('quantidade', 'desc')"/>
            </div>
            <div class="page-table-header-text total-val-width">
              Valor Total
              <cc-label-sort :showicon="ordernar=='valorTotal'" :direction="tipoOrder == 'asc' ? 1 : 0" @click="setFiltroOrder('valorTotal', 'desc')"/>
            </div>
            <div class="page-table-header-text percent-width">%</div>
          </div>
          <div v-for="(produto, idx) in produtos"
               :key="'loj-' + produto.loj_id"
               @click="getProdutosInternos(produto)">
            <div class="page-table-row-container" >
              <div class="page-table-row" style="cursor: pointer;" :class="getSpecialBackground(idx)">
                <div class="page-table-desc-column category-width">
                  <div class="clients-analysis-mobile-header">Loja</div>
                  <span v-if="!produto.expanded" class="material-icons product-tab-colapsed-icon">arrow_forward_ios</span>
                  <span v-else class="material-icons product-tab-expanded-icon">arrow_forward_ios</span>
                  {{ produto.loj_descricao }}
                </div>
                <div class="page-table-desc-column qty-width">
                  <div class="clients-analysis-mobile-header">Quantidade</div>
                  {{ produto.quantidade }}
                </div>
                <div class="page-table-desc-column total-val-width">
                  <div class="clients-analysis-mobile-header">Valor Total</div>
                  {{ produto.valorTotal | currency }}
                </div>
                <div class="page-table-desc-column percent-width">
                  <div class="clients-analysis-mobile-header">%</div>
                  {{ getPercentual(produto) }}
                </div>
              </div>
              <div class="page-table-row" v-show="produto.loading" :key="'prod-loader-' + produto.pro_id">
                <cc-loader style="margin: 10px auto;"/>
              </div>
              <div v-show="!produto.loading && produto.produtos && produto.expanded"
                   :key="'prod-detail-' + produto.pro_id"
                   class="page-table-row"
                   style="justify-content: center;">
                <div class="category-analysis-sub-table">
                  <div class="page-table-header" style="padding: 15px 0px;">
                    <div class="page-table-header-text descricao-width">Descrição</div>
                    <div class="page-table-header-text quantidade-width">Quantidade</div>
                    <div class="page-table-header-text valor-total-width">Valor Total</div>
                    <div class="page-table-header-text valor-unit-width">Valor Unit</div>
                    <div class="page-table-header-text porcento-width">%</div>
                    <div class="page-table-header-text porcento-acumulado-width">% Acumulado</div>
                  </div>
                  <div v-for="(produtoInt, index) in produto.produtos"
                       :key="`inf-line-${index}`">
                    <div class="page-table-row-container">
                      <div class="page-table-row" :class="getSpecialBackground(index)">
                        <div class="page-table-desc-column descricao-width">
                          <div class="clients-analysis-mobile-header">Descrição</div>
                          {{ produtoInt.pro_descricao }}
                        </div>
                        <div class="page-table-desc-column quantidade-width">
                          <div class="clients-analysis-mobile-header">Quantidade</div>
                          {{ produtoInt.quantidade }}
                        </div>
                        <div class="page-table-desc-column valor-total-width">
                          <div class="clients-analysis-mobile-header">Valor Total</div>
                          {{ produtoInt.valorTotal | currency }}
                        </div>
                        <div class="page-table-desc-column valor-unit-width">
                          <div class="clients-analysis-mobile-header">Valor Unit</div>
                          {{ produto.cli_novoPedido == 1 && produto.cli_tipoPedido == 1 ? formartNumber(produtoInt.valorTotal / produtoInt.quantidade ) : formartNumber(produtoInt.valorTotal / (produtoInt.quantidade)) }}
                        </div>
                        <div class="page-table-desc-column porcento-width">
                          <div class="clients-analysis-mobile-header">%</div>
                          {{ produtoInt.percent }}%
                        </div>
                        <div class="page-table-desc-column porcento-acumulado-width">
                          <div class="clients-analysis-mobile-header">% Acumulado</div>
                          {{ produtoInt.percentAcumulate }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile">
        <ClientMobileRow v-for="(client, idx) in produtos" :key="'loj-' + client.loj_id" :client="client" :totalPedido="totalPedido"/>
      </div>
      <!-- tabela apenas impressão -->
      <div v-if="produtos.length > 0 && !loading && !showTable" class="page-table-container only-print">
        <div class="page-table-content">
          <div class="page-table-header">
            <div class="page-table-header-text category-width__print">Loja</div>
            <div class="page-table-header-text qty-width__print">
              Quantidade
              <cc-label-sort :showicon="ordernar=='quantidade'" :direction="tipoOrder == 'asc' ? 1 : 0" @click="setFiltroOrder('quantidade', 'desc')"/>
            </div>
            <div class="page-table-header-text total-val-width__print">
              Valor Total
              <cc-label-sort :showicon="ordernar=='valorTotal'" :direction="tipoOrder == 'asc' ? 1 : 0" @click="setFiltroOrder('valorTotal', 'desc')"/>
            </div>
            <div class="page-table-header-text percent-width__print">%</div>
          </div>
          <div v-for="produto in produtos"

               :key="'loj-' + produto.loj_id">
            <div class="page-table-row-container">
              <div class="page-table-row">
                <div class="page-table-desc-column category-width__print">{{ produto.loj_descricao }}</div>
                <div class="page-table-desc-column qty-width__print">{{ produto.quantidade }}</div>
                <div class="page-table-desc-column total-val-width__print">{{ produto.valorTotal | currency }}</div>
                <div class="page-table-desc-column percent-width__print">{{ getPercentual(produto) }}</div>
              </div>
              <div class="page-table-row" v-show="produto.loading" :key="'prod-loader-' + produto.pro_id">
                <cc-loader style="margin: 10px auto;"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <cc-loader style="margin: 15vh auto; width: 120px;" v-show="loading" />
    </div>
    <tr v-if="!showTable" class="seemore-button-container not-print">
      <td class="text-center" colspan="6">
        <Paginacao classes="orange justify-end"
                   :items_by_page_value="items_by_page_value"
                   :total="total_items"
                   :page="page"
                   v-on:change="updatePage($event)">
        </Paginacao>
      </td>
    </tr>
   </div>
</template>

<script>

import { filtroData } from "@/mixins/filtroData";
import { Money } from "v-money";
import { mascaraCNPJ } from "@/mixins/mascaraCNPJ";
import numeral from "numeral";
//import Paginacao from "@/components/shared/Paginacao";
import Paginacao from "@/components/cliente/base-components/Pagination";
import * as CONSTANTS from "@/constants/constants";
import Button from "@/components/ui/buttons/Standard-Button.vue"
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import { mapState } from "vuex";
import ClientMobileRow from "./ClientMobileRow.vue";

export default {
    components: {
      Paginacao,
      Button,
      StandardInput,
      ClientMobileRow
    },
    mixins: [filtroData, mascaraCNPJ],
    data() {
        return {
            showTable: false,
            page: 1,
            items_by_page_value: 30,
            total_items: 1000,
            image:"/images/search.png",
            image2:"/images/calendar.png",
            paginaAtual: 1,
            date_picker_options: {
                format: "DD/MM/YYYY",
                locale: "pt-br",
                useCurrent: false
            },
            totalPaginas: 1,
            itensPorPagina: 0,
            totalPedido: 0,
            totalItens: 0,
            produtos: [],
            filtroProduto: "",
            filtroRequisitante: null,
            filtroFornecedor: null,
            filtroCategoria: null,
            classOrder: "fa-sort-asc",
            ordernar: "valorTotal",
            tipoOrder: "desc",
            loading: false,
            fDateIni: moment().format("YYYY-MM-DD"),
            fDateFim: moment().format("YYYY-MM-DD")
        };
    },
    computed: {
      ...mapState(["whitelabel"]),
        getNomeCategoria() {
            let lojaSelect = this.$store.state.categoriasFiltro.filter(
                item => item.cat_id == this.filtroCategoria
            );
            if (lojaSelect.length == 0) {
                return "";
            }
            return lojaSelect[0].cat_descricao;
        },
        getNomeRequisitante() {
            let requisitanteSelect = this.$store.state.requisitantesFiltro.filter(
                item => item.loj_id == this.filtroRequisitante
            );

            if (requisitanteSelect.length == 0) {
                return "";
            }
            return requisitanteSelect[0].loj_descricao;
        },
        getNomeFornecedor() {
            let fornecedorSelect = this.$store.state.fornecedorFiltro.filter(
                item => item.for_id == this.filtroFornecedor
            );
            if (fornecedorSelect.length == 0) {
                return "";
            }
            return fornecedorSelect[0].for_nome;
        }
    },
    watch: {
      filtroProduto: function() {
        if (!this.loading) {
          this.loading = true
          setTimeout(() => {
            this.getProdutos();
          }, 1500);
        }
      },
      filtroRequisitante: function() {
        if (!this.loading) {
          this.loading = true
          setTimeout(() => {
            this.getProdutos();
          }, 1500);
        }
      },
      filtroFornecedor: function() {
        if (!this.loading) {
          this.loading = true
          setTimeout(() => {
            this.getProdutos();
          }, 1500);
        }
      },
      filtroCategoria: function() {
        if (!this.loading) {
          this.loading = true
          setTimeout(() => {
            this.getProdutos();
          }, 1500);
        }
      },
    },
    methods: {
        getSpecialBackground(idx) {
          if (idx % 2 != 0) return 'page-table-line-special'
        },
        openTable() {
          this.showTable = !this.showTable
        },
        setValue(value){
          this.filtroProduto = value
        },
        setRequisitanteValue(value){
          this.filtroRequisitante = value
        },
        setFornecedorValue(value){
          this.filtroFornecedor = value
        },
        setCategoriaValue(value){
          this.filtroCategoria = value
        },
        print() {
            this.imprimir = true;
            this.getProdutos(1,false,true);
        },
        reset_filter() {
            this.filtroRequisitante = null;
            this.filtroData = null;
            this.filtroProduto = null;
            this.filtroCategoria = null;
            this.filtroFornecedor = null
        },
        open_datepicker(idx) {
            document.getElementsByClassName('vdatetime-input')[idx].click()
        },
        setFiltroOrder(order, tipo) {
            if (order == this.ordernar && tipo == this.tipoOrder && tipo == "asc") {
                this.tipoOrder = "desc";
            } else if (
                order == this.ordernar &&
                tipo == this.tipoOrder &&
                tipo == "desc"
            ) {
                this.tipoOrder = "asc";
            } else {
                this.tipoOrder = tipo;
            }
            this.classOrder = "fa-sort-" + this.tipoOrder;


            this.ordernar = order;

            if (this.produtos.length > 0) {
                this.getProdutos(this.paginaAtual);
            }
        },
        formartNumber(n) {
            if (n) {
                return numeral(n.toString().replace(".", ",")).format("0,0.00");
            } else {
                return n;
            }
        },
        getPercentual(produto) {
            return (
                parseFloat((produto.valorTotal * 100) / this.totalPedido).toFixed(2) +
                "%"
            )
        },
        onDateRangeChangedIni: function(picker) {
            this.fDateIni = picker.startDate.format("YYYY-MM-DD");
            this.$store.commit(
                "changeDateIniRelatorioCliente",
                picker.startDate.format("YYYY-MM-DD")
            );
        },
        onDateRangeChangedFim: function(picker) {
            this.fDateFim = picker.startDate.format("YYYY-MM-DD");
            this.$store.commit(
                "changeDateFimRelatorioCliente",
                picker.startDate.format("YYYY-MM-DD")
            );
        },
        clickTable(index, indexProduto) {
            if (
                this.$refs["view-" + index][0].classList.length == 2 &&
                !this.produtos[indexProduto].produtos
            ) {
                // this.$refs["div-" + index][0].style.background = "#f1f1f1";

                this.getProdutosInternos(index, indexProduto);
            } else {
                // this.$refs["div-" + index][0].style.background = "#FFF";
            }
            this.$refs["view-" + index][0].classList.toggle("open");
            this.$refs["fold-" + index][0].classList.toggle("open");
        },
        getProdutosInternos(produto) {
          if (produto.expanded) {
            produto.expanded = false
            this.$forceUpdate()
            return
          }
          if (!produto.expanded && produto.produtos) {
            produto.expanded = true
            this.$forceUpdate()
            return
          }
          console.log(produto);
          produto.loading = true;
            this.$forceUpdate()
            var requestUrl =
                `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?requisitante=` +
                produto.loj_id +
                "&groupBy=p.pro_id&imprimir=true&ordenar=valorTotal&tipoorder=desc";
            requestUrl +=
                "&dateIni=" + this.$store.state.dateIniRelatorio
                +
                "&dateFim=" + this.$store.state.dateFimRelatorio

            axios.get(requestUrl, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(({ data }) => {
              produto.produtos = data.produtos
              let lastPercent = 0;
              produto.produtos.forEach(element => {
                element.percent = parseFloat((element.valorTotal * 100) / produto.valorTotal).toFixed(2)
                element.percentAcumulate = parseFloat(parseFloat(element.percent) + parseFloat(lastPercent)).toFixed(2);
                lastPercent = element.percentAcumulate;
              });
              produto.loading = false;
              produto.expanded = true
              this.$forceUpdate()
            })
            // .catch(error => {
            //   produto.loading = false;
            //     if (error.response.status === 401) {
            //         if (error.response.data.mensagem == CONSTANTS.TOKEN_EXPIRADO) {
            //         location.href = CONSTANTS.ROUTE_LOGIN;
            //         }
            //     } else if (error.response.status === 500) {
            //         if (error.response.data.mensagem) {
            //         this.mensagemErro = error.response.data.mensagem;
            //         } else {
            //         this.mensagemErro = "Não foi possível abrir os dados gerenciais";
            //         }
            //     }
            // });
        },
        getPercentualNumber(produto) {
            return parseFloat((produto.valorTotal * 100) / this.totalPedido);
        },
        getProdutos(pagina, variable, imprimir = false) {
            this.loading = true;
            var requestUrl =
                `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?groupBy=l.loj_id&ordenar=` +
                this.ordernar +
                "&tipoorder=" +
                this.tipoOrder +
                "&page=" +
                pagina;

            const dateIni = moment(this.$store.state.dateIniRelatorio).format('YYYY-MM-DD')
            const dateFim = moment(this.$store.state.dateFimRelatorio).format('YYYY-MM-DD')

            requestUrl += "&dateIni=" + dateIni + "&dateFim=" + dateFim

            if (this.filtroProduto != "") {
                requestUrl += "&nomeproduto=" + this.filtroProduto;
            }

            if(this.filtroRequisitante && this.filtroRequisitante.loj_id)requestUrl += "&requisitante=" + this.filtroRequisitante.loj_id;
            if(this.filtroFornecedor && this.filtroFornecedor.for_id)requestUrl += "&fornecedor=" + this.filtroFornecedor.for_id
            if(this.filtroCategoria && this.filtroCategoria.cat_id)requestUrl += "&categoria=" + this.filtroCategoria.cat_id

            if (imprimir) {
                window.print();
                this.imprimindo = false;
                this.loading = false;
                return true;
                requestUrl += "&imprimir=" + true;
                // var telaImpressao = window.open("about:blank");
            }
            axios.get(requestUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("token") }})
            .then(({ data }) => {
                this.loading = false;

                if (imprimir) {
                    // requestUrl += "&imprimir=" + true;
                    // // var telaImpressao = window.open("about:blank");
                    // this.totalPedido = data.totalProdutos.valorTotal
                    //   ? data.totalProdutos.valorTotal
                    //   : 0;
                    // this.produtos = data.produtos.map((item, index) => {
                    //   let newItem = item;
                    //   let lastPercent = 0;
                    //   newItem.percent = this.getPercentualNumber(item).toFixed(2);
                    //   //percentual acumulado para itens internos
                    //   if (item.produtos) {
                    //     newItem.produtos = item.produtos.map(itemInt => {
                    //       let newItemInt = itemInt;
                    //       newItemInt.percent = parseFloat(
                    //         itemInt.valorTotal * 100 / item.valorTotal
                    //       ).toFixed(2);
                    //       newItemInt.percentAcumulate = parseFloat(
                    //         parseFloat(newItemInt.percent) + parseFloat(lastPercent)
                    //       ).toFixed(2);
                    //       lastPercent = newItemInt.percentAcumulate;
                    //       return newItemInt;
                    //     });
                    //   }
                    //   return newItem;
                    // });
                    // setTimeout(() => {
                    //   window.print();
                    //   this.imprimindo = false;
                    // }, 100);
                } else {
                    this.totalPedido = data.totalProdutos.valorTotal
                    ? data.totalProdutos.valorTotal
                    : 0;
                    this.paginaAtual = data.produtos.current_page;
                    this.totalPaginas = data.produtos.last_page;
                    this.total_items = data.produtos.total;
                    this.itensPorPagina = data.produtos.per_page;
                    this.totalItens = data.produtos.total;
                    this.produtos = data.produtos.data.map((item, index) => {
                        let newItem = item;
                        let lastPercent = 0;
                        newItem.percent = this.getPercentualNumber(item).toFixed(2);
                        //percentual acumulado para itens internos
                        if (item.produtos) {
                            newItem.produtos = item.produtos.map(itemInt => {
                            let newItemInt = itemInt;
                            newItemInt.percent = parseFloat(
                                (itemInt.valorTotal * 100) / item.valorTotal
                            ).toFixed(2);
                            newItemInt.percentAcumulate = parseFloat(
                                parseFloat(newItemInt.percent) + parseFloat(lastPercent)
                            ).toFixed(2);
                            lastPercent = newItemInt.percentAcumulate;
                            return newItemInt;
                            });
                        }

                        return newItem;
                    });

                }
            }).catch(error => {
                this.loading = false;
                if (error.response.status === 401) {
                    if (error.response.data.mensagem == CONSTANTS.TOKEN_EXPIRADO) {
                    // location.href = CONSTANTS.ROUTE_LOGIN;
                    window.location.assign(CONSTANTS.ROUTE_LOGIN)
                    }
                } else if (error.response.status === 500) {
                    if (error.response.data.mensagem) {
                    this.mensagemErro = error.response.data.mensagem;
                    } else {
                    this.mensagemErro = "Não foi possível abrir os dados gerenciais";
                    }
                }
            })
        }
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>
.clients-analysis {}
.clients-analysis__container {}
.clients-analysis__add-buttons {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-top: 1.5em;
}
.clients-analysis__add-button-container {
  margin-left: 1em;
}
.clients-analysis__total-value {
  padding: 0.55em;
  border-radius: 8px;
  background-color: var(--primary-color);
}
.clients-analysis__total-value-text {
  color: #FFF;
  font-weight: 600;
}
.clients-analysis__page-filter-container {
  display: flex;
  margin-top: 2em;
}
.clients-analysis__page-filter-wrapper {
  margin-right: 10px;
  width: 20%;
}
.input-title{
  font-weight: 400;
  font-size: 1.6em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.mobile{
  display: none;
}
.input-container{
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.date-container {
  align-items: center;
  border-radius: 8px !important;
  margin-top: 5px;
  display: flex;
}
.date-icon {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}
.input-value{
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 1.1em;
  letter-spacing: 0.15px;
  color: #B3B3B3 !important;
}
.input-value:focus{
  border: none;
  outline: none;
}
.input-icon{
  color: #fff;
  background-color: var(--primary-color);
  margin-left: 5px;
  padding: 0.3em;
  border-radius: 8px;
  cursor: pointer;/*
  position: relative;
  left: -30px; */
}
.page-table-container{
  margin-top: 20px;
}
.page-table-header{
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  font-size: 1.3em;
  color: #605F5F;
  display: flex;
  padding: 1vh 0;
  align-items: center;
}
.page-table-header-text{
  font-weight: 400;
  color: #605F5F;
  padding-left: 10px;
}
.category-width {
  width: 25%;
}
.qty-width {
  width: 25%;
}
.total-val-width {
  width: 25%;
}
.percent-width {
  width: 25%;
}
.category-width__print {
  width: 40%;
}
.qty-width__print {
  width: 20%;
}
.total-val-width__print {
  width: 30%;
}
.percent-width__print {
  width: 30%;
}

.page-table-row {
  display: flex;
  font-weight: 300;
  line-height: 14px;
  color: #605F5F;
  background-color: white;
}
.page-table-desc-column {
  padding: 0.8em;
  border-right: 1px solid #DDE5E9;
  display: flex;
  align-items: center;
}
.page-table-row-container{
  margin: 2vh 0;
}
.seemore-button-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 70px;
}
.page-selected-filters-container {
  display: flex;
  margin-top: 14px;
}
.page-selected-filters{
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text{
  font-weight: 300;
  font-size: 1.4em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}
.page-selected-filters-icon{
  color: var(--primary-color);
  cursor: pointer;
}
.page-buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2em 0em;
}
.page-buttons-container__left-container {
  display: flex;
}
.page-buttons-container__right-container {
  display: flex;
  align-items: center;
  margin-top: 0.7em;
}
.page-buttons-wrapper {
  width: 20vw;
  margin-right: 1em;
}
.page-buttons-wrapper__title {
  font-weight: 400;
  font-size: 1.7em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.action-button {
  width: 15vw;
}
.page-filters__total-value {
  padding: 0.55em;
  border-radius: 8px;
  background-color: var(--primary-color);
}
.page-filters__total-value-text {
  color: #FFF;
  font-weight: 600;
}
.page-filters-container {
  display: flex;
  align-items: center;
}
.page-filters__left-container {}
.page-filters__left-wrapper {}
.search-input {
  width: 23.3vw;
}
.page-filters__left-title-wrapper {}
.page-filters__left-title {
  font-weight: 400;
  font-size: 1.7em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.page-filters__left-select-wrapper {
  display: flex;
}
.page-filters__right-container {
  display: flex;
}
// .page-table-content{
//   border-left: #E5E5E5 1px solid;
//   border-bottom: #E5E5E5 1px solid;
// }
.page-filters__right-wrapper {
  margin-right: 10px;
}
.page-filters__right-title-wrapper {
  display: flex;
  align-items: center;
}
.page-filters__right-title {
  font-weight: 400;
  font-size: 1.7em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.category-analysis-sub-table{
  width: 100%;
}
.descricao-width {
  width: 24.6%;
}
.quantidade-width {
  width: 12.6%;
}
.valor-total-width {
  width: 16.6%;
}
.valor-unit-width {
  width: 12.6%;
}
.porcento-width {
  width: 16.6%;
}
.porcento-acumulado-width {
  width: 16.6%;
}
.product-tab-colapsed-icon{
  font-size: 1.5em;
  margin-right: 1vw;
}
.product-tab-expanded-icon{
  color: var(--primary-color);
  font-size: 1.5em;
  rotate: 90deg;
  margin-right: 1vw;
}
.page-filters__right-select-wrapper {
  display: flex;
  align-items: center;
}
.page-filters__total-value {
  display: flex;
  padding: 1.8em;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #E5E5E5;
  background-color: #fff;
}
.page-filters__total-value-text {
  color: #202020;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.05em;
}
.action-buttons-width {
  margin-left: 10px;
}
.page-filters__total-value__img {
  margin-right: 10px;
}
.page-filters__datetime-container {
  display: flex;
  align-items: center;
}
.page-filters__actions-container {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.standard-input-width {
  margin-right: 4.5em;
}
.search-icon {
  margin: 0em 0.2em;
  color: #fff;
  background-color: var(--primary-color);
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.preorder-seller-title-button-excel {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E5E5E5;
  border-radius: 9.5px;
  font-weight: 600;
  padding: 0 1vw;
  height: 7.5vh;
  font-size: 1.3em;
  cursor: pointer;
  margin-left: 10px;
}
.preorder-seller-title-button-excel-icon {
  margin-right: 1vw;
}
.reports-page__title-button-total {
  background: rgba(23, 162, 184, 0.1);
  border: 1px solid #E5E5E5;
  border-radius: 9.5px;
  font-weight: 600;
  font-size: 1.3em;
  color: #5281FF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1vw;
  height: 7.5vh;
}
.page-table-line-special {
  background: #F7F7F7;
}
.clients-analysis-mobile-header{
  display: none;
}

  @media only screen and (max-width: 800px) {
    .clients-analysis__page-filter-container {
      flex-direction: column;
    }
    .input-container {
      width: 95vw;
    }
    .mobile{
      display: block;
    }

    .input-title {
      width: 100vw;
    }
    .date-icon {
    }
    .page-filters-container {
          flex-wrap: wrap;
          gap: 0 5vw;
        }
        .page-buttons-container {
          flex-direction: column;
          align-items: flex-start;

        }
        .page-buttons-container__left-container {
          flex-direction: column;
        }
        .page-buttons-wrapper {
          width: 100vw;
        }
        .page-buttons-wrapper__select {
        }
        .page-buttons-container__right-container {
          flex-direction: column;

        }
        .page-filters__right-container {
          flex-direction: column;
        }
        .end-date {
          width: 57vw;
        }
        .page-filters__left-select-wrapper {
          width: 100vw;
        }
        .page-filters__total-value {
          width: 100vw;
        }
        .page-filters__left-container {
          display: flex;
          flex-direction: column;
        }
        .button-container {
          margin: 5px 0px;
        }
        .page-buttons-wrapper {
          margin: 5px 0px;
        }
        .page-filters__left-select-wrapper {
          margin: 5px 0px;
        }
        .page-filters__right-wrapper {
          margin: 5px 0px;
        }
        .standard-input-width {
          width: 55vw !important;
          margin-right: 0;
        }
        .action-buttons-width {
          width: 35vw !important;
        }
        .page-filters__datetime-container {
          flex-wrap: wrap;
        }
        .page-filters__actions-container {
          flex-wrap: wrap;
        }
        .page-table-header{
          display: none
        }
        .page-table-row{
          flex-direction: column;
          gap: 1vh;
          margin-bottom: 3vh
        }
        .page-table-desc-column{
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex: unset
        }
        .clients-analysis-mobile-header{
          display: flex;
          font-weight: 700;
          font-size: 1.1em
        }
        .page-table-container{display: none;}
}
@media print {
  .page-table-content{width: 100%}
}

@import '@/sass/commons/_variables';

.totalDiv {
    width: fit-content;
    color: white;
    height: 100%;
    background-color: $secondary-color;
    text-align: right;
    padding: 0.7rem 1.5rem !important;
}
</style>

<template>
    <div class="mobile-seller-container">
        <h6 @click="getProdutosInternos(client)">
            <span v-if="!client.expanded" class="material-icons seller-tab-colapsed-icon">arrow_forward_ios</span>
            <span v-else class="material-icons seller-tab-expanded-icon">arrow_forward_ios</span>
            {{ client.loj_descricao }}
        </h6>
        <div class="mobile-table-row">
            <div class="unit-width">
                <h6 class="page-table-mobile-header">
                    Quantidade
                </h6>
                {{ client.quantidade }}
            </div>
            <div class="unit-width" style="text-align: center;">
                <h6 class="page-table-mobile-header">
                    Valor Total
                </h6>
                {{ client.valorTotal | currency }}
            </div>
            <div class="unit-width" style="text-align: end;">
                <h6 class="page-table-mobile-header">
                    %
                </h6>
                {{ getPercentual(client) }}
            </div>
        </div>
        
        <div v-if="client.loading" :key="'prod-loader-' + client.pro_id">
            <cc-loader style="margin: 10px auto" />
        </div>
        <div v-if="client.expanded">
            <SubSellerMobileRow v-for="(info, index ) in client.produtos" :key="`inf-line-${index}`"
                :seller="client" :product="info" />
        </div>

    </div>
</template>

<script>
import numeral from "numeral";
import SubSellerMobileRow from "./SubSellerMobileRow.vue";
import * as CONSTANTS from "@/constants/constants";

export default {
    name: 'ClientMobileRow',
    props: {
        client: Object,
        totalPedido: Number
    },
    components: {
        SubSellerMobileRow,
    },
    // data() {
    //     return {
    //         seller: this.produto
    //     }
    // },
    methods: {
        formartNumber(n) {
            if (n) {
                return numeral(n.toString().replace(".", ",")).format("0,0.00");
            } else {
                return n;
            }
        },
        getPercentual(produto) {
            return (
                parseFloat((produto.valorTotal * 100) / this.totalPedido).toFixed(2) +
                "%"
            );
        },
        getProdutosInternos(produto) {
          if (produto.expanded) {
            produto.expanded = false
            this.$forceUpdate()
            return
          }
          if (!produto.expanded && produto.produtos) {
            produto.expanded = true
            this.$forceUpdate()
            return
          }
          console.log(produto);
          produto.loading = true;
            this.$forceUpdate()
            var requestUrl =
                `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?requisitante=` +
                produto.loj_id +
                "&groupBy=p.pro_id&imprimir=true&ordenar=valorTotal&tipoorder=desc";
            requestUrl +=
                "&dateIni=" + this.$store.state.dateIniRelatorio
                +
                "&dateFim=" + this.$store.state.dateFimRelatorio

            axios.get(requestUrl, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            })
            .then(({ data }) => {
              produto.produtos = data.produtos
              let lastPercent = 0;
              produto.produtos.forEach(element => {
                element.percent = parseFloat((element.valorTotal * 100) / produto.valorTotal).toFixed(2)
                element.percentAcumulate = parseFloat(parseFloat(element.percent) + parseFloat(lastPercent)).toFixed(2);
                lastPercent = element.percentAcumulate;
              });
              produto.loading = false;
              produto.expanded = true
              this.$forceUpdate()
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.mobile-table-row {
    justify-content: space-between;
    display: flex;
    margin-top: 1.5vh;
}

.mobile-seller-container {
    border-bottom: 1px solid lightgray;
    padding-bottom: 3vh;
    padding-top: 2vh;
}

.unit-width {
    width: 30%;
    font-size: 1.4em;
}

h6 {
    font-weight: bold;
    margin: 0;
}

.seller-tab-colapsed-icon {
    font-size: 1em;
    margin-right: 1vw;
}

.seller-tab-expanded-icon {
    color: var(--primary-color);
    font-size: 1em;
    rotate: 90deg;
    margin-right: 1vw;
}

.page-table-mobile-header {
    font-size: 1.5em;
    font-weight: normal;
}
</style>
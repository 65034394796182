<template>
    <div class="table-container">
        <table class="desktop">
            <tr>
                <th style="width: 10%;">Ean</th>
                <th style="width: 40%;">Nome</th>
                <th>Marca</th>
                <th>Embalagem</th>
                <th> Cadastro </th>
            </tr>
            <tr class="product-text" v-for="(p, idx) in products" :key="idx">
                <td>{{ p.produto_principal ? p.produto_principal.pro_ean : p.pro_ean  }}</td>
                <td>{{ p.produto_principal ? p.produto_principal.pro_descricao : p.pro_descricao }} </td>
                <td :title="p.pro_observacao">
                    <div class="obs-text">{{ p.pro_observacao || '--' }}</div>
                </td>
                <td >{{ p.produto_principal ? p.pro_unidade_compra : p.pro_embalagem }}</td>
                <td>{{ p.pro_dataCadastro | formatDateOnly }}</td>
            </tr>
        </table>

        <div class="mobile" v-for="(p, idx) in products" :key="idx" style="margin-bottom: 3vh;">
            <h5 style="font-size: 1.5em; font-weight: bold; margin: 0;">{{ p.produto_principal ? p.produto_principal.pro_descricao : p.pro_descricao }}</h5>
            <p style="font-size: 1.4em; margin: 0;">{{ p.produto_principal ? p.produto_principal.pro_ean : p.pro_ean  }}</p>

            <div style="display: flex; justify-content: space-between; font-size: 1.4em;">
                <div style="width: 30%;">
                    <b>Marca</b>
                    <p>{{ p.pro_observacao || '--' }}</p>
                </div>
                <div style="width: 30%; text-align: center;">
                    <b>Embalagem</b>
                    <p>{{ p.produto_principal ? p.pro_unidade_compra : p.pro_embalagem }}</p>
                </div>
                <div style="width: 30%; text-align: end;">
                    <b>Cadastro</b>
                    <p>{{ p.pro_dataCadastro | formatDateOnly }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RegisterProductsTable',
    props: {
        products: {
            type: Array
        },
        categories: {
            type: Array
        }
    },
    data() {
        return {
            totalOrder: undefined,
        }
    },
    methods: {
        getCategory(p) {
            const resp = this.categories.filter(c => c.id == p.cat_id)
            return resp.length > 0 ? resp[0].cat_descricao : '--'
        },
        toggleOrder() {
            if (this.totalOrder == 'asc') this.totalOrder = 'desc'
            else this.totalOrder = 'asc'
        }
    }
}
</script>

<style lang="scss" scoped>
.table-container {
    height: 60vh;
    overflow: auto;
}

.product-text {
    font-weight: 400;
    font-size: 1.2em;
    color: #606060;
    border-bottom: 1px solid lightgray;
    padding-bottom: 0.5vh;
}

th {
    font-size: 1rem;
}

.red-alert {
    color: lightcoral;
}
.obs-text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 10vw;
}
.mobile{
    display: none;
}

@media only screen and (max-width: 700px) {
    .table-container {
        height: 55vh;
    }
    .desktop{
        display: none;
    }
    .mobile{
        display: block;
    }
    p{
        margin: 0;
    }
}
</style>
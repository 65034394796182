<template>
    <div class="mobile-sub-product-container">
        <h6>
            {{ store.loj_razao }}
        </h6>
        <div class="mobile-table-row">
            <div class="unit-width">
                <h6 class="page-table-mobile-header">
                    Nome fantasia
                </h6>
                {{ store.loj_nomeFantasia }}
            </div>
            <div class="unit-width" style="text-align: center;">
                <h6 class="page-table-mobile-header">
                    Valor comprado
                </h6>
                {{ store.total_value | currency }}
            </div>
            <div class="unit-width" style="text-align: end;">
                <h6 class="page-table-mobile-header">
                    Percentual
                </h6>
                {{ ( store.total_value / buyer.total_value * 100).toFixed(2) }}%
            </div>
        </div>
    </div>
</template>

<script>
import numeral from "numeral";

export default {
    name: 'SubBuyerMobileRow',
    props: {
        store: Object,
        buyer: Object
    },
    methods: {
        formartNumber(n) {
            if (n) {
                return numeral(n.toString().replace(".", ",")).format("0,0.00");
            } else {
                return n;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.mobile-table-row {
    justify-content: space-between;
    display: flex;
}

.mobile-sub-product-container {
    border-top: 1px solid lightgray;
    padding-bottom: 3vh;
    padding-top: 2vh;
}

.unit-width {
    width: 30%;
    font-size: 1.4em;
}

h6 {
    font-weight: bold;
    margin: 0;
}

.product-tab-colapsed-icon {
    font-size: 1em;
    margin-right: 1vw;
}

.product-tab-expanded-icon {
    color: var(--primary-color);
    font-size: 1em;
    rotate: 90deg;
    margin-right: 1vw;
}

.page-table-mobile-header {
    font-size: 1.5em;
    font-weight: normal;
}
</style>
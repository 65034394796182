<template>
    <div>
        <div class="page-filters__datetime-container">
            <div class="page-filters__right-wrapper">
                <div class="page-filters__right-title-wrapper">
                    <div class="page-filters__right-title">Data Inicial</div>
                </div>
                <div class="page-filters__right-select-wrapper end-date">
                    <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker"
                        v-model="$store.state.dateIniRelatorio" auto type="date"></datetime>
                    <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                        today
                    </span>
                </div>
            </div>
            <div class="page-filters__right-wrapper">
                <div class="page-filters__right-title-wrapper">
                    <div class="page-filters__right-title">Data Fim</div>
                </div>
                <div class="page-filters__right-select-wrapper end-date">
                    <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker"
                        v-model="$store.state.dateFimRelatorio" auto type="date"></datetime>
                    <span @click="open_datepicker(1)" class="material-icons-outlined date-icon">
                        today
                    </span>
                    <span @click="loadBuyers" class="material-icons-outlined search-icon">
                        search
                    </span>
                </div>
            </div>
            <div class="action-buttons-width">
                <Button title="Limpar Filtros" :text="'Limpar Filtros'" class="mobile-button-size" :iconName="'cancel'" :action="reset_filter" />
                <div class="reports-page__title-button-total mobile-button-size">
                    <div style="display: flex; align-items: center;">
                        Valor Total: {{ total | currency }}
                    </div>
                </div>
            </div>

        </div>
        <div class="desktop">
            <div class="page-table-header">
                <div class="expand-width"></div>
                <div class="page-table-header-text name-width">Nome</div>
                <div class="page-table-header-text total-width">Valor total</div>
                <div class="page-table-header-text total-width">Percentual</div>
            </div>
            <div v-for="i in buyers" :key="i.name">
                <div class="page-table-row">
                    <div class="expand-width" @click="openItem(i)">
                        <span v-if="!i.expanded" class="material-icons-outlined">keyboard_arrow_down</span>
                        <span v-else class="material-icons-outlined">keyboard_arrow_up</span>
                    </div>
                    <div class="page-table-desc-column name-width">
                        {{ i.usu_nome }}
                    </div>
                    <div class="page-table-desc-column total-width">
                        {{ i.total_value | currency }}
                    </div>
                    <div class="page-table-desc-column total-width">
                        {{i.percent.toFixed(2)}}%
                    </div>
                </div>
                <div class="page-table-line-special" style="border-bottom: 1px solid #E5E5E5;" v-if="i.expanded">
                    <div style="display: flex; justify-content: space-around;">
                        <div style="border-left: 1px solid #E5E5E5;" class="page-table-header-text sub-table-colum">Razão
                            loja</div>
                        <div class="page-table-header-text sub-table-colum">Nome fantasia</div>
                        <div class="page-table-header-text sub-table-colum" style="text-align: center;">Valor comprado</div>
                        <div class="page-table-header-text sub-table-colum" style="text-align: center;">Percentual</div>
                    </div>
                    <div v-for="s in i.stores" style="display: flex; justify-content: space-around;">
                        <div style="border-left: 1px solid #E5E5E5;" class="page-table-header-text sub-table-colum">{{
                            s.loj_razao }}</div>
                        <div class="page-table-header-text sub-table-colum">{{ s.loj_nomeFantasia }}</div>
                        <div class="page-table-header-text sub-table-colum" style="text-align: center;">{{ s.total_value | currency }}</div>
                        <div class="page-table-header-text sub-table-colum" style="text-align: center;">{{ ( s.total_value / i.total_value * 100).toFixed(2) }}%</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile">
            <BuyerMobileRow v-for="i in buyers" :key="i.name" :buyer="i"/>
        </div>

        <cc-loader-full v-if="isLoading" />
    </div>
</template>

<script>
import ClientServiceV3 from "@/services/v3/ClientService.js"
import Button from "@/components/ui/buttons/Standard-Button.vue"
import BuyerMobileRow from "./BuyerMobileRow.vue";

export default {
    name: 'buyersComponent',
    components: {
        Button,
        BuyerMobileRow
    },
    data() {
        return {
            buyers: [],
            svc: new ClientServiceV3(),
            isLoading: true,
            total: 0
        }
    },
    mounted() {
        this.loadBuyers()
        
    },
    methods: {
        loadBuyers() {
            this.isLoading = true
            this.svc.loadBuyers(this.$store.state.dateIniRelatorio, this.$store.state.dateFimRelatorio).then(resp => {
                this.buyers = resp.data
                if (this.buyers.length > 0) this.total = this.buyers.reduce((t,i)=> t + i.total_value,0)
                else this.total = 0
                this.buyers.forEach(i => i.percent = i.total_value / this.total * 100)
                this.isLoading = false
            })
        },
        open_datepicker(idx) {
            document.getElementsByClassName('vdatetime-input')[idx].click()
        },
        reset_filter() {
            this.$store.state.dateIniRelatorio = undefined
            this.$store.state.dateFimRelatorio = undefined
            this.loadBuyers()
        },
        openItem(i) {
            if (i.stores) {
                i.expanded = !i.expanded
                this.$forceUpdate()
                return
            }
            this.isLoading = true
            this.svc.getBuyer(i.usu_id, this.$store.state.dateIniRelatorio, this.$store.state.dateFimRelatorio).then(resp => {
                i.stores = resp.data
                i.expanded = true
                this.isLoading = false
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.page-table-header {
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    color: #605F5F;
    font-size: 1.3em;
    display: flex;
    align-items: center;
    padding: 1vh 0;
    margin-top: 4vw;
}

.price-analysis-mobile-header {
    display: none;
}

.page-table-header-text {
    font-weight: 400;
    color: #605F5F;
    padding-left: 10px;
}

.name-width {
    flex: 1;
}

.expand-width {
    border-left: 1px solid #DDE5E9;
    text-align: center;
    width: 3%;
}

.total-width {
    width: 15%;
}

.page-table-row {
    display: flex;
    font-weight: 300;
    color: #605F5F;
    border-bottom: 1px solid #DDE5E9;
    align-items: center;
    cursor: pointer;
}

.page-table-line-special {
    background: #F7F7F7;
}

.page-table-desc-column {
    padding: 0.8em;
    border-right: 1px solid #DDE5E9;
}

.sub-table-colum {
    flex: 1;
    padding-left: 2%;
    border-right: 1px solid #DDE5E9;
    border-bottom: 1px solid #DDE5E9;
}

.reports-page__title-button-total {
    background: rgba(23, 162, 184, 0.1);
    border: 1px solid #E5E5E5;
    border-radius: 9.5px;
    font-weight: 600;
    font-size: 1.3em;
    color: #5281FF;
    display: flex;
    align-items: center;
    padding: 0 1vw;
    height: 7.5vh;
}

.page-filters__datetime-container {
    display: flex;
    align-items: center;
    margin-top: 2em;
}

.page-filters__right-title {
    font-weight: 400;
    font-size: 1.7em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #898989;
}

.page-filters__right-select-wrapper {
    display: flex;
    align-items: center;
}

.date-icon {
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
    padding: 8px 10px;
    border-radius: 0px 8px 8px 0px;
    position: relative;
    left: -6px;
}

.search-icon {
    margin: 0em 0.2em;
    color: #fff;
    background-color: var(--primary-color);
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.action-buttons-width {
    margin-top: 20px;
    display: flex;
    gap: 4vw;
    margin-left: auto;
}

.page-filters__right-wrapper {
    margin-right: 10px;
}
.mobile{
    display: none;
}

@media only screen and (max-width: 800px) {
    .page-filters__datetime-container {
        flex-wrap: wrap;

    }
    .desktop{
        display: none;
    }
    .mobile{
        display: block;
    }

    .action-buttons-width {
        width: 100%;
    }

    .page-filters__right-wrapper {
        margin-right: 0;
        width: 50%;
    }

    .total-width {
        width: 25%;
    }

    .expand-width {
        width: 10%;
    }
    .mobile-button-size{
        width: 50%;
        justify-content: center;
    }

}
</style>
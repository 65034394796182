<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <!-- <div class="modal-pendecie-close-container">
        <span v-on:click="close" class="material-icons-outlined modal-pendecie-close">cancel</span>
      </div> -->
      <span :style="getIconColor()" class="material-icons modal-pendecie-icon">monetization_on</span>
      <div class="modal-pendecie-title">{{ title ? title : 'Pendência Identificada:' }} </div>
      <div style="text-align: center; color: #605F5F;">{{text ? text :'Ops!! Identificamos Fatura(s) Vencida(s). Evite o bloqueio de sua conta.'}}</div>
      <div v-if="text2" style="text-align: center; color: #605F5F; font-size: 0.8em;">{{ text2 }}</div>
      <div class="modal-pendencie">
        <router-link v-if="!hideButton" to="/configuracoes/plan" class="modal-pendecie-button">Ver pendecias</router-link>
        <a v-else class="modal-pendecie-button" href="https://api.whatsapp.com/send?phone=558184400160" target="_blank">Acionar departamento financeiro</a>
        <button v-on:click="close" class="modal-pendecie-button-cancel">Cancelar</button>
      </div>
      
    </div>
  </div>
</template>

<script>
export default {
  name: 'PendecieNotification',
  props:['close', 'text', 'hideButton', 'title', 'text2'],
  methods:{
    getIconColor(){
      if (this.title) return 'color: green'
    }
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1.5vw;
}
.modal-content-container{
  background-color: white;
  min-width: 50vw;
  border-radius: 12px;
  padding: 3.5vh 2vw;
  max-width: 65vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
}
.modal-pendecie-title{
  font-size: 1.5em;
  color: #605F5F;
  text-align: center;
}
.modal-pendecie-icon{
  color: red;
  font-size: 2.5em;
}
.modal-pendecie-button{
  padding: 1vw;
  color: white;
  background-color: rgb(48, 133, 214);
  border-radius: 8px;
  font-size: .8em;
  padding: .325em .9em;
  width: 30%;
  cursor: pointer;
  text-align: center;
}
.modal-pendecie-button-cancel{
  padding: 1vw;
  color: white;
  border: none;
  background-color: rgb(221, 51, 51);
  border-radius: 8px;
  font-size: .8em;
  padding: .325em .9em;
  width: 30%;
  cursor: pointer;
}
.modal-pendecie-close-container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  
}
.modal-pendecie-close{
  color: red;
  margin-right: 3%;
  cursor: pointer;
  font-size: 2em;
}
.modal-pendencie{
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 3vh;
}
@media only screen and (max-width: 800px) {
  .modal-content-container{
    width: 110vw;
    max-width: none;
    font-size: 4vw;
  }
}
</style>
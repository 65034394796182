<template>
    <div class="mobile-product-container">
        <h6 @click="getProdutosInternos(product)">
            <span v-if="!product.expanded" class="material-icons product-tab-colapsed-icon">arrow_forward_ios</span>
            <span v-else class="material-icons product-tab-expanded-icon">arrow_forward_ios</span>
            {{ product.pro_descricao }}
        </h6>
        <div class="mobile-table-row">
            <div class="unit-width">
                <h6 class="page-table-mobile-header">
                    Categoria
                </h6>
                {{ product.cat_descricao }}
            </div>
            <div class="unit-width" style="text-align: center;">
                <h6 class="page-table-mobile-header">
                    Quantidade
                </h6>
                {{ product.quantidade }}
            </div>
            <div class="unit-width" style="text-align: end;">
                <h6 class="page-table-mobile-header">
                    Qtd. cotações
                </h6>
                {{ product.qtdCots }}
            </div>
        </div>
        <div class="mobile-table-row">
            <div class="unit-width">
                <h6 class="page-table-mobile-header">
                    Med. Cmp.
                </h6>
                {{ (product.quantidade / product.qtdCots).toFixed(2) }}
            </div>
            <div class="unit-width" style="text-align: center;">
                <h6 class="page-table-mobile-header">
                    Valor Unit.
                </h6>
                {{ product.cli_novoPedido == 1 && product.cli_tipoPedido == 1 ?
                    formartNumber(product.valorTotal / product.quantidade)
                    : formartNumber(product.valorTotal / product.quantidade)
                }}
            </div>
            <div class="unit-width" style="text-align: end;">
                <h6 class="page-table-mobile-header">
                    Valor Total
                </h6>
                {{ formartNumber(product.valorTotal) }}
            </div>
        </div>
        <div class="mobile-table-row">
            <div class="unit-width">
                <h6 class="page-table-mobile-header">
                    Porcentagem
                </h6>
                {{ product.percent }}%
            </div>
            <div class="unit-width" style="text-align: end;">
                <h6 class="page-table-mobile-header">
                    % Acumulado
                </h6>
                {{ product.percentAcumulate }}%
            </div>
        </div>
        <div v-if="produto.loading" :key="'prod-loader-' + produto.pro_id">
            <cc-loader style="margin: 10px auto" />
        </div>
        <div v-if="product.expanded">
            <SubProductMobileRow v-for="(info, index ) in produto.fornecedores" :key="`inf-line-${index}`"
                :produto="info" />
        </div>

    </div>
</template>

<script>
import numeral from "numeral";
import SubProductMobileRow from "./SubProductMobileRow.vue";
import * as CONSTANTS from "@/constants/constants";

export default {
    name: 'ProductMobileRow',
    props: {
        produto: Object
    },
    components: {
        SubProductMobileRow,
    },
    data() {
        return {
            product: this.produto
        }
    },
    methods: {
        formartNumber(n) {
            if (n) {
                return numeral(n.toString().replace(".", ",")).format("0,0.00");
            } else {
                return n;
            }
        },
        getProdutosInternos(product) {
            product.loading = true;
            if (!product.fornecedores) {
                console.log('forn');

                var requestUrl =
                    `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?produtoFilter=` +
                    product.pro_id +
                    "&groupBy=pe.id_cotacao&imprimir=true&ordenar=data_geracao&tipoorder=desc";
                const dateIni = moment(
                    this.$store.state.dateIniRelatorio
                ).format("YYYY-MM-DD");
                const dateFim = moment(
                    this.$store.state.dateFimRelatorio
                ).format("YYYY-MM-DD");
                requestUrl += "&dateIni=" + dateIni + "&dateFim=" + dateFim;
                axios
                    .get(requestUrl, {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    })
                    .then(({ data }) => {
                        console.log('final');

                        product.fornecedores = data.produtos;
                        product.expanded = !product.expanded;
                        this.$forceUpdate();
                        product.loading = false;
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            if (
                                error.response.data.mensagem ==
                                CONSTANTS.TOKEN_EXPIRADO
                            ) {
                                // location.href = CONSTANTS.ROUTE_LOGIN;
                                window.location.assign(CONSTANTS.ROUTE_LOGIN)
                            }
                        } else if (error.response.status === 500) {
                            if (error.response.data.mensagem) {
                                this.mensagemErro =
                                    error.response.data.mensagem;
                            } else {
                                this.mensagemErro =
                                    "Não foi possível abrir os dados gerenciais";
                            }
                        }
                        product.loading = false;
                    });
            } else {
                product.expanded = !product.expanded;
                product.loading = false;
            }
            this.$forceUpdate();
        },
    },
}
</script>

<style lang="scss" scoped>
.mobile-table-row {
    justify-content: space-between;
    display: flex;
    margin-top: 1.5vh;
}

.mobile-product-container {
    border-bottom: 1px solid lightgray;
    padding-bottom: 3vh;
    padding-top: 2vh;
}

.unit-width {
    width: 30%;
    font-size: 1.4em;
}

h6 {
    font-weight: bold;
    margin: 0;
}

.product-tab-colapsed-icon {
    font-size: 1em;
    margin-right: 1vw;
}

.product-tab-expanded-icon {
    color: var(--primary-color);
    font-size: 1em;
    rotate: 90deg;
    margin-right: 1vw;
}

.page-table-mobile-header {
    font-size: 1.5em;
    font-weight: normal;
}
</style>
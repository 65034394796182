<template>
    <div class="product-analysis" v-on:click="closeProductSelect">
        <div class="product-analysis__container">
            <div class="row only-print">
                <div class="col p-4 text-center">
                    <img
                        class="logo"
                        style="width: 100px"
                        :src="whitelabel ? whitelabel.data.imgs.img_logo : require('@/assets/logo.png')"
                    />
                </div>
            </div>
            <div class="row only-print">
                <div class="col">
                    <p style="padding: 0px; margin: 0px">Nome Produto:</p>
                    <span>{{ filtroProduto ? filtroProduto : "Todos" }}</span>
                </div>
                <div class="col">
                    <p style="padding: 0px; margin: 0px">Requisitante:</p>
                    <span>{{ getNomeRequisitante }}</span>
                </div>
                <div class="col">
                    <p style="padding: 0px; margin: 0px">Fornecedor:</p>
                    <span>{{
                        getNomeFornecedor ? getNomeFornecedor : "Todos"
                    }}</span>
                </div>
                <div class="col">
                    <p style="padding: 0px; margin: 0px">Categoria:</p>
                    <span>{{
                        getNomeCategoria ? getNomeCategoria : "Todos"
                    }}</span>
                </div>
                <div class="col" v-if="$store.state.dateIniRelatorio">
                    <p style="padding: 0px; margin: 0px">Data Inicial:</p>
                    <span>{{
                        $store.state.dateIniRelatorio | formatDate
                    }}</span>
                </div>
                <div class="col" v-if="$store.state.dateFimRelatorio">
                    <p style="padding: 0px; margin: 0px">Data Final:</p>
                    <span>{{
                        $store.state.dateFimRelatorio | formatDate
                    }}</span>
                </div>
            </div>
            <div class="page-buttons-container not-print">
                <div class="page-filters__right-container">
                    <div class="page-filters__datetime-container">
                        <div class="page-filters__right-wrapper">
                            <div class="page-filters__right-title-wrapper">
                                <div class="page-filters__right-title">
                                    Data Inicial
                                </div>
                            </div>
                            <div class="page-filters__right-select-wrapper">
                                <datetime
                                    style="border-radius: 8px !important"
                                    class="form-control"
                                    ref="datepicker"
                                    v-model="$store.state.dateIniRelatorio"
                                    auto
                                    type="date"
                                ></datetime>
                                <span
                                    @click="open_datepicker(0)"
                                    class="material-icons-outlined date-icon"
                                >
                                    today
                                </span>
                            </div>
                        </div>
                        <div class="page-filters__right-wrapper">
                            <div class="page-filters__right-title-wrapper">
                                <div class="page-filters__right-title">
                                    Data Fim
                                </div>
                            </div>
                            <div
                                class="page-filters__right-select-wrapper end-date"
                            >
                                <datetime
                                    style="border-radius: 8px !important"
                                    class="form-control"
                                    ref="datepicker"
                                    v-model="$store.state.dateFimRelatorio"
                                    auto
                                    type="date"
                                ></datetime>
                                <span
                                    @click="open_datepicker(0)"
                                    class="material-icons-outlined date-icon"
                                >
                                    today
                                </span>
                                <span
                                    @click="getProdutos(1, false)"
                                    class="material-icons-outlined search-icon"
                                >
                                    search
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="page-filters__actions-container">
                        <Button
                            class="action-buttons-width"
                            title="Imprimir produtos"
                            :text="'Imprimir'"
                            :iconName="'print'"
                            :action="print"
                        />
                        <div
                            @click="getProdutosExcel"
                            class="preorder-seller-title-button-excel action-buttons-width"
                        >
                            <div style="display: flex; align-items: center">
                                <img
                                    class="preorder-seller-title-button-excel-icon"
                                    src="@/assets/excel.svg"
                                    alt="excel"
                                />
                                Exportar Excel
                            </div>
                        </div>
                        <!-- <Button class="action-buttons-width" title="Exportar excel" :text="'Exportar Excel'" :iconName="'note_add'" :action="getProdutosExcel"/> -->
                        <Button
                            class="action-buttons-width"
                            title="Limpar Filtros"
                            :text="'Limpar Filtros'"
                            :iconName="'cancel'"
                            :action="reset_filter"
                        />
                        <div class="reports-page__title-button-total action-buttons-width">
                                Valor Total: R$ {{ formartNumber(totalPedido) }}
                        </div>
                    </div>
                    <!-- <Button class="action-buttons-width" title="Visualizar Tabela" :text="'Visualizar Tabela'" :iconName="'visibility'" :action="openTable"/> -->
                </div>
            </div>
            <div class="page-filters-container not-print">
                <div class="Select-containear-product">
                    <div class="input-title">Buscar por empresa</div>
                    <div
                        class="input-containear"
                        v-on:click.stop="openProductSelect()"
                    >
                        Selecione o empresa
                    </div>
                    <div v-if="showSelectBody">
                        <div class="select-body">
                            <div
                                v-on:click.stop="toogleCheck(req)"
                                v-for="(req, idx) in $store.state
                                    .requisitantesFiltroArray"
                                :key="idx"
                                class="select-body-line"
                            >
                                <span
                                    v-if="req.isCheck"
                                    class="material-icons checkbox-icon"
                                    >check_box</span
                                >
                                <span
                                    v-else
                                    class="material-icons checkbox-icon"
                                    >check_box_outline_blank</span
                                >
                                <div class="select-body-line-text">
                                    {{ req.text }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <StandardInput
                    class="standard-input-width"
                    :title="'Fornecedor'"
                    :action="setFornecedorValue"
                    :width="'22%'"
                    :type="'select'"
                    :list="$store.state.fornecedorFiltroArray"
                    :placeholder="'Selecione o fornecedor'"
                />
                <StandardInput
                    class="standard-input-width"
                    :title="'Categoria'"
                    :action="setCategoriaValue"
                    :width="'22%'"
                    :type="'select'"
                    :list="$store.state.categoriasFiltroArray"
                    :placeholder="'Selecione a categoria'"
                />
                <StandardInput
                    class="standard-input-width"
                    :title="'Produto'"
                    :action="setValue"
                    :width="'22%'"
                    :type="'text'"
                    :placeholder="'Digite o nome'"
                />
            </div>
            <div class="page-selected-filters-container">
                <div v-if="filtroProduto" class="page-selected-filters">
                    <div class="page-selected-filters-text">
                        {{ filtroProduto }}
                    </div>
                    <span
                        v-on:click="setValue('')"
                        class="material-icons page-selected-filters-icon"
                        >close</span
                    >
                </div>
                <div
                    v-for="(requisitante, index) in filtroRequisitante"
                    :key="index"
                    class="page-selected-filters not-print"
                >
                    <div class="page-selected-filters-text">
                        {{ requisitante.loj_descricao }}
                    </div>
                    <!-- <span @click="removeRequisitanteValue(requisitante)" class="material-icons page-selected-filters-icon">close</span> -->
                </div>
                <div v-if="filtroFornecedor" class="page-selected-filters">
                    <div class="page-selected-filters-text">
                        {{ filtroFornecedor.for_nome }}
                    </div>
                    <span
                        @click="setFornecedorValue('')"
                        class="material-icons page-selected-filters-icon"
                        >close</span
                    >
                </div>
                <div v-if="filtroCategoria" class="page-selected-filters">
                    <div class="page-selected-filters-text">
                        {{ filtroCategoria.cat_descricao }}
                    </div>
                    <span
                        @click="setCategoriaValue('')"
                        class="material-icons page-selected-filters-icon"
                        >close</span
                    >
                </div>
            </div>
            <no-data-available
                v-if="produtos.length == 0 && !loading"
                :text="'Nenhum resultado encontrado'"
            />
            <div
                v-if="produtos.length > 0 && !loading && !showTable"
                class="page-table-container not-print"
            >
                <div class="page-table-content">
                    <div class="page-table-header">
                        <div class="page-table-header-text code-width">
                            Código
                        </div>
                        <div class="page-table-header-text desc-width">
                            Descrição
                            <cc-label-sort
                                :showicon="ordernar == 'p.pro_descricao'"
                                :direction="tipoOrder == 'asc' ? 1 : 0"
                                @click="
                                    setFiltroOrder('p.pro_descricao', 'desc')
                                "
                            />
                        </div>
                        <div class="page-table-header-text category-width">
                            Categoria
                        </div>
                        <div class="page-table-header-text qty-width">
                            Qtd.
                            <cc-label-sort
                                :showicon="ordernar == 'quantidade'"
                                :direction="tipoOrder == 'asc' ? 1 : 0"
                                @click="setFiltroOrder('quantidade', 'desc')"
                            />
                        </div>
                        <div class="page-table-header-text qty-width">
                            Embalagem
                        </div>
                        <div class="page-table-header-text qty-width">
                            Qtd. Cot
                        </div>
                        <div class="page-table-header-text qty-width">
                            Med. Cmp.
                        </div>
                        <div class="page-table-header-text unit-val-width">
                            Valor Unit.
                        </div>
                        <div class="page-table-header-text total-val-width">
                            Valor Total
                            <cc-label-sort
                                :showicon="ordernar == 'valorTotal'"
                                :direction="tipoOrder == 'asc' ? 1 : 0"
                                @click="setFiltroOrder('valorTotal', 'desc')"
                            />
                        </div>
                        <div class="page-table-header-text percent-width">
                            %
                        </div>
                        <div
                            class="page-table-header-text percent-accumulated-width"
                        >
                            % Acumulado
                        </div>
                    </div>
                    <div
                        v-for="(produto, idx) in produtos"
                        :key="'pro-tab-' + produto.pro_id"
                        @click="clickTable(produto)"
                    >
                        <div class="page-table-row-container">
                            <div
                                class="page-table-row"
                                :class="getSpecialBackground(idx)"
                            >
                                <div class="page-table-desc-column code-width">
                                    <div
                                        class="products-analysis-mobile-header"
                                    >
                                        Código
                                    </div>
                                    <span
                                        v-if="!produto.expanded"
                                        class="material-icons product-tab-colapsed-icon"
                                        >arrow_forward_ios</span
                                    >
                                    <span
                                        v-else
                                        class="material-icons product-tab-expanded-icon"
                                        >arrow_forward_ios</span
                                    >
                                    {{ produto.pro_ean }}
                                </div>
                                <div class="page-table-desc-column desc-width">
                                    <div
                                        class="products-analysis-mobile-header"
                                    >
                                        Descrição
                                    </div>
                                    {{ produto.pro_descricao }}
                                </div>
                                <div
                                    class="page-table-desc-column category-width"
                                >
                                    <div
                                        class="products-analysis-mobile-header"
                                    >
                                        Categoria
                                    </div>
                                    {{ produto.cat_descricao }}
                                </div>
                                <div class="page-table-desc-column qty-width">
                                    <div
                                        class="products-analysis-mobile-header"
                                    >
                                        Qtd.
                                    </div>
                                    {{ produto.quantidade }}
                                </div>
                                <div class="page-table-desc-column qty-width">
                                    {{ produto.pro_generico == 1 ? produto.pro_unidade_compra : produto.embalagem_2 }}
                                </div>
                                <div class="page-table-desc-column qty-width">
                                    <div
                                        class="products-analysis-mobile-header"
                                    >
                                        Qtd. Cot
                                    </div>
                                    {{ produto.qtdCots }}
                                </div>
                                <div class="page-table-desc-column qty-width">
                                    <div
                                        class="products-analysis-mobile-header"
                                    >
                                        Med. Cmp.
                                    </div>
                                    {{ (produto.quantidade / produto.qtdCots).toFixed(2) }}
                                </div>
                                <div
                                    class="page-table-desc-column unit-val-width"
                                >
                                    <div
                                        class="products-analysis-mobile-header"
                                    >
                                        Valor Unit.
                                    </div>
                                    {{
                                        produto.cli_novoPedido == 1 &&
                                        produto.cli_tipoPedido == 1
                                            ? formartNumber(
                                                  produto.valorTotal /
                                                      produto.quantidade
                                              )
                                            : formartNumber(
                                                  produto.valorTotal /
                                                      produto.quantidade
                                              )
                                    }}
                                </div>
                                <div
                                    class="page-table-desc-column total-val-width"
                                >
                                    <div
                                        class="products-analysis-mobile-header"
                                    >
                                        Valor Total
                                    </div>
                                    {{ formartNumber(produto.valorTotal) }}
                                </div>
                                <div
                                    class="page-table-desc-column percent-width"
                                >
                                    <div
                                        class="products-analysis-mobile-header"
                                    >
                                        %
                                    </div>
                                    {{ produto.percent }}%
                                </div>
                                <div
                                    class="page-table-desc-column percent-accumulated-width"
                                >
                                    <div
                                        class="products-analysis-mobile-header"
                                    >
                                        % Acumulado
                                    </div>
                                    {{ produto.percentAcumulate }}%
                                </div>
                            </div>
                            <div
                                class="page-table-row"
                                v-show="produto.loading"
                                :key="'prod-loader-' + produto.pro_id"
                            >
                                <cc-loader style="margin: 10px auto" />
                            </div>
                            <div
                                v-show="
                                    !produto.loading &&
                                    produto.fornecedores &&
                                    produto.expanded
                                "
                                :key="'prod-detail-' + produto.pro_id"
                                class="page-table-row"
                                style="justify-content: center"
                            >
                                <div class="product-analysis-sub-table">
                                    <div
                                        class="page-table-header"
                                        style="padding: 15px 0px"
                                    >
                                        <div
                                            class="page-table-header-text marca-width"
                                        >
                                            Marca
                                        </div>
                                        <div
                                            class="page-table-header-text fornecedor-width"
                                        >
                                            Fornecedor
                                        </div>
                                        <div
                                            class="page-table-header-text data-compra-width"
                                        >
                                            Data Compra
                                        </div>
                                        <div
                                            class="page-table-header-text num-cotacao-width"
                                        >
                                            Número Cotação
                                        </div>
                                        <div
                                            class="page-table-header-text num-requisitantes-width"
                                        >
                                            N. Requisitantes
                                        </div>
                                        <div
                                            class="page-table-header-text num-requisitantes-width"
                                        >
                                            Embalagem
                                        </div>
                                        <div
                                            class="page-table-header-text quantidade-width"
                                        >
                                            Quantidade
                                        </div>
                                        <div
                                            class="page-table-header-text valor-unit-width"
                                        >
                                            Valor Unit
                                        </div>
                                        <div
                                            class="page-table-header-text valor-total-width"
                                        >
                                            Valor Total
                                        </div>
                                    </div>
                                    <div
                                        v-for="(
                                            info, index
                                        ) in produto.fornecedores"
                                        :key="`inf-line-${index}`"
                                    >
                                        <div class="page-table-row-container">
                                            <div
                                                class="page-table-row"
                                                :class="
                                                    getSpecialBackground(index)
                                                "
                                            >
                                                <div
                                                    class="page-table-desc-column marca-width"
                                                >
                                                    <div
                                                        class="products-analysis-mobile-header"
                                                    >
                                                        Marca
                                                    </div>
                                                    {{ info.marca }}
                                                </div>
                                                <div
                                                    class="page-table-desc-column fornecedor-width"
                                                >
                                                    <div
                                                        class="products-analysis-mobile-header"
                                                    >
                                                        Fornecedor
                                                    </div>
                                                    {{ info.nomeFornecedor }}
                                                </div>
                                                <div
                                                    class="page-table-desc-column data-compra-width"
                                                >
                                                    <div
                                                        class="products-analysis-mobile-header"
                                                    >
                                                        Data Compra
                                                    </div>
                                                    {{
                                                        info.data_geracao
                                                            | moment(
                                                                "DD/MM/YYYY"
                                                            )
                                                    }}
                                                </div>
                                                <div
                                                    class="page-table-desc-column num-cotacao-width"
                                                >
                                                    <div
                                                        class="products-analysis-mobile-header"
                                                    >
                                                        Número Cotação
                                                    </div>
                                                    {{ info.id_cotacao }}
                                                </div>
                                                <div
                                                    class="page-table-desc-column num-requisitantes-width"
                                                >
                                                    <div
                                                        class="products-analysis-mobile-header"
                                                    >
                                                        N. Requisitantes
                                                    </div>
                                                    {{ info.requisitantes }}
                                                </div>
                                                <div
                                                    class="page-table-desc-column num-requisitantes-width"
                                                >
                                                    {{ info.pro_generico == 1 ? info.pro_unidade_compra : info.embalagem_2 }}
                                                </div>
                                                <div
                                                    class="page-table-desc-column quantidade-width"
                                                >
                                                    <div
                                                        class="products-analysis-mobile-header"
                                                    >
                                                        Quantidade
                                                    </div>
                                                    {{ info.quantidade }}
                                                </div>
                                                <div
                                                    class="page-table-desc-column valor-unit-width"
                                                >
                                                    <div
                                                        class="products-analysis-mobile-header"
                                                    >
                                                        Valor Unit
                                                    </div>
                                                    {{
                                                        produto.cli_novoPedido ==
                                                            1 &&
                                                        produto.cli_tipoPedido ==
                                                            1
                                                            ? formartNumber(
                                                                  info.valorTotal /
                                                                      info.quantidade
                                                              )
                                                            : formartNumber(
                                                                  info.valorTotal /
                                                                      info.quantidade
                                                              )
                                                    }}
                                                </div>
                                                <div
                                                    class="page-table-desc-column valor-total-width"
                                                >
                                                    <div
                                                        class="products-analysis-mobile-header"
                                                    >
                                                        Valor Total
                                                    </div>
                                                    {{
                                                        formartNumber(
                                                            info.valorTotal
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <table v-show="!produto.loading && produto.fornecedores && produto.expanded"
                       class="additional-info table"
                       :key="'prod-detail-' + produto.pro_id">
                  <thead>
                    <tr>
                      <th>Marca</th>
                        <th>Fornecedor</th>
                        <th>Data Compra</th>
                        <th>Número Cotação</th>
                        <th>N. Requisitantes</th>
                        <th>Quantidade</th>
                        <th>Valor Unit</th>
                        <th>Valor Total</th>
                      </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(info, index) in produto.fornecedores" :key="`inf-line-${index}`">
                      <td>{{ info.marca }}</td>
                      <td>{{ info.nomeFornecedor }}</td>
                      <td>{{ info.data_geracao | moment("DD/MM/YYYY") }}</td>
                      <td>{{ info.id_cotacao }}</td>
                      <td>{{ info.requisitantes }}</td>
                      <td>{{ info.quantidade }}</td>
                      <td>{{ produto.cli_novoPedido == 1 && produto.cli_tipoPedido == 1 ? formartNumber(info.valorTotal / info.quantidade ) : formartNumber(info.valorTotal / (info.quantidade)) }}</td>
                      <td>{{ formartNumber(info.valorTotal) }}</td>
                    </tr>
                  </tbody>
                </table> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mobile">
                <ProductMobileRow v-for="(produto, idx) in produtos" :key="'pro-tab-' + produto.pro_id" :produto="produto"/>
            </div>

            <!-- tabela apenas impressão -->



            <a id="download-excel-product" style="display: none"></a>
            <div class=" p-4 shadow mb-4 only-print">
                <table
                    class=" mb-3"
                    v-if="produtos.length > 0 && !loading"
                >
                    <thead>
                        <tr>
                            <th>EAN</th>
                            <th>
                                <cc-label-sort
                                    :showicon="ordernar == 'p.pro_descricao'"
                                    :direction="tipoOrder == 'asc' ? 1 : 0"
                                    :content="'Descrição'"
                                    @click="
                                        setFiltroOrder(
                                            'p.pro_descricao',
                                            'desc'
                                        )
                                    "
                                />
                            </th>
                            <th>Categoria</th>
                            <th class="text-center">
                                <cc-label-sort
                                    :showicon="ordernar == 'quantidade'"
                                    :direction="tipoOrder == 'asc' ? 1 : 0"
                                    :content="'Qtd.'"
                                    @click="
                                        setFiltroOrder('quantidade', 'desc')
                                    "
                                />
                            </th>
                            <th class="text-center">Qtd. Cot</th>
                            <th class="text-center">Med. Cmp.</th>
                            <th class="text-center">Valor Unit.</th>
                            <th class="text-center">
                                <cc-label-sort
                                    :showicon="ordernar == 'valorTotal'"
                                    :direction="tipoOrder == 'asc' ? 1 : 0"
                                    :content="'Valor Total'"
                                    @click="
                                        setFiltroOrder('valorTotal', 'desc')
                                    "
                                />
                            </th>
                            <th class="text-center">%</th>
                            <th class="text-center">% Acumulado</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="produto in produtos">
                            <tr
                                :key="'pro-tab-' + produto.pro_id"
                                @click="clickTable(produto)"
                                class="tab-prod-row"
                            >
                                <td>{{ produto.pro_ean }}</td>
                                <td>{{ produto.pro_descricao }}</td>
                                <td>{{ produto.cat_descricao }}</td>
                                <td class="text-center">
                                    {{ produto.quantidade }}
                                </td>
                                <td>{{ produto.qtdCots }}</td>
                                <td>{{ (produto.quantidade / produto.qtdCots).toFixed(2) }}</td>
                                <td class="text-center">
                                    {{
                                        produto.cli_novoPedido == 1 &&
                                        produto.cli_tipoPedido == 1
                                            ? formartNumber(
                                                  produto.valorTotal /
                                                      produto.quantidade
                                              )
                                            : formartNumber(
                                                  produto.valorTotal /
                                                      produto.quantidade
                                              )
                                    }}
                                </td>
                                <td class="text-center">
                                    {{ formartNumber(produto.valorTotal) }}
                                </td>
                                <td class="text-center">
                                    {{ produto.percent }}%
                                </td>
                                <td class="text-center">
                                    {{ produto.percentAcumulate }}%
                                </td>
                            </tr>
                            <tr
                                v-show="produto.loading"
                                :key="'prod-loader-' + produto.pro_id"
                            >
                                <td colspan="8"><cc-loader /></td>
                            </tr>
                            <tr
                                v-show="
                                    !produto.loading &&
                                    produto.fornecedores &&
                                    produto.expanded
                                "
                                class="additional-info"
                                :key="'prod-detail-' + produto.pro_id"
                            >
                                <td colspan="8" class="p-0">
                                    <div class="m-4 p-4 shadow">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Marca</th>
                                                    <th>Fornecedor</th>
                                                    <th>Data Compra</th>
                                                    <th>Número Cotação</th>
                                                    <th>N. Requisitantes</th>
                                                    <th>Quantidade</th>
                                                    <th>Valor Unit</th>
                                                    <th>Valor Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    v-for="(
                                                        info, index
                                                    ) in produto.fornecedores"
                                                    :key="`inf-line-${index}`"
                                                >
                                                    <td>{{ info.marca }}</td>
                                                    <td>
                                                        {{
                                                            info.nomeFornecedor
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            info.data_geracao
                                                                | moment(
                                                                    "DD/MM/YYYY"
                                                                )
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{ info.id_cotacao }}
                                                    </td>
                                                    <td>
                                                        {{ info.requisitantes }}
                                                    </td>
                                                    <td>
                                                        {{ info.quantidade }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            produto.cli_novoPedido ==
                                                                1 &&
                                                            produto.cli_tipoPedido ==
                                                                1
                                                                ? formartNumber(
                                                                      info.valorTotal /
                                                                          info.quantidade
                                                                  )
                                                                : formartNumber(
                                                                      info.valorTotal /
                                                                          info.quantidade
                                                                  )
                                                        }}
                                                    </td>
                                                    <td>
                                                        {{
                                                            formartNumber(
                                                                info.valorTotal
                                                            )
                                                        }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <cc-loader-full  v-if="loading" />
        <tr
            v-show="total_items > items_by_page_value && !showTable"
            class="seemore-button-container not-print"
        >
            <td class="text-center" colspan="6">
                <Paginacao
                    classes="orange justify-end"
                    :items_by_page_value="items_by_page_value"
                    :total="total_items"
                    :page="page"
                    v-on:change="updatePage($event)"
                >
                </Paginacao>
            </td>
        </tr>
    </div>
</template>

<script>
import { filtroData } from "@/mixins/filtroData";
import { Money } from "v-money";
import numeral from "numeral";
//import PaginacaoOld from "@/components/shared/Paginacao";
import Paginacao from "@/components/cliente/base-components/Pagination";
import { mascaraCNPJ } from "@/mixins/mascaraCNPJ";
import * as CONSTANTS from "@/constants/constants";
import Button from "@/components/ui/buttons/Standard-Button.vue";
import StandardInput from "@/components/ui/inputs/StandardInput.vue";
import { mapState } from "vuex";
import ProductMobileRow from "./ProductMobileRow.vue";

export default {
    components: {
        Paginacao,
        Button,
        StandardInput,
        ProductMobileRow
    },
    mixins: [filtroData, mascaraCNPJ],
    data() {
        return {
            showTable: false,
            page: 1,
            items_by_page_value: 30,
            total_items: 1000,
            image: "/images/search.png",
            image2: "/images/calendar.png",
            paginaAtual: 1,
            totalPaginas: 1,
            date_picker_options: {
                format: "DD/MM/YYYY",
                locale: "pt-br",
                useCurrent: false,
            },
            itensPorPagina: 0,
            totalPedido: 0,
            totalItens: 0,
            imprimindo: 0,
            showSelectBody: false,
            produtos: [],
            filtroProduto: "",
            filtroRequisitante: [],
            filtroFornecedor: null,
            filtroCategoria: null,
            classOrder: "fa-sort-asc",
            ordernar: "valorTotal",
            tipoOrder: "desc",
            lastPercent: [],
            loading: false,
            fDateIni: moment().format("DD/MM/YYYY"),
            fDateFim: moment().format("DD/MM/YYYY"),
        };
    },
    computed: {
        ...mapState(["whitelabel"]),
        getNomeCategoria() {
            let lojaSelect = this.$store.state.categoriasFiltro.filter(
                (item) => item.cat_id == this.filtroCategoria
            );
            if (lojaSelect.length == 0) {
                return "";
            }
            return lojaSelect[0].cat_descricao;
        },
        getNomeRequisitante() {
            // let requisitanteSelect = this.$store.state.requisitantesFiltro.filter(
            //     item => this.filtroRequisitante && item.loj_id == this.filtroRequisitante.loj_id
            // );
            // if (requisitanteSelect.length == 0) {
            //     return "";
            // }
            // return requisitanteSelect[0].loj_descricao;
            if (this.filtroRequisitante.length > 0) {
                let resp = this.filtroRequisitante[0].loj_descricao;
                for (
                    let index = 1;
                    index < this.filtroRequisitante.length;
                    index++
                ) {
                    resp += ", " + this.filtroRequisitante[index].loj_descricao;
                }
                return resp;
            }
        },
        getNomeFornecedor() {
            let fornecedorSelect = this.$store.state.fornecedorFiltro.filter(
                (item) => item.for_id == this.filtroFornecedor
            );
            if (fornecedorSelect.length == 0) {
                return "";
            }
            return fornecedorSelect[0].for_nome;
        },
    },
    watch: {
        filtroProduto: function () {
            if (!this.loading) {
                this.loading = true;
                setTimeout(() => {
                    this.getProdutos();
                }, 1500);
            }
        },
        filtroFornecedor: function () {
            if (!this.loading) {
                this.loading = true;
                setTimeout(() => {
                    this.getProdutos();
                }, 1500);
            }
        },
        filtroCategoria: function () {
            if (!this.loading) {
                this.loading = true;
                setTimeout(() => {
                    this.getProdutos();
                }, 1500);
            }
        },
        /* filtroData: function() {
        if (!this.loading) {
          this.loading = true
          setTimeout(() => {
            this.getProdutos();
          }, 1500);
        }
      } */
    },
    methods: {
        openProductSelect() {
            this.showSelectBody = true;
        },
        closeProductSelect() {
            this.showSelectBody = false;
        },
        toogleCheck(product) {
            product.isCheck = !product.isCheck;
            if (product.isCheck) this.setRequisitanteValue(product.value);
            else this.removeRequisitanteValue(product.value);
            this.$forceUpdate();
        },
        openTable() {
            this.showTable = !this.showTable;
        },
        setValue(value) {
            this.filtroProduto = value;
        },
        setRequisitanteValue(value) {
            this.filtroRequisitante.push(value);
        },
        removeRequisitanteValue(value) {
            this.filtroRequisitante = this.filtroRequisitante.filter(
                (element) => element.loj_id != value.loj_id
            );
        },
        setFornecedorValue(value) {
            this.filtroFornecedor = value;
        },
        setCategoriaValue(value) {
            this.filtroCategoria = value;
        },
        /* setDataValue(value){
          this.filtroData = value
        }, */
        updatePage(new_page) {
            this.getProdutos(new_page);
        },
        getSpecialBackground(idx) {
            if (idx % 2 != 0) return "page-table-line-special";
        },
        reset_filter() {
            this.filtroRequisitante = [];
            this.filtroData = null;
            this.filtroProduto = null;
            this.filtroCategoria = null;
            this.filtroFornecedor = null;
        },
        open_datepicker(idx) {
            document.getElementsByClassName("vdatetime-input")[idx].click();
        },
        print() {
            this.$swal
                .fire({
                    title: "Deseja Imprimir somente a tela?",
                    text: "Como deseja que seu relatório seja impresso.",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Somente Atual!",
                    cancelButtonText: "Relatório Completo!",
                })
                .then((result) => {
                    if (result.value) {
                        setTimeout(() => {
                            window.print();
                            this.imprimindo = 0;
                        }, 1000);
                    } else {
                        this.imprimir = true;
                        this.getProdutos(1, false, true);
                    }
                });
        },
        setFiltroOrder(order, tipo) {
            if (
                order == this.ordernar &&
                tipo == this.tipoOrder &&
                tipo == "asc"
            ) {
                this.tipoOrder = "desc";
            } else if (
                order == this.ordernar &&
                tipo == this.tipoOrder &&
                tipo == "desc"
            ) {
                this.tipoOrder = "asc";
            } else {
                this.tipoOrder = tipo;
            }
            this.classOrder = "fa-sort-" + this.tipoOrder;

            this.ordernar = order;

            if (this.produtos.length > 0) {
                this.getProdutos(this.paginaAtual, false);
            }
        },
        formartNumber(n) {
            if (n) {
                return numeral(n.toString().replace(".", ",")).format("0,0.00");
            } else {
                return n;
            }
        },
        getPercentual(produto) {
            return (
                parseFloat(
                    (produto.valorTotal * 100) / this.totalPedido
                ).toFixed(2) + "%"
            );
        },
        getPercentualNumber(produto) {
            return parseFloat((produto.valorTotal * 100) / this.totalPedido);
        },
        onDateRangeChangedIni: function (picker) {
            this.fDateIni = picker.startDate.format("DD/MM/YYYY");
            this.$store.commit(
                "changeDateIniRelatorioCliente",
                picker.startDate.format("YYYY-MM-DD")
            );
        },
        clickTable(product) {
            console.log('yes');

            this.getProdutosInternos(product);
        },
        getProdutosInternos(product) {
            product.loading = true;
            if (!product.fornecedores) {
                console.log('forn');

                var requestUrl =
                    `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?produtoFilter=` +
                    product.pro_id +
                    "&groupBy=pe.id_pedido&imprimir=true&ordenar=data_geracao&tipoorder=desc";
                const dateIni = moment(
                    this.$store.state.dateIniRelatorio
                ).format("YYYY-MM-DD");
                const dateFim = moment(
                    this.$store.state.dateFimRelatorio
                ).format("YYYY-MM-DD");
                requestUrl += "&dateIni=" + dateIni + "&dateFim=" + dateFim;
                if (this.filtroProduto) {
                    requestUrl += "&nomeproduto=" + this.filtroProduto;
                }
                if (this.filtroRequisitante.length > 0) {
                    requestUrl +=
                        "&requisitante=" + this.filtroRequisitante[0].loj_id;
                    for (
                        let index = 1;
                        index < this.filtroRequisitante.length;
                        index++
                    ) {
                        requestUrl +=
                            "," + this.filtroRequisitante[index].loj_id;
                    }
                }
                if (this.filtroFornecedor) {
                    requestUrl += "&fornecedor=" + this.filtroFornecedor.for_id;
                }
                if (this.filtroCategoria) {
                    requestUrl += "&categoria=" + this.filtroCategoria.cat_id;
                }
                axios
                    .get(requestUrl, {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    })
                    .then(({ data }) => {
                        console.log('final');

                        product.fornecedores = data.produtos;
                        product.expanded = !product.expanded;
                        this.$forceUpdate();
                        product.loading = false;
                    })
                    .catch((error) => {
                        if (error.response.status === 401) {
                            if (
                                error.response.data.mensagem ==
                                CONSTANTS.TOKEN_EXPIRADO
                            ) {
                                // location.href = CONSTANTS.ROUTE_LOGIN;
                                window.location.assign(CONSTANTS.ROUTE_LOGIN)
                            }
                        } else if (error.response.status === 500) {
                            if (error.response.data.mensagem) {
                                this.mensagemErro =
                                    error.response.data.mensagem;
                            } else {
                                this.mensagemErro =
                                    "Não foi possível abrir os dados gerenciais";
                            }
                        }
                        product.loading = false;
                    });
            } else {
                product.expanded = !product.expanded;
                product.loading = false;
            }
            this.$forceUpdate();
        },
        onDateRangeChangedFim: function (picker) {
            this.fDateFim = picker.startDate.format("DD/MM/YYYY");
            this.$store.commit(
                "changeDateFimRelatorioCliente",
                picker.startDate.format("YYYY-MM-DD")
            );
        },
        getProdutos(pagina, variable, imprimir = false) {
            this.loading = true;
            var requestUrl =
                `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?groupBy=IFNULL(main.pro_id, p.pro_id)&ordenar=` +
                this.ordernar +
                "&tipoorder=" +
                this.tipoOrder +
                "&page=" +
                pagina;
            const dateIni = moment(this.$store.state.dateIniRelatorio).format(
                "YYYY-MM-DD"
            );
            const dateFim = moment(this.$store.state.dateFimRelatorio).format(
                "YYYY-MM-DD"
            );
            requestUrl += "&dateIni=" + dateIni + "&dateFim=" + dateFim;
            // requestUrl += "&dateIni=2019-10-30d&dateFim=2020-01-30";

            if (this.filtroProduto != "") {
                requestUrl += "&nomeproduto=" + this.filtroProduto;
            }
            if (this.filtroRequisitante.length > 0) {
                requestUrl +=
                    "&requisitante=" + this.filtroRequisitante[0].loj_id;
                for (
                    let index = 1;
                    index < this.filtroRequisitante.length;
                    index++
                ) {
                    requestUrl += "," + this.filtroRequisitante[index].loj_id;
                }
            }
            if (this.filtroFornecedor)
                requestUrl += "&fornecedor=" + this.filtroFornecedor.for_id;
            if (this.filtroCategoria)
                requestUrl += "&categoria=" + this.filtroCategoria.cat_id;
            if (imprimir) {
                requestUrl += "&imprimir=" + true;
                this.imprimindo = 1;
                // var telaImpressao = window.open("about:blank");
            }

            axios
                .get(requestUrl, {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                })
                .then(({ data }) => {
                    this.loading = false;

                    if (imprimir) {
                        requestUrl += "&imprimir=" + true;
                        // var telaImpressao = window.open("about:blank");
                        this.totalPedido = data.totalProdutos.valorTotal
                            ? data.totalProdutos.valorTotal
                            : 0;
                        let lastPercent = 0;
                        this.produtos = data.produtos.map((item, index) => {
                            let newItem = item;
                            newItem.percent =
                                this.getPercentualNumber(item).toFixed(2);
                            newItem.percentAcumulate = parseFloat(
                                parseFloat(newItem.percent) +
                                    parseFloat(lastPercent)
                            ).toFixed(2);
                            lastPercent = newItem.percentAcumulate;
                            return newItem;
                        });

                        setTimeout(() => {
                            window.print();
                            this.imprimindo = 0;
                        }, 100);
                    } else {
                        this.totalPedido = data.totalProdutos.valorTotal
                            ? data.totalProdutos.valorTotal
                            : 0;
                        this.paginaAtual = data.produtos.current_page;
                        this.totalPaginas = data.produtos.last_page;
                        this.total_items = data.produtos.total;
                        this.itensPorPagina = data.produtos.per_page;
                        this.totalItens = data.produtos.total;
                        let lastPercent = !this.lastPercent[pagina]
                            ? 0
                            : this.lastPercent[pagina];
                        this.produtos = data.produtos.data;
                        this.produtos = data.produtos.data.map(
                            (item, index) => {
                                let newItem = item;
                                newItem.percent =
                                    this.getPercentualNumber(item).toFixed(2);
                                newItem.percentAcumulate = parseFloat(
                                    parseFloat(newItem.percent) +
                                        parseFloat(lastPercent)
                                ).toFixed(2);
                                lastPercent = newItem.percentAcumulate;
                                this.lastPercent[pagina + 1] =
                                    newItem.percentAcumulate;
                                return newItem;
                            }
                        );
                    }

                    // setando produtos
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.status === 401) {
                        if (
                            error.response.data.mensagem ==
                            CONSTANTS.TOKEN_EXPIRADO
                        ) {
                            // location.href = CONSTANTS.ROUTE_LOGIN;
                            window.location.assign(CONSTANTS.ROUTE_LOGIN)
                        }
                    } else if (error.response.status === 500) {
                        if (error.response.data.mensagem) {
                            this.mensagemErro = error.response.data.mensagem;
                        } else {
                            this.mensagemErro =
                                "Não foi possível abrir os dados gerenciais";
                        }
                    }
                });
        },
        getProdutosExcel() {
            this.loading = true;
            this.loading = true;
            var requestUrl =
                `${CONSTANTS.API_URL}/getprodutosrelatorioclienteexcel?groupBy=p.pro_id&ordenar=` +
                this.ordernar +
                "&tipoorder=" +
                this.tipoOrder;
            const dateIni = moment(this.$store.state.dateIniRelatorio).format(
                "YYYY-MM-DD"
            );
            const dateFim = moment(this.$store.state.dateFimRelatorio).format(
                "YYYY-MM-DD"
            );
            requestUrl += "&dateIni=" + dateIni + "&dateFim=" + dateFim;
            // requestUrl += "&dateIni=2019-10-30d&dateFim=2020-01-30";

            if (this.filtroProduto != "") {
                requestUrl += "&nomeproduto=" + this.filtroProduto;
            }
            if (this.filtroRequisitante.length > 0) {
                requestUrl +=
                    "&requisitante=" + this.filtroRequisitante[0].loj_id;
                for (
                    let index = 1;
                    index < this.filtroRequisitante.length;
                    index++
                ) {
                    requestUrl += "," + this.filtroRequisitante[index].loj_id;
                }
            }
            if (this.filtroFornecedor)
                requestUrl += "&fornecedor=" + this.filtroFornecedor.for_id;
            if (this.filtroCategoria)
                requestUrl += "&categoria=" + this.filtroCategoria.cat_id;
            requestUrl += "&template=produto";
            axios
                .get(requestUrl, {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                    responseType: "blob",
                })
                .then((response) => {
                    var file = response.data;
                    var fileURL = URL.createObjectURL(file);
                    var el = document.getElementById("download-excel-product");
                    el.download = "export.xls";
                    el.href = fileURL;
                    el.click();
                    setTimeout(function () {
                        window.URL.revokeObjectURL(fileURL);
                    }, 100);
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    if (error.response.status === 401) {
                        if (
                            error.response.data.mensagem ==
                            CONSTANTS.TOKEN_EXPIRADO
                        ) {
                            // location.href = CONSTANTS.ROUTE_LOGIN;
                            window.location.assign(CONSTANTS.ROUTE_LOGIN)
                        }
                    } else if (error.response.status === 500) {
                        if (error.response.data.mensagem) {
                            this.mensagemErro = error.response.data.mensagem;
                        } else {
                            this.mensagemErro =
                                "Não foi possível abrir os dados gerenciais";
                        }
                    }
                });
        },
    },
    mounted() {},
};
</script>
<style lang="scss" scoped>
.product-analysis {
}
.product-analysis__container {
}
.product-analysis__add-buttons {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 1.5em;
}
.product-analysis__add-button-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-right: 1em;
}
.product-analysis__total-value {
    padding: 0.55em;
    border-radius: 8px;
    background-color: var(--primary-color);
}
.product-analysis__total-value-text {
    color: #fff;
    font-weight: 600;
}
.page-table-row {
    display: flex;
    font-weight: 300;
    color: #605f5f;
    cursor: pointer;
    background-color: white;
}
.input-title {
    font-weight: 400;
    font-size: 2em;
    letter-spacing: 0.15px;
    color: #898989;
}
.input-container {
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
}
.date-container {
    align-items: center;
    border-radius: 8px !important;
    display: flex;
}
.date-icon {
    background-color: var(--primary-color);
    color: #fff;
    cursor: pointer;
    padding: 8px 10px;
    border-radius: 0px 8px 8px 0px;
    position: relative;
    left: -6px;
}
.input-value {
    border: none;
    flex: 1;
    font-weight: 400;
    font-size: 1.1em;
    letter-spacing: 0.15px;
    color: #b3b3b3 !important;
}
.input-value:focus {
    border: none;
    outline: none;
}
.input-icon {
    color: #fff;
    background-color: var(--primary-color);
    margin-left: 5px;
    padding: 0.3em;
    border-radius: 8px;
    cursor: pointer;
}
.input-select-value {
    border: none;
    font-weight: 400;
    font-size: 1.1em;
    letter-spacing: 0.15px;
    color: #b3b3b3;
    flex: 1;
    height: 24px;
}
.input-containear {
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 1.65vh 0.6vw;
    margin-top: 5px;
    display: flex;
    font-size: 14px;
    letter-spacing: 0.15px;
    color: #6e6e6e;
}
.Select-containear-product {
    width: 22%;
}
.product-analysis-sub-table {
    width: 100%;
}
.select-body {
    position: absolute;
    z-index: 4;
    background-color: white;
    overflow-y: scroll;
    max-height: 300px;
    width: 20%;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
}
.select-body-line {
    display: flex;
    align-items: center;
    padding: 10px 2px;
    cursor: pointer;
    flex: 1;
}
.select-body-line:hover {
    background-color: rgba(255, 247, 247, 0.75);
}
.select-body-line-text {
    font-weight: 300;
    font-size: 1.27em;
    color: #505050;
    margin-left: 5px;
}
.checkbox-icon {
    color: var(--primary-color);
}
.input-select-value:focus {
    border: none;
    outline: none;
    color: #b3b3b3;
}
.product-analysis__page-filter-container {
    display: flex;
    align-items: center;
    margin-top: 2em;
    justify-content: space-between;
}
.product-analysis__page-filter-wrapper {
    width: 20%;
    margin-right: 1em;
}
.product-analysis__page-filter-wrapper:last-child {
}
.page-table-container {
    margin-top: 20px;
}
.page-table-header {
    background: #fffefc;
    border: 0.5px solid #e5e5e5;
    font-weight: 400;
    font-size: 1.3em;
    color: #605f5f;
    display: flex;
    padding: 1vh 0;
}
.mobile{
    display: none;
}
.page-table-store-width {
    width: 15%;
    padding-left: 10px;
}
.page-table-row-container {
    margin-top: 10px;
}

.page-table-desc-column {
    padding: 0.6em 0.8em;
    border-right: 1px solid #dde5e9;
}
.page-table-unit-column {
    padding-left: 10px;
    border-right: 1px solid #dde5e9;
}
.page-table-price-column {
    padding-left: 10px;
    border-right: 1px solid #dde5e9;
}
.page-table-date-column {
    padding-left: 10px;
    border-right: 1px solid #dde5e9;
}
.page-table-actions-colum {
    padding-left: 10px;
    border-right: 1px solid #dde5e9;
}
.table {
    margin: 10px !important;
    -webkit-box-shadow: 6px 6px 8px 0.5px rgba(0, 0, 0, 0.16);
    box-shadow: 6px 6px 8px 0.5px rgba(0, 0, 0, 0.16);
}
.page-table-header-text {
    font-weight: 400;
    color: #605f5f;
    padding-left: 10px;
}
.seemore-button-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 70px;
}
.code-width {
    width: 10%;
    display: flex;
    align-items: center;
}
.desc-width {
    width: 35%;
}
.category-width {
    width: 10%;
}
.qty-width {
    width: 8%;
}
.unit-val-width {
    width: 8%;
}
.total-val-width {
    width: 12%;
}
.percent-width {
    width: 8%;
}
.products-analysis-mobile-header {
    display: none;
}
.percent-accumulated-width {
    width: 12%;
}
.page-table-line-special {
    background: #f7f7f7;
}
.page-selected-filters-container {
    display: flex;
    margin-top: 14px;
}
.half-filter-width {
    width: 30%;
    display: flex;
}
.page-selected-filters {
    background: #fcf9f7;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.page-selected-filters-text {
    font-weight: 300;
    font-size: 1.1em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: var(--primary-color);
    margin-right: 5px;
}
.page-selected-filters-icon {
    color: var(--primary-color);
    cursor: pointer;
}
.page-buttons-container {
    /* display: flex;
  align-items: center;
  justify-content: space-between; */
    margin: 2em 0em;
}
.page-buttons-wrapper {
    width: 20vw;
    margin-right: 1em;
}
.page-buttons-wrapper__title {
    font-weight: 400;
    font-size: 1.8em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #898989;
}
.page-buttons-wrapper__select {
}
.page-buttons-container__left-container {
    display: flex;
}
.page-buttons-container__right-container {
    display: flex;
    align-items: center;
    margin-top: 0.7em;
}
.action-button {
    width: 15vw;
}
.page-filters-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.page-filters__left-container {
}
.page-filters__left-wrapper {
}
.search-input {
    width: 23.3vw;
}
.page-filters__left-title-wrapper {
}
.page-filters__left-title {
    font-weight: 400;
    font-size: 1.8em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #898989;
}
.page-filters__left-select-wrapper {
    display: flex;
}
.page-filters__right-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.page-filters__right-wrapper {
    margin-right: 10px;
}
.page-filters__right-title-wrapper {
    display: flex;
    align-items: center;
}
.page-filters__right-title {
    font-weight: 400;
    font-size: 1.8em;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #898989;
}
.page-filters__right-select-wrapper {
    display: flex;
    align-items: center;
}
.page-filters__total-value {
    display: flex;
    padding: 1.8em;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    border: 0.5px solid #e5e5e5;
}
.page-filters__total-value__img {
    margin-right: 10px;
}
.page-filters__total-value-text {
    color: #202020;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.05em;
}
.action-buttons-width {
    margin-left: 10px;
}
.search-icon {
    margin: 0em 0.2em;
    color: #fff;
    background-color: var(--primary-color);
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}
.preorder-seller-title-button-excel {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5e5e5;
    border-radius: 9.5px;
    font-weight: 600;
    padding: 0 1vw;
    height: 7.5vh;
    font-size: 1.3em;
    cursor: pointer;
    margin-left: 10px;
}
.preorder-seller-title-button-excel-icon {
    margin-right: 1vw;
}
.reports-page__title-button-total {
    background: rgba(23, 162, 184, 0.1);
    border: 1px solid #e5e5e5;
    border-radius: 9.5px;
    font-weight: 600;
    font-size: 1.3em;
    color: #5281ff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1vw;
    height: 7.5vh;
}
.page-filters__datetime-container {
    display: flex;
    align-items: center;
}
.page-filters__actions-container {
    display: flex;
    align-items: center;
    padding-top: 20px;
}
.marca-width {
    width: 12.5%;
}
.fornecedor-width {
    width: 12.5%;
}
.data-compra-width {
    width: 12.5%;
}
.num-cotacao-width {
    width: 12.5%;
}
.num-requisitantes-width {
    width: 11%;
}
.quantidade-width {
    width: 12.5%;
}
.valor-unit-width {
    width: 12.5%;
}
.valor-total-width {
    width: 12.5%;
}
.product-tab-colapsed-icon {
    font-size: 1.5em;
    margin-right: 1vw;
}
.product-tab-expanded-icon {
    color: var(--primary-color);
    font-size: 1.5em;
    rotate: 90deg;
    margin-right: 1vw;
}

@media only screen and (max-width: 800px) {
    .button-container {
        margin: 10px 0px;
    }
    .page-buttons-wrapper {
        margin: 5px 0px;
    }
    .select-body{
        width: 100vw;
    }
    .mobile{
        display: block;
    }
    .product-analysis__add-buttons {
        flex-direction: column;
    }
    .product-analysis__add-button-container {
        margin: 5px 0px;
    }
    .product-analysis__page-filter-container {
        flex-direction: column;
    }
    .product-analysis__page-filter-wrapper {
        margin: 10px 0px;
        width: 100%;
    }
    .input-container {
        margin-right: 5px;
    }
    .date-container {
        width: 102vw;
    }
    .page-filters-container {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }
    .page-buttons-container {
        flex-direction: column;
        align-items: flex-start;
    }
    .page-buttons-container__left-container {
        flex-direction: column;
    }
    .page-buttons-wrapper {
        width: 100vw;
    }
    .page-buttons-container__right-container {
        flex-direction: column;
    }
    .page-filters__right-container {
        flex-direction: column;
    }
    .end-date {
    }
    .search-input {
        width: 100vw;
    }
    .page-filters__total-value {
        width: 100vw;
    }
    .button-container {
        margin: 5px 0px;
    }
    .page-buttons-wrapper {
        margin: 5px 0px;
    }
    .page-filters__left-select-wrapper {
        margin: 5px 0px;
    }
    .page-filters__right-wrapper {
        margin: 5px 0px;
    }
    .standard-input-width {
        width: 45% !important;
    }
    .action-buttons-width {
        width: 55vw !important;
        margin: 5px;
    }
    .page-filters__datetime-container {
        gap: 5vw;
    }
    .page-filters__actions-container {
        flex-wrap: wrap;
        justify-content: center;
    }
    .input-containear {
        width: 100%;
    }
    .Select-containear-product {
        width: 45%;
    }
    .page-table-row {
        flex-direction: column;
        gap: 1vh;
        margin-bottom: 3vh;
    }
    .page-table-desc-column {
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex: unset;
        text-align: end;
    }
    .products-analysis-mobile-header {
        display: flex;
        font-weight: 700;
        font-size: 1.1em;
    }
    .product-analysis-sub-table {
        width: 100%;
    }
    .page-table-container {
        display: none;
    }
}
@media only screen and (max-width: 500px) {
}
@import "@/sass/commons/_variables";

.totalDiv {
    width: fit-content;
    color: white;
    height: 100%;
    background-color: $secondary-color;
    text-align: right;
    padding: 0.7rem 1.5rem !important;
}
.tab-prod-row {
    cursor: pointer;
}

/deep/ {
    .cc-loader img {
        height: 4rem;
        width: 5rem;
        margin: auto;
    }
}
</style>

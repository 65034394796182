<template>
    <div class="modal-mask">
        <div class="modal-content-container">
            <div class="edit-product-modal-title">
                Editar produto: {{ product.pro_descricao }}
                <span v-on:click="close" class="material-icons modal-close-icon">cancel</span>
            </div>
            <div class="edit-product-modal-body">
                <div style="display: flex; justify-content: space-between; margin-top: 3%;">
                    <div class="input-size">
                       <p class="edit-product-text" >Custo:</p>
                        <money type="text" v-model="cost" v-bind="money" class="edit-product-input"/> 
                    </div>
                    <div class="input-size0big">
                        <p class="edit-product-text">Prç de venda:</p>
                        <money type="text" v-model="sell" v-bind="money" class="edit-product-input"/>
                    </div>
                    <div class="input-size">
                        <p class="edit-product-text">Valor Unt:</p>
                        <input type="text" :value="balance | twoDecimal" disabled class="edit-product-input"/>
                    </div>
                    <div class="input-size0-big">
                        <p class="edit-product-text">Embalagem:</p>
                        <input type="text" :value="product.pro_generico == 1 ? product.pro_unidade_compra : product.pro_embalagem" disabled class="edit-product-input"/>
                    </div>
                    <div class="input-size">
                        <p class="edit-product-text">Margem:</p>
                        <input type="text" :value="(margem | formatNumber) + '%'" disabled class="edit-product-input info-orange"/>
                    </div>

                </div>
                
                
                <p class="edit-product-text">Observação:</p>
                <input type="text" v-model="obs"  class="edit-product-input">
                <div style="display: flex; justify-content: space-between;">
                    <div style="width: 45%;">
                        <p class="edit-product-text">Fornecedor:</p>
                        <cc-select-v2 class="new-product-select-width" v-model="selectedProvider" :searchable="true"   :tracker="'value'" :text="'text'"  :options="providers"></cc-select-v2>
                    </div>
                    <div style="width: 45%;">
                        <p class="edit-product-text">Vendedor:</p>
                        <cc-select-v2 class="new-product-select-width" v-model="selectedBuyer"    :tracker="'value'" :text="'text'"  :options="buyers"></cc-select-v2>
                    </div>
                    
                </div>
                
                <StandardButton iconName="save" text="Salvar" :action="save" class="edit-product-save-button"/>
            </div>
        </div>
    </div>
</template>

<script>
import StandardButton from '@/components/ui/buttons/Standard-Button.vue'
import SellersService from "@/services/v3/commons/sellers.service";
import ProviderService from "@/services/v3/provider.service";
import currencyPrecision from '../../mixins/currencyPrecision';

    export default {
        name: 'EditProductModal',
        components: {
            StandardButton,
        },
        mixins: [currencyPrecision],
        data() {
            return {
                cost: this.product.custo_atual,
                sell: this.product.preco_venda,
                obs: this.product.observacao,
                selectedProvider: { text: this.product.pro_for_nome, value: true},
                selectedBuyer: { text: this.product.pro_vend_nome, value: {id: this.product.pro_vend_id}},
                buyers: [],
                providers: [],
                money: {
                    decimal: ',',
                    prefix: 'R$ ',
                    precision: 2,
                },
                percent: {
                    suffix: '%',
                    precision: 0,
                },
                sellers_svc: new SellersService(),
                psvc: new ProviderService(),
            }
        },
        props: {
            product: {
                type: Object
            },
            close: {
                type: Function
            },
            update: {
                type: Function
            }
        },
        computed: {
            margem() {
                return ((this.sell / this.balance || 0) - 1) * 100
            },
            balance(){
                return (this.cost || 0) / (this.product.pro_generico == 1 ? this.product.quantidade : this.product.pro_embalagem.split(' ')[1])
            }
        },
        watch:{
            selectedProvider() {
                this.getBuyers()
            }
        },
        mounted () {
            this.load_providers();
        },
        methods: {
            save() {
                this.product.custo_atual = this.cost
                this.product.preco_venda = this.sell
                this.product.observacao = this.obs
                this.product.changed = true
                this.update(this.product)
            },
            load_providers() {
                this.psvc.load_providers().then((response) => {
                    this.providers.push({text: 'Nenhum', value: null})
                    response.data.forEach(p => {
                    this.providers.push( { text: p.name, value: p })
                    })
                });
            },
            getBuyers(){
                this.selectedBuyer = undefined
                if (!this.selectedProvider.value){
                    this.buyers = [{text:'nenhum', value: {}}]
                    return
                }
                this.sellers_svc.load_sellers({ provider_id: this.selectedProvider.value.id }).then(resp => {
                    this.buyers = resp.data.map(element => {
                    return {text: element.name, value: element}
                    })
                })
            },
        },
    }
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}
.modal-content-container {
  background-color: white;
  min-width: 80vw;
  border-radius: 12px;
}
.edit-product-modal-title{
    background-color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    font-size: 2.25em;
    border-radius: 10px 10px 0 0;
    color: white;
    font-weight: 500;
    letter-spacing: -0.02em;
    padding: 1vh 1vw;
    align-items: center;
}
.input-size{
    width: 16%;
}
.input-size-big{
    width: 18%;
}
.modal-close-icon{
    font-size: 1.5em;
    cursor: pointer;
}
.edit-product-modal-body{
    min-height: 70vh;
    padding: 3vh 1vw;

}
.edit-product-input{
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0.5vw;
    margin-top: 5px;
    display: flex;
    align-items: center;
    width: 100%;
    height: 6vh;
    font-weight: 400;
    font-size: 17px;
    letter-spacing: 0.15px;
    color: #6e6e6e;
}
.edit-product-input:focus{
    outline: none;
}
.edit-product-text{
    font-weight: 400;
    font-size: 2em;
    letter-spacing: 0.15px;
    color: #6e6e6e;
}
.edit-product-save-button{
    width: 30%;
    margin: 0 auto;
    margin-top: 5%;
}
.info-value{
    color: white;
    padding: 0 5%;
    border-radius: 5px;
    width: max-content;
}
.info-green{
    background-color: rgb(122, 210, 122);
}
.info-orange{
    background-color: lightsalmon;
    color: white;
}
.info-value-container{
    text-align: center;
    font-weight: 600;
    font-size: 2em;
    color: #605F5F;
    width: 15%;
}
</style>
import http from "./Http";
import Service from "./Service";

/**
 * @type QuotationService
 */
export default class QuotationService extends Service {

  getLojasQuantidade(produtoId, quotation_id, vendedorId, loj_id) {
    return http.get("getvendedorescotacaopedido", {
	  headers: this.getHeaders(),
	  timeout: 600000,
      params: {
        produto: produtoId,
        numerocotacao: quotation_id,
		vendedor: vendedorId,
		loja: loj_id
      }
    });
  }

	sendQuantidade(data) {
		return http.post("inserirquantidadeproduto", data, {
		headers: this.getHeaders(),
		timeout: 600000
		});
	}

	get_request_providers(request_id) {
		return http.get("getconcorrentescotacao", {
			params: {
				numerocotacao: request_id
			},
			headers: this.getHeaders()
		});
	}

	getAllStatus() {
		return http.get("getstatuscotacao", { headers: this.getHeaders() });
	}

	persisteQuotationStatuses(map) {
		localStorage.setItem("q_statuses", JSON.stringify(map))
	}

	getQuotationStatus(id) {
		return JSON.parse(localStorage.getItem("q_statuses")) ? JSON.parse(localStorage.getItem("q_statuses"))[id] : null;
	}

	getClientId() {
		return JSON.parse(localStorage.getItem("cli_id"));
	}

	list(
		page = null,
		dataIni = null,
		cliente = null,
		status = null,
		numerocotacao = null,
		itemsByPage = 10,
		buyer = null
	) {
		return http.get("getcotacoes", {
		headers: this.getHeaders(),
        timeout: 600000,
		params: {
			page: page,
			itens_por_pagina: itemsByPage,
			dataIni: dataIni,
			cliente: cliente,
			status: status,
			numerocotacao: numerocotacao,
			usu_id: buyer ? buyer.usu_id : null
		}
		});
	}

	listResupply(status=false) {
		return http.get("getcotacoes", {
		headers: this.getHeaders(),
		params: {
			tipo: "ressuprimento",
			status: status ? '13' : '8,15'
		}
		});
	}

	getSellers(page, name = "", status = "", provider_filter = "") {
		return http.get("meusvendedores", {
            headers: this.getHeaders(),
            timeout: 200000,
            params: {
                page: page,
                name: name,
                status: status,
                for_id: provider_filter
            }
		});
	}

	getSellersProviders() {
		return http.get("meusvendedores/fornecedores", {
		headers: this.getHeaders()
		});
	}

	enableSeller(seller_id) {
		return http.put(`meusvendedores/activate/${seller_id}`, {}, {
			headers: this.getHeaders()
		});
	}

	disableSeller(seller_id) {
		return http.put(`meusvendedores/deactivate/${seller_id}`, {}, {
			headers: this.getHeaders()
		});
	}

	sendResupply(params) {
		return http.post("inserircotacaoressuprimento", params, {
		headers: this.getHeaders(),
		timeout: 600000
		});
	}

	consolidateListMobile(params){
		return http.post("consolidarlistamobile", params, {
			headers: this.getHeaders(),
			timeout: 600000
		});
	}

	inserirCotacao(data) {
		return http.post("inserircotacao", data, {
		headers: this.getHeaders(),
		timeout: 600000
		});
	}

    send_delivery(data) {
        return http.post("entregaoutrascotacoes", data, {
            headers: this.getHeaders(),
            timeout: 600000
        });
    }

	list_payment_types() {
		return http.get("getipopagamento", {
		headers: this.getHeaders()
		});
	}

	inserircotacaocentral(data) {
		return http.post("inserircotacaocentral", data, {
		headers: this.getHeaders()
		});
	}

	prorogateQuotation(data) {
		return http.post("prorrogationquotation", data, {
		headers: this.getHeaders()
		});
	}

	insert_client_product(data){
		return http.post("inserirProdutoCliente", data, {
			headers: this.getHeaders(),
		});
	}

	deletequotationresuply(quote) {
		return http.post(
			"deletequotationresuply",
			{ quote },
			{
				headers: this.getHeaders()
			}
		);
	}

    mergequotationresuply(quote) {
		return http.post(
			"mergequotationresuply",
			{ quote },
			{
				headers: this.getHeaders()
			}
		);
	}

	getProductsListMobile(list) {
		return http.get("getprodutoslista",{
			headers: this.getHeaders(),
			params: {
				listas:list
			}
		});
	}

	deleteListMobile(list) {
		return http.post(
			"deletarlista",
			{ 'listas' : list },
			{
				headers: this.getHeaders()
			}
		);
	}

	getProductsResupply(quote) {
		return http.get("getprodutosressuprimento?numeroCotacao=" + quote, {
		    headers: this.getHeaders(),
            timeout: 600000
		});
	}


	getQuotationProducts(quote, store_id) {
		return http.get("getprodutoscotacao?cotacao=" + quote + '&store_id=' + store_id, {
		headers: this.getHeaders()
		});
	}

	get_default_products(data, token) {

		return http.get("getprodutopadraocliente", {
		cancelToken: token,
		headers: this.getHeaders(),
		timeout: 600000,
		params: data
		});
	}

	getById(id) {
		return http.get(
		"show/" + id,
		{},
		{
			headers: this.getHeaders()
		}
		);
	}

	freeze(quotationId) {
		return http.post(
		"congelarcotacao",
		{
			numeroCotacao: quotationId
		},
		{
			headers: this.getHeaders()
		}
		);
	}

	unfreeze(quotationId) {
		return http.post(
		"descongelarcotacao",
		{
			numeroCotacao: quotationId
		},
		{
			headers: this.getHeaders()
		}
		);
	}

	getProducts(
		quotationId,
		description,
		value,
		limit,
		page,
		providerId,
		filterCategory,
		print,
		store
	) {
		return http.get("getprodutoscomparativo", {
		headers: this.getHeaders(),
		timeout: 600000,
		params: {
			numerocotacao: quotationId,
			fornecedor: providerId,
			filtro: value,
			categoriaFiltro: filterCategory,
			descricao: description,
			registrosPorPagina: limit,
			pagina: page,
			imprimir: print,
			loja: store
		}
		});
	}

	getStoresClient() {
		return http.get("getrequisitantescliente", {
			timeout: 1200000,
			headers: this.getHeaders()
		});
	}

	getCategoriasCotacao(quotationId) {
		return http.get("getcategoriascotacao", {
		headers: this.getHeaders(),
		timeout: 1200000,
		params: {
			numeroCotacao: quotationId
		}
		});
	}

		getCategorias() {
			return http.get("listarcategoriascliente", {
				headers: this.getHeaders(),
				timeout: 1200000,
				params: {
				cliente_id: this.getClientId()
				}
			});
		}

		load_provider_default_categories() {
			return http.get("getcategoriasadministrador", {
				timeout: 600000,
				headers: this.getHeaders()
			});
		}


	getLojasNaoDigitados(quotationId) {
		return http.get("getlojasnaodigitados", {
		headers: this.getHeaders(),
		timeout: 1200000,
		params: {
			numerocotacao: quotationId
		}
		});
	}

	getVendedoresPedido(quotationId) {
		return http.get("getvendedorespedido", {
		headers: this.getHeaders(),
		timeout: 1200000,
		params: {
			numerocotacao: quotationId
		}
		});
	}

	getProdutosClientePorPagina(data, token) {
		return http.get("getprodutosclienteporpage", {
			cancelToken: token,
			headers: this.getHeaders(),
			timeout: 1200000,
			params: data
		});
	}

    getProdutosClientePorPaginaPdf(data, token) {
		return http.get("getprodutosclienteporpagepdf", {
			cancelToken: token,
			headers: this.getHeaders(),
            responseType: 'arraybuffer',
			timeout: 1200000,
			params: data
		});
	}
	getProductsPrint(data){
		return http.get('createProdutospdf',{
			headers: this.getHeaders(),
			params: data
		})
	}



	get_temp_products(data) {
		return http.get("listarprodutoscotacaotemp", {
		headers: this.getHeaders(),
		timeout: 600000,
		params: data
		});
	}

	generate_pdf(numeroCotacao, numeroSolicitacao) {
		return http.get("pdfporloja", {
		headers: this.getHeaders(),
		timeout: 600000,
		responseType: 'arraybuffer',
		params: {
			numeroCotacao: numeroCotacao,
			numeroSolicitacao: numeroSolicitacao
		}
		});
	}

	load_not_typed_per_store(numeroCotacao, codLoja) {
		return http.get("getlojasnaodigitados", {
		headers: this.getHeaders(),
		timeout: 600000,
		params: {
			numerocotacao: numeroCotacao,
            codloja: codLoja
		}
		});
	}

	getLojasFornecedores(quotation_id, lojaid) {
		return http.get("getlojasfornecedores", {
		headers: this.getHeaders(),
		timeout: 1200000,
		params: {
			numerocotacao: quotation_id,
			loja: lojaid
		}
		});
	}

	getProdutosDigitacaoComparativo(quotation_id, idvendedor, loja, idProduto, div, orderId) {
		return http.get("getprodutosdigitacaocomparativo", {
		headers: this.getHeaders(),
		timeout: 600000,
		params: {
			numerocotacao: quotation_id,
			idvendedor: idvendedor,
			loja: loja,
			idproduto: idProduto,
			divergencia: div,
			pedido: orderId
		}
		});
	}

	getProdutosDigitacaoComparativoUltimaCompra(quotation_id, idvendedor, loja, idProduto) {
		return http.get("getprodutosdigitacaoultimacompra", {
		headers: this.getHeaders(),
		timeout: 600000,
		params: {
			numerocotacao: quotation_id,
			idvendedor: idvendedor,
			loja: loja,
			idproduto: idProduto
		}
		});
	}

	getDigitacaesProdutoComparativo(quotation_id, loja, idProduto) {
		return http.get("getdigitacoesprodutocomparativo", {
		headers: this.getHeaders(),
		params: {
			numerocotacao: quotation_id,
			loja: loja,
			idproduto: idProduto
		}
		});
	}

	order(data) {
		return http.post("realizarpedido", data, {
		headers: this.getHeaders(),
		timeout: 600000
		});
	}

	update_quantity(data) {
		return http.post("inserirquantidadeproduto", data, {
		headers: this.getHeaders()
		});
	}

	save_quotation_temp_products(data) {
		return http.post("inserircotacaotemporaria", data, {
		headers: this.getHeaders()
		});
	}

	getRequestTempProducts(data) {
		return http.get("listarprodutosrequisicaotemporaria", {
			headers: this.getHeaders(),
			timeout: 600000,
			params: data
		});
	}

	update_quotation_typing(offer) {
		return http.put("quotation/typing", offer, {
			headers: this.getHeaders(),
			timeout: 60000
		});
	}

	clear_temp_products() {
		return http.delete("limparcotacaotemporaria", {
		headers: this.getHeaders()
		});
	}

	cancel_order(data) {
		return http.post("cancelarpedido", data, {
		headers: this.getHeaders(),
		timeout: 60000
		});
	}

	toggleQuotationWinner(quoatationId, storeId, productId, sellerId, isWinner) {
		return http.post(
		"alterarvencedorcotacao",
		{
			loja: storeId,
			numerocotacao: quoatationId,
			produto: productId,
			vencedor: isWinner,
			vendedor: sellerId
		},
		{ headers: this.getHeaders() }
		);
	}

	getListMobile(loja=null, ids=null, status=null) {
		return http.get("getlistamobile", {
			headers: this.getHeaders(),
			timeout: 600000,
			params: {
				loja:loja,
				list_ids: ids ? ids.join(",") : null,
				status:status
				// list_ids: '14211,13522'
			}
		});
	}

    discontinue_product(subreq_id, product_id, discontinue) {
		return http.put("discontinueproduct", {
            subreq_id,
            product_id,
            discontinue
        }, {
			headers: this.getHeaders(),
			timeout: 600000
		});
	}

	updateQuotationProductsAmount(id, items) {
		return http.put(
			`/cotacao/${id}/products/quantidade`,
			{ 'items' : items },
			{
				headers: this.getHeaders()
			}
		);
	}

    updateQuotationProductAmount(id, product) {
		return http.put(
			`/cotacao/${id}/products/${product.pro_id}/quantidade`,
			{ 
				quantidade : product.quantidade,
				estoque: product.estoque,
				'qtd_padrao': product.qtd_padrao,
				obs: product.observacao,
				custo: product.custo_atual,
				venda: product.preco_venda
		 	},
			{
				headers: this.getHeaders()
			}
		);
	}

	orderNotTyped(quoatationId, filter, store) {
		return http.post("inserircotacaonaodigitados",
		{
            idcotacao:quoatationId,
            filter: filter,
            codloja: store
        },
		{
			headers: this.getHeaders(),
			timeout: 60000
		});
	}

	copy_prices(id, seller_id) {
		return http.get(`/copiarprecos/`, {
			headers: this.getHeaders(),
			timeout: 60000,
			params: { cotacao: id, vendedor: seller_id }
		});
	}

    remove_product_ressuply(cotacao, product) {
		return http.post(`/removeproductresupply`,
		{
			cotacao: cotacao,
			produto: product
		},
		{
			headers: this.getHeaders()
		});
	}

    removeWinners(cotacao) {
		return http.post(`/removewinners`,
		{ numeroCotacao: cotacao },
		{
			headers: this.getHeaders()
		});
	}

    get_info_client(id  ) {
		return http.get(`/v2/clients/quotantion/${id}`, {
			headers: this.getHeaders(),
			timeout: 60000
		});
	}
	getVariationInfo(id){
		return http.get(`relatorio/variacao/total?numerocotacao=${id}`, {
			headers: this.getHeaders(),
			timeout: 60000
		});
	}

    replicate_quotantion(cotacao) {
		return http.post(`/replicaterequest`,
		{
			cotacao: cotacao
		},
		{
			headers: this.getHeaders(),
            timeout: 60000,
		});
	}
	getBuyers (id) {
		return http.get('usuario?cliente_id='+id,{
			headers: this.getHeaders(),
			timeout: 60000
		})
	}
	recreateQuotation(payload){
		return http.post("prorrogationquotation/new", {
			"numeroCotacao": payload.quotationId,
			"hora_final": payload.timeEnd,
			"data_final": payload.dateEnd,
			"fechamento": payload.close ? payload.close : false
		}, {headers: this.getHeaders()})
	}
	excludeQuotation(id){
		return http.post("closequotation/"+id, {}, {
			headers: this.getHeaders()
		})
	}
}

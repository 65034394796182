<template>
    <div>
        <div class="page-table-header">
                            <div class="page-table-expand-width">Id</div>
                            <div class="page-table-store-width">Nome do vendedor</div>
                            <div class="page-table-email-width">Email/Telefone</div>
                            <div class="page-table-rate-width order-icon" @click="toggleCotOrder()">
                                Cotações
                                <span v-if="!cotOrder" class="material-icons-outlined">unfold_more</span>
                                <span v-else-if="cotOrder == 'asc'"  class="material-icons-outlined">expand_more</span>
                                <span v-else  class="material-icons-outlined">expand_less</span>
                            </div>
                            <div class="page-table-rate-width order-icon" @click="togglePartOrder()">
                                Participação
                                <span v-if="!partOrder" class="material-icons-outlined">unfold_more</span>
                                <span v-else-if="partOrder == 'asc'"  class="material-icons-outlined">expand_more</span>
                                <span v-else  class="material-icons-outlined">expand_less</span>
                            </div>
                            <div class="page-table-action-width">Ação</div>
                        </div>
                        <div v-for="(test, idx) in sellers" :key="idx" class="page-table-line expanded-line"  >
                            <div class="page-table-expand-width">#{{ test.id }}</div>

                            <div class="page-table-store-width page-table-separator page-table-store">
                                <div class="seller-table-mobile-align">{{test.name}} <br> ({{ test.provider.name }})</div>
                                <div class="mobile ">
                                    <span title="Deletar fornecedor" class="material-icons page-table-delete-icon" v-on:click="remove_seller(test)">delete</span>
                                </div>
                            </div>
                            <!-- desktop -->
                            <div class="desktop page-table-email-width page-table-separator">
                                {{test.email}} <br>
                                <a v-if="test.phone" :href="'https://api.whatsapp.com/send?phone='+formatNumber(test.phone)" target="_blank" class="page-table-contact-text">{{test.phone | VMask('(##) ####-#####')}}</a>
                                <div v-else class="page-table-contact-text">(xx) xxxxx-xxxxx</div>
                            </div>
                            <!-- desktop -->
                            <div class="desktop page-table-rate-width">
                                {{ test.cotacao_count }}
                            </div>
                            <!-- desktop -->
                            <div class="desktop page-table-rate-width">
                                <span v-if="test.clients.length > 0 && test.clients[0].pivot.participation > 0" :class="getPercent(test.clients[0].pivot.participation)">{{ (test.clients[0].pivot.participation * 100).toFixed(2) + ' %' }}</span>
                                <span v-else>--</span>
                            </div>
                            <!-- desktop -->
                            <div class="desktop page-table-action-width page-table-separator">
                                <span title="Deletar fornecedor" class="material-icons page-table-delete-icon" v-on:click="remove_seller(test, test.provider)">delete</span>
                            </div>

                            <!-- mobile -->
                            <div class="mobile mobile-row-table">
                                <div >
                                    <div class="page-mobile-header">Telefone</div>
                                    <a v-if="test.phone" :href="'https://api.whatsapp.com/send?phone='+formatNumber(test.phone)" target="_blank" class="page-table-contact-text">{{test.phone | VMask('(##) ####-#####')}}</a>
                                    <div v-else class="page-table-contact-text">(xx) xxxxx-xxxxx</div>
                                </div>
                                <div >
                                    <div class="page-mobile-header align-end">Email</div>
                                    {{test.email}}
                                </div>
                                
                            </div>

                            <!-- mobile -->
                            <div class="mobile mobile-row-table">
                                <div>
                                    <div class="page-mobile-header">Participação</div>
                                    <span v-if="test.clients.length > 0 && test.clients[0].pivot.participation > 0" :class="getPercent(test.clients[0].pivot.participation)">{{ (test.clients[0].pivot.participation * 100).toFixed(2) + ' %' }}</span>
                                    <span v-else>--</span>
                                </div>
                                <div >
                                    <div class="page-mobile-header">Nota</div>
                                    {{rating(test)}}
                                </div>
                            </div>
                        </div>
    </div>
</template>

<script>
import ProviderService from "@/services/ProviderService";
import { loaderMixin } from '@/mixins/sweet-loader.mixin';

    export default {
        name: 'SellerTable',
        props: {
            sellers: Array,
            loadPage: Function,
            partOrder: {
                type: String,
                required: false
            },
            setPartOrder: Function,
            cotOrder: {
                type: String,
                required: false
            },
            setCotOrder: Function
        },
        mixins: [ loaderMixin ],
        data() {
            return {
                prov_svc: new ProviderService(),
            }
        },
        methods: {
            formatNumber(number){
                if (!number) return null
                number = number.replace('+','').replace('(','').replace(')','').replace('-','')
                if (number[0] + '' + number[1] != '55' ) number = '55' + number
                return number
            },
            togglePartOrder(){
                if (this.partOrder == "desc") this.setPartOrder('asc')
                else this.setPartOrder('desc')
            },
            toggleCotOrder(){
                if (this.cotOrder == "desc") this.setCotOrder('asc')
                else this.setCotOrder('desc')
            },
            getPercent(value){
                if(value <= 0.3) return 'table-not-typed'
                else if(value >= 0.7) return 'table-typed'
                else return 'percent-orange'
            },
            rating(seller) {
                return seller.review ? seller.review+'.0' : '--'
            },
            syncSuplier (test) {
                const type = test.type == 'REQUEST' ? 'DIRECT_REQUEST' : 'REQUEST'
                this.prov_svc.syncProvider(test.id,type).then(() => {
                    test.type = type
                    this.$forceUpdate()
                })
            },
            remove_seller(seller) {
                this.confirm_action({
                    message: `Confirma remoção do vendedor ${seller.name}?` ,
                    callback: () => this.remove(seller)
                })
            },
            remove(seller) {
                return this.prov_svc.remove_seller(seller.id).then(() => {
                    this.loadPage()
                })
            },
        }    
    }
</script>

<style lang="scss" scoped>
.page-table-header{
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 600;
    font-size: 1.19em;
    color: #605F5F;
    display: flex;
    padding: 1vh 0;
    margin-top: 2vh;
}
.page-table-expand-width{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.page-table-store-width{
    flex: 1;
}
.page-table-cnpj-width{
    width: 10%;
    padding-left: 10px;
}
.page-table-email-width{
    width: 25%;
}
.page-table-rate-width{
    width: 10%;
}
.page-table-action-width{
    width: 7%;
}
.page-table-line{
    display: flex;
    font-weight: 300;
    font-size: 1em;
    color: #605F5F;
    align-items: center;
    padding: 1.5vh 0;
    border-bottom: 1px solid lightgray;
}
.page-table-store{
    display: flex;
    align-items: center;
}
.mobile{
    display: none;
}
.page-table-contact-text{
    color: green;
}
.page-table-delete-icon{
    cursor: pointer;
    color: lightcoral;
}
.page-table-update-icon{
    cursor: pointer;
    color: rgb(77, 77, 250);
}
.table-typed{
    background-color: lightgreen;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    border-radius: 10px;
    padding: 0 1vw;
    width: 80%;
    display: block;
    text-align: center;
}
.percent-orange{
    background-color: lightsalmon;
    color: white;
    border-radius: 10px;
    padding: 0 1vw;
    text-align: center;
    font-weight: bold;
    width: 80%;
    display: block;
    text-transform: uppercase;
}
.order-icon{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.table-not-typed{
    background-color: lightcoral;
    color: white;
    border-radius: 10px;
    padding: 0 1vw;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    width: 80%;
    display: block;
  }
@media only screen and (max-width: 800px) {
    .page-table-header{display: none;}
    .mobile{
        display: block;
    }
    .align-end{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .alert-container{
        width: 32%;
        padding: 1vh 0.5vw;
        font-size: 18px;
    }
    .desktop{
        display: none !important;
    }
    
    .page-table-line{
        flex-direction: column;
        gap: 1vh;
        font-size: 1.2em;
    }
    .page-table-separator{
        display: flex;
        justify-content: space-between;
        width: 100%;
        flex: unset;
    }
    .page-mobile-header{
        display: flex;
        font-size: 1em;
        font-weight: 700;
    }
    .page-table-actions-width{
        flex: unset;
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
    }
    .page-table-city-width{
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .seller-participation-icon{
        width: 40%;
        font-size: 1em;
        padding: 0;
    }
    .seller-table-mobile-align{
        text-align: end;
        font-weight: bold;
        font-size: 1.1em;
    }
    .page-table-phone-width{padding-left: 10px;}
    .page-table-status-width{padding-left: 10px;}
    .page-table-separator{
        border-right: none;
        padding-right: 2vw;
    }
    .mobile-row-table{
        display: flex !important;
        justify-content: space-between;
        width: 100%;
    }
    .page-table-expand-width{
        width: 100%;
        justify-content: flex-start;
    }
}
</style>
<template>
    <div class="table-container">
        <table>
           <tr>
                <th style="width: 10%;">Id</th>
                <th style="width: 50%;">Nome</th>
                <th>Quantidade</th>
                <th style="display: flex; align-items: center; cursor: pointer;" @click="toggleOrder()">
                    Total
                    <span v-if="!totalOrder" class="material-icons-outlined">unfold_more</span>
                    <span v-else-if="totalOrder == 'asc'" class="material-icons-outlined">expand_more</span>
                    <span v-else class="material-icons-outlined">expand_less</span>
                </th>
            </tr>
            <tr class="product-text" v-for="(p, idx) in showProducts" :key="idx">
                <td>{{ p.id_produto }}</td>
                <td>{{ p.descricao_principal }} <br> ({{ p.marca || '--' }})</td>
                <td>{{ p.qtd }}</td>
                <td :class="{'red-alert': p.total_lucro<0.01}">R${{p.total_lucro.toFixed(2)}}</td>
            </tr> 
        </table>
        
    </div>
</template>

<script>
export default {
    name: 'ProductsTable',
    props: {
        products: {
            type: Array
        },
        categories:{
            type: Array
        }
    },
    data() {
        return {
            totalOrder: undefined,
            showProducts: this.products
        }
    },
    methods: {
        getCategory(p) {
            const resp = this.categories.filter(c => c.id == p.cat_id)
            return resp.length > 0 ? resp[0].cat_descricao : '--'
        },
        toggleOrder(){
            if (this.totalOrder == 'asc') this.totalOrder = 'desc'
            else this.totalOrder = 'asc'
        }
    },
    watch: {
        totalOrder(newValue) {
            if (newValue == 'asc') this.showProducts = this.products.sort((a,b)=> a.total_lucro-b.total_lucro)
            else this.showProducts = this.products.sort((a,b)=> b.total_lucro-a.total_lucro)
        }
    },
}
</script>

<style lang="scss" scoped>
.table-container {
    height: 60vh;
    overflow: auto;
}

.product-text {
    font-weight: 400;
    font-size: 1.2em;
    color: #606060;
    border-bottom: 1px solid lightgray;
    padding-bottom: 0.5vh;
}
th{
    font-size: 1rem;
}
.red-alert{
    color: lightcoral;
}
@media only screen and (max-width: 700px) {
    .table-container{
        height: 75vh;
    }
}
</style>
import { saveAs } from 'file-saver';

export default class ExcelDownload {
    // payload = {
    //     name: 'carros'   
    //     header: ['cor', 'ano', 'marca', 'modelo'],
    //     body: [
    //         ['azul', '2017', 'honda', 'civic'],
    //         ['laranja', '2005', 'ford', 'fiesta'],
    //         ['preto', '2022', 'fiat', 'fastback'],
    //     ]
    // }
    // excel:
    // cor    | ano   | marca   | modelo 
    // azul   | 2017  | honda   | civic 
    // laranja| 2005  | ford    | fiesta 
    // preto  | 2022  | fiat    | fastback

    constructor(payload) {
        const XLSX = require('sheetjs-style');
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "SheetJS",
            Subject: "Valores",
            Author: payload.author ? payload.author : "Club da Cotação",
            CreatedDate: new Date()
        };
        wb.SheetNames.push(payload.tableName.trim());
        const now = new Date()
        var ws_data = []
        if (!payload.skip) ws_data.push([payload.author ? payload.author : "Club da Cotação", payload.store, payload.userName, now.toLocaleDateString(), payload.cot])
        ws_data.push(payload.header) 
        ws_data.push(...payload.body)  
        var ws = XLSX.utils.aoa_to_sheet(ws_data);
        ws["!cols"] = [ { wch: 10 },{ wch: 30 }, { wch: 20 }, { wch: 15 }, { wch: 15 },{ wch: 15 },{ wch: 15 },{ wch: 15 },{ wch: 32 },{ wch: 7 }, ]
        const header = {
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }
            },
            fill:{
                fgColor: { rgb: payload.headerColor ? payload.headerColor.replace('#','') : "FF7110" }
            }
        }
        const title = {
            font: {
                bold: true,
            }
        }
        ws["A1"].s = payload.skip ? header : title
        ws["B1"].s = payload.skip ? header : title
        ws["C1"].s = payload.skip ? header : title
        ws["D1"].s = payload.skip ? header : title
        if(ws["E1"]) ws["E1"].s = payload.skip ? header : title
        if(ws["F1"]) ws["F1"].s = payload.skip ? header : title

        ws["A2"].s = !payload.skip && header
        if(ws["B2"]) ws["B2"].s = !payload.skip &&header
        if(ws["C2"]) ws["C2"].s = !payload.skip &&header
        if(ws["D2"]) ws["D2"].s = !payload.skip &&header
        if(ws["E2"]) ws["E2"].s = !payload.skip &&header
        if(ws["F2"]) ws["F2"].s = !payload.skip &&header
        if(ws["G2"]) ws["G2"].s = !payload.skip &&header
        if(ws["H2"]) ws["H2"].s = !payload.skip &&header
        if(ws["I2"]) ws["I2"].s = !payload.skip &&header
        wb.Sheets[payload.tableName.trim()] = ws;
        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }
        saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), payload.tableName.trim() + '.xlsx');
    }
}
<template>
  <div class="modal-mask">
    <div class="modal-content-container">
      <div class="requested-products__main-container">
        <div class="requested-products__header-container not-print">
          <span class="requested-products__header-title">
            {{ modal.title }}
          </span>
          <span @click="closeDialog" class="material-icons requested-products__closebtn-icon">
            cancel
          </span>
        </div>
        <div v-if="!showRequest" class="requested-products__actions-container not-print">
          <div v-if="!hideExtraInfo" class="requested-products__filter-input-wrapper">
            <cc-select-v2 class="requested-products__filter-input"
                          :tracker="'loj_id'"
                          :text="'loj_descricao'"
                          v-model="filters.store"
                          :options="stores">
            </cc-select-v2>
          </div>
          
          <div v-if="!hideExtraInfo" class="requested-products__actions-wrapper full-rigth" @click="showRequest=true">
            <span class="material-icons requested-products__actions-icon">
              article
            </span>
            <span class="requested-products__actions-title">
              Ver Requisições
            </span>
          </div>
          <div class="requested-products__actions-wrapper "
               :content="'Imprimir'"
               :classes="'print-btn'"
               @click="downloadExcel()">
            <span class="material-icons requested-products__actions-icon">
              upload_file
            </span>
            <span class="requested-products__actions-title">
              Excel
            </span>
          </div>
          <div class="requested-products__actions-wrapper"
               :content="'Imprimir'"
               :classes="'print-btn'"
               @click="download_pdf()">
            <span class="material-icons requested-products__actions-icon">
              print
            </span>
            <span class="requested-products__actions-title">
              Imprimir
            </span>
          </div>
        </div>
        <div v-else class="requested-products__actions-container not-print">
          <div class="requested-products__actions-wrapper full-rigth" @click="showRequest=false">
            <span class="material-icons requested-products__actions-icon">
              inventory_2
            </span>
            <span class="requested-products__actions-title">
              Ver Produtos
            </span>
          </div>
        </div>
        <div id="modalBodyPrint" v-if="!showRequest" class="requested-products__table-container">
          <cc-loader style="margin-top: 25vh;" v-show="loading" />
          <no-data-available v-if="products.length == 0 && !loading" :text="'Nenhum produto encontrado'" />
          <table v-show="!loading && products.length > 0" class="requested-products__table-wrapper">
            <thead class="requested-products__table-thead desktop">
              <tr class="requested-products__table-thead-tr">
                <th class="requested-products__table-th">Código</th>
                <th class="requested-products__table-th">Nome</th>
                <th class="requested-products__table-th">Embalagem</th>
                <th class="requested-products__table-th">Qtd.</th>
                <th class="requested-products__table-th">Estoque</th>
              </tr>
            </thead>

            <!-- desktop -->
            <tbody class="requested-products__table-tbody desktop">
              <tr class="requested-products__table-tbody-tr"
                  v-for="(item, index) in products"
                  :key="'prod-' + index"
                  :class="getSpecialBackground(index)">
                <td class="requested-products__table-td">{{ item.pro_ean }}</td>
                <td class="requested-products__table-td">{{ item.pro_descricao }}</td>
                <td class="requested-products__table-td">{{ item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem }}</td>
                <td class="requested-products__table-td">{{ item.soma_quantidades || item.quantidade }}</td>
                <td class="requested-products__table-td">{{ item.estoque || 0 }}</td>
              </tr>
            </tbody>

            <!-- mobile -->
            <div class="mobile" v-for="(item, index) in products"
                  :key="'prod-' + index"
                  :class="getSpecialBackground(index)">
              <div class="mobile-table-row">
                <p class="mobile-table-title">{{ item.pro_descricao }}</p>
                <p class="mobile-table-title">{{ item.pro_ean }}</p>
              </div>
              <div class="mobile-table-row">
                <div style="width: 20%;">
                  <p class="mobile-table-title">Embalagem</p>
                  {{ item.pro_generico == 1 ? item.pro_unidade_compra : item.pro_embalagem }}
                </div>
                <div>
                  <p class="mobile-table-title">Qtd.</p>
                  {{ item.soma_quantidades || item.quantidade }}
                </div>
                <div style="display: flex; flex-direction: column; align-items: flex-end;">
                  <p class="mobile-table-title">Estoque</p>
                  {{ item.estoque || 0 }}
                </div>
              </div>

            </div>

          </table>
          <a id="download-pdf" style="display:none;"></a>
        </div>
        <RequestedProductsTable v-else :quotation="quotation"/>
      </div>
    </div>
  </div>
</template>

<script>

import RequestsService from "@/services/v2/requests.service";
import ErrorHandlerService from "@/services/ErrorHandlerService";
import { perm_mixin } from '@/mixins/permission.mixin'
import { mapState } from "vuex";
import ExcelDownload from '@/services/ExcelDownload.js'
import RequestedProductsTable from './RequestedProductsTable.vue'

export default {
    mixins: [ perm_mixin ],
    props: ["quotation", "closeDialog", 'hideExtraInfo'],
    components:{
      RequestedProductsTable,
    },
    data() {
        return {
            products: [],
            stores: [],
            loading: true,
            service: new RequestsService(),
            showRequest: false,
            filters: {
                store: null
            },
            modal: {
                title: `Produtos da cotação #${this.quotation}`,
                unpermitted_message: "Aceite o termo de consentimento para continuar.",
                permitted: true,
                style: {
                    width: "80%"
                }
            }
        };
    },
    watch: {
        'filters.store' : function() {
            this.getProducts()
        }
    },
    computed: {
        ...mapState([ "whitelabel"]),
    },
    methods: {
			close() {
				this.$emit('close');
			},
        group_name() {
            return localStorage.getItem("grupo")
        },
        downloadExcel(){
          setTimeout(() => {
              const payload = {
                  tableName: 'Produtos',
                  userName: localStorage.getItem('user_name'),
                  author: this.whitelabel && this.whitelabel.data.providers.text_window,
                  headerColor: this.whitelabel && this.whitelabel.data.colors.primary,
                  store: this.filters.store ? this.filters.store.loj_nomeFantasia : localStorage.getItem('grupo'),
                  cot: '#'+this.quotation,
                  header: ['Código','Nome',  'Embalagem', 'Qtd.', 'Estoque'],
                  body: this.products.map(value => {
                      return [
                          value.pro_ean,
                          value.pro_descricao,
                          value.pro_generico == 1 ? value.pro_unidade_compra : value.pro_embalagem,
                          value.soma_quantidades || value.quantidade ,
                          value.estoque || 0,
                      ]
                  })
              }
              new ExcelDownload(payload)
          }, 100);
        },
        getProducts() {
            this.loading = true
            this.service
            .request_products(this.quotation, { store_id: this.filters.store ? this.filters.store.loj_id : null })
            .then(response => response.data)
            .then(data => {
                this.loading = false
                this.products = data;
            })
            .catch(error => {
                this.loading = false
                ErrorHandlerService.handle(error, this.$store, this.$store);
            });
        },

        getStores() {

            this.service
            .request_stores(this.quotation)
            .then(response => response.data)
            .then(data => {
                if (this.can('REQUEST_ORDER', 'READ_ALL'))this.stores = data;
                else data.forEach(element => {
                  if (element.loj_id == localStorage.getItem('store_id')) {
                    this.stores.push(element)
                    this.filters.store = element
                  }
                });
                this.stores.map(item =>{ item.loj_descricao = item.cot_id + " - " + item.loj_descricao});
                this.getProducts();
            })
            .catch(error => {
                this.loading = false
                ErrorHandlerService.handle(error, this.$store, this.$store);
            });
        },
        download_pdf() {
            let callback = data => {
                var file = new Blob([data], { type: "application/pdf" });
                var fileURL = URL.createObjectURL(file);
                var el = document.getElementById("download-pdf");
                var title = "produtos-cotacao-" + this.quotation + ".pdf";
                el.download = title
                el.href = fileURL;
                el.click();
                setTimeout(function() {
                    window.URL.revokeObjectURL(fileURL);
                }, 100);
            }

            this.is_loading_pdf = true;
            this.service.request_products_pdf_report(this.quotation, this.filters.store ? this.filters.store.loj_id : null).then(response => response.data)
            .then(callback).catch(error => {
                this.is_loading_pdf = false;
                ErrorHandlerService.handle(error, this.$store);
            });
        },
        print() {
            setTimeout(() => {

                var conteudoModal = window.document.getElementById("modal").innerHTML;

                var conteudoImpressao =
                    "<html>" +
                    "<head>" +
                    '<link rel="stylesheet" href="/css/app.css">' +
                    '<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic">' +
                    "<title>" +
                    "</title>" +
                    "<style> \
                        @media print {    \
                            .not-print, .not-print * { \
                            display: none !important; \
                            } \
                        } \
                    </style>" +
                    "</head>" +
                    "<body>" +
                    conteudoModal +
                    "</body>";

                let name = '_blank';
                let specs = ['fullscreen=yes','titlebar=yes', 'scrollbars=yes'];
                let replace = true;

                specs = !!specs.length ? specs.join(',') : '';

                var telaImpressao = window.open("", '_blank', ['fullscreen=yes','titlebar=yes', 'scrollbars=yes'], true);

                telaImpressao.document.write(conteudoImpressao);

                setTimeout(() => {
                    telaImpressao.document.close();
                    telaImpressao.focus();
                    telaImpressao.print();
                    telaImpressao.close();
                }, 1500);

                this.imprimindo = false;
            }, 500);
        },
        getSpecialBackground (index) {
          if (index % 2 != 0) return 'page-table-line-special'
        },
    },
    async mounted() {
        this.getStores();
    }
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}
.modal-content-container{
  background-color: white;
  min-width: 50vw;
  border-radius: 12px;
}
.requested-products__main-container {
  width: 90vw;
  height: 100vh;
}
.requested-products__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.requested-products__header-title {
  color: #FFFFFF;
  letter-spacing: -2%;
  font-weight: 500;
  font-size: 32px;
}
.requested-products__closebtn-icon {
  color: #FFFFFF;
  font-size: 3em;
  cursor: pointer;
}
.requested-products__actions-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px;
}

.requested-products__filter-input-wrapper {
  width: 30vw;
}
.mobile{display: none;}
.requested-products__filter-input {
}
.requested-products__actions-wrapper {
  display: flex;
  align-items: center;
  padding: 12px;
  margin: 0px 10px;
  border-radius: 8px;
  border: 1px solid #E5E5E5;
  background-color: #FAFAFC;
  cursor: pointer;
}
.requested-products__actions-icon {
  color: var(--primary-color);
  margin-right: 1rem;
}
.requested-products__actions-title {
  font-weight: 400;
  font-size: 16px;
  color: #505050;
}
.requested-products__table-container {
  margin: 20px;
  overflow: auto;
  max-height: 70vh;
  scrollbar-width: unset
}
.requested-products__table-wrapper {}
.requested-products__table-thead {}
.requested-products__table-thead-tr {
  background-color: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  color: #605F5F;
  font-size: 1.1em;
  font-weight: 400;
}
.requested-products__table-th {
  font-size: 1.1em;
  font-weight: 600;
}
.requested-products__table-tbody {}
.requested-products__table-tbody-tr {}
.requested-products__table-td {
  color: #605F5F;
  font-size: 1.1em;
  font-weight: 300;
}
.page-table-line-special{
  background: #F7F7F7;
}
.full-rigth{
  margin-left: auto;
}
@media only screen and (max-width: 850px) {
  .mobile{display: block;}

  .requested-products__main-container{
    width: 120vw;
  }
  .requested-products__table-container {
    max-height: 55vh;
  }
  .requested-products__actions-container{
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .requested-products__filter-input-wrapper{
    width: 100%;
    margin-bottom: 2vh;
  }
  .requested-products__actions-wrapper{
    width: 50vw;
    margin: 0;
  }
  .requested-products__header-title {
    font-size: 24px;
  }
  .mobile-table-row{
    display: flex;
    width: 97%;
    color: #605F5F;
    justify-content: space-between;
    margin: 0.5vh 0;
    p{
      margin: 0;
    }
  }
  .mobile-table-title{
    font-size: 1.1em;
    font-weight: bold;
  }
  .full-rigth{margin-left: 0; margin-bottom: 2vw;}
  .desktop{display: none}
}

	@import "./requested-products.modal";
</style>

<template>
<div class="category-analysis">
    <div class="category-analysis__container">
      <div class="row only-print">
        <div class="col p-4 text-center">
          <img class="logo" style="width:100px;" :src="whitelabel ? whitelabel.data.imgs.img_logo : require('@/assets/logo.png')"/>
        </div>
      </div>
      <div class="row only-print">
        <div class="col">
          <p style="padding: 0px;margin: 0px">Nome Produto:</p>
          <span>{{filtroProduto ? filtroProduto : "Todos"}}</span>
        </div>
        <div class="col">
          <p style="padding: 0px;margin: 0px">Requisitante:</p>
          <span>{{getNomeRequisitante ? getNomeRequisitante : "Todos"}}</span>
        </div>
        <div class="col">
          <p style="padding: 0px;margin: 0px">Fornecedor:</p>
          <span>{{getNomeFornecedor ? getNomeFornecedor : "Todos"}}</span>
        </div>
        <div class="col">
          <p style="padding: 0px;margin: 0px">Categoria:</p>
          <span>{{getNomeCategoria ? getNomeCategoria : "Todos"}}</span>
        </div>
        <div class="col" v-if="$store.state.dateIniRelatorio">
          <p style="padding: 0px;margin: 0px">Data Inicial:</p>
          <span>{{ $store.state.dateIniRelatorio | formatDate }}</span>
        </div>
        <div class="col" v-if="$store.state.dateFimRelatorio">
          <p style="padding: 0px;margin: 0px">Data Final:</p>
          <span>{{ $store.state.dateFimRelatorio | formatDate }}</span>
        </div>
      </div>
      <div class="page-buttons-container not-print">
        <div class="page-buttons-container__left-container">
          <div class="page-filters__right-wrapper">
            <div class="page-filters__right-title-wrapper">
              <div class="page-filters__right-title">Data Inicial</div>
            </div>
            <div class="page-filters__right-select-wrapper end-date">
              <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker" v-model="$store.state.dateIniRelatorio" auto type="date"></datetime>
              <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                today
              </span>
            </div>
          </div>
          <div class="page-filters__right-wrapper">
            <div class="page-filters__right-title-wrapper">
              <div class="page-filters__right-title">Data Fim</div>
            </div>
            <div class="page-filters__right-select-wrapper end-date">
              <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker" v-model="$store.state.dateFimRelatorio" auto type="date"></datetime>
              <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                today
              </span>
              
              <!-- <span @click="getProdutos(1, false)" class="material-icons input-icon">search</span> -->
            </div>
          </div>
          <span @click="getProdutos(1, false)" class="material-icons-outlined search-icon">
                search
              </span>
          <!-- <div class="page-buttons-wrapper">
            <div class="page-buttons-wrapper__title">Empresa</div>
            <div class="page-buttons-wrapper__select">
              <cc-select-v2 :tracker="'loj_id'" :text="'loj_descricao'" :options="$store.state.requisitantesFiltro" v-model="filtroRequisitante"></cc-select-v2>
            </div>
          </div>
          <div class="page-buttons-wrapper">
            <div class="page-buttons-wrapper__title">Fornecedor</div>
            <div class="page-buttons-wrapper__select">
              <cc-select-v2 :tracker="'for_id'" :text="'for_nome'" :options="$store.state.fornecedorFiltro" v-model="filtroFornecedor"></cc-select-v2>
            </div>
          </div>
          <div class="page-buttons-wrapper">
            <div class="page-buttons-wrapper__title">Categoria</div>
            <div class="page-buttons-wrapper__select">
              <cc-select-v2 :tracker="'cat_id'" :text="'cat_descricao'" :options="$store.state.categoriasFiltro" v-model="filtroCategoria"></cc-select-v2>
            </div>
          </div> -->
        </div>
        <div class="page-buttons-container__right-container">
          <Button class="action-buttons-width" title="Imprimir produtos" :text="'Imprimir'" :iconName="'print'" :action="print"/>
          <Button class="action-buttons-width" title="Limpar Filtros" :text="'Limpar Filtros'" :iconName="'cancel'" :action="reset_filter"/>
          <div class="reports-page__title-button-total action-buttons-width" style="justify-content: center;">
            Valor Total: R$ {{formartNumber(totalPedido)}}
          </div>
        </div>
      </div>
      <div class="page-filters-container not-print">
        <StandardInput class="standard-input-width"
                       :title="'Empresa'"
                       :action="setRequisitanteValue"
                       :width="'22%'"
                       :type="'select'"
                       :list="$store.state.requisitantesFiltroArray"
                       :placeholder="'Selecione a empresa'"/>
        <StandardInput class="standard-input-width"
                       :title="'Fornecedor'"
                       :action="setFornecedorValue"
                       :width="'22%'"
                       :type="'select'"
                       :list="$store.state.fornecedorFiltroArray"
                       :placeholder="'Selecione o fornecedor'"/>
        <StandardInput class="standard-input-width"
                       :title="'Categoria'"
                       :action="setCategoriaValue"
                       :width="'22%'"
                       :type="'select'"
                       :list="$store.state.categoriasFiltroArray"
                       :placeholder="'Selecione a categoria'"/>
        <StandardInput class="standard-input-width"
                       :title="'Produto'"
                       :action="setValue"
                       :width="'22%'"
                       :type="'text'"
                       :placeholder="'Digite o nome'"/>
        <!-- <div class="page-filters__left-container">
          <div class="page-filters__left-wrapper">
            <div class="page-filters__left-title-wrapper search-input">
              <div class="page-filters__left-title">Buscar por produto</div>
            </div>
            <div class="page-filters__left-select-wrapper">
              <cc-search :placeholder="'Nome do produto'" v-model="filtroProduto" :noicon="true" />
              <span @click="getProdutos(1, false)" class="material-icons input-icon">search</span>
            </div>
          </div>
        </div> -->
        <!-- <div class="page-filters__right-container">
          <div class="page-filters__right-wrapper">
            <div class="page-filters__right-title-wrapper">
              <div class="page-filters__right-title">Data Inicial</div>
            </div>
            <div class="page-filters__right-select-wrapper">
              <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker" v-model="$store.state.dateIniRelatorio" auto type="date"></datetime>
              <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                today
              </span>
            </div>
          </div>
          <div class="page-filters__right-wrapper">
            <div class="page-filters__right-title-wrapper">
              <div class="page-filters__right-title">Data Fim</div>
            </div>
            <div class="page-filters__right-select-wrapper">
              <datetime style="border-radius: 8px !important;" class="form-control" ref="datepicker" v-model="$store.state.dateFimRelatorio" auto type="date"></datetime>
              <span @click="open_datepicker(0)" class="material-icons-outlined date-icon">
                today
              </span>
              <span @click="getProdutos(1, false)" class="material-icons input-icon">search</span>
            </div>
          </div>
        </div> -->
      </div>
      <div class="page-selected-filters-containear">
        <div v-if="filtroProduto" class="page-selected-filters">
          <div class="page-selected-filters-text">{{ filtroProduto }}</div>
          <span v-on:click="setValue('')" class="material-icons page-selected-filters-icon">close</span>
        </div>
        <div v-if="filtroRequisitante" class="page-selected-filters">
          <div class="page-selected-filters-text">{{ filtroRequisitante.loj_descricao }}</div>
          <span @click="setRequisitanteValue('')" class="material-icons page-selected-filters-icon">close</span>
        </div>
        <div v-if="filtroFornecedor" class="page-selected-filters">
          <div class="page-selected-filters-text">{{ filtroFornecedor.for_nome }}</div>
          <span @click="setFornecedorValue('')" class="material-icons page-selected-filters-icon">close</span>
        </div>
        <div v-if="filtroCategoria" class="page-selected-filters">
          <div class="page-selected-filters-text">{{ filtroCategoria.cat_descricao }}</div>
          <span @click="setCategoriaValue('')" class="material-icons page-selected-filters-icon">close</span>
        </div>
      </div>
      <no-data-available v-if="produtos.length == 0 && !loading" :text="'Nenhum resultado encontrado'" />
      <div v-if="produtos.length > 0 && !loading && !showTable" class="page-table-container">
        <div class="not-print">
          <div class="page-table-header">
            <div class="page-table-header-text category-width">Categoria</div>
            <div class="page-table-header-text qty-width">
              Qtd.
              <cc-label-sort :showicon="ordernar=='quantidade'" :direction="tipoOrder == 'asc' ? 1 : 0" @click="setFiltroOrder('quantidade', 'desc')"/>
            </div>
            <div class="page-table-header-text total-val-width">
              Valor Total
              <cc-label-sort :showicon="ordernar=='valorTotal'" :direction="tipoOrder == 'asc' ? 1 : 0" @click="setFiltroOrder('valorTotal', 'desc')"/>
            </div>
            <div class="page-table-header-text percent-width">%</div>
          </div>
          <div v-for="(produto, idx) in produtos"
               :key="'pro-tab-' + produto.pro_id"

               @click="clickTable(produto)">
            <div class="page-table-row-containear">
              <div class="page-table-row" :class="getSpecialBackground(idx)">
                <div class="page-table-desc-column category-width">
                  <div class="category-analysis-mobile-header">Categoria</div>
                  <span v-if="!produto.expanded" class="material-icons product-tab-colapsed-icon">arrow_forward_ios</span>
                  <span v-else class="material-icons product-tab-expanded-icon">arrow_forward_ios</span>
                  {{ produto.cat_descricao }}
                </div>
                <div class="page-table-desc-column qty-width">
                  <div class="category-analysis-mobile-header">Qtd.</div>
                  {{ produto.quantidade }}
                </div>
                <div class="page-table-desc-column total-val-width">
                  <div class="category-analysis-mobile-header">Valor Total</div>
                  {{ formartNumber(produto.valorTotal) }}
                </div>
                <div class="page-table-desc-column percent-width">
                  <div class="category-analysis-mobile-header">%</div>
                  {{ produto.percent }}%
                </div>
              </div>
              <div class="page-table-row" v-show="produto.loading" :key="'prod-loader-' + produto.pro_id">
                <cc-loader style="margin: 5vh auto; width: 120px;"/>
              </div>
              <div v-show="!produto.loading && produto.produtos && produto.expanded"
                   :key="'prod-detail-' + produto.pro_id"
                   class="page-table-row"
                   style="justify-content: center;">
                <div class="category-analysis-sub-table">
                  <div class="page-table-header" style="padding: 15px 0px;">
                    <div class="page-table-header-text descricao-width">Descrição</div>
                    <div class="page-table-header-text quantidade-width">Quantidade</div>
                    <div class="page-table-header-text valor-total-width">Valor Total</div>
                    <div class="page-table-header-text valor-unit-width">Valor Unit</div>
                    <div class="page-table-header-text porcento-width">%</div>
                    <div class="page-table-header-text porcento-acumulado-width">% Acumulado</div>
                  </div>
                  <div v-for="(produtoInt, index) in produto.produtos"
                       :key="`inf-line-${index}`">
                    <div class="page-table-row-container">
                      <div class="page-table-row" :class="getSpecialBackground(index)">
                        <div class="page-table-desc-column descricao-width">
                          <div class="category-analysis-mobile-header">Descrição</div>
                          {{ produtoInt.pro_descricao }}
                        </div>
                        <div class="page-table-desc-column quantidade-width">
                          <div class="category-analysis-mobile-header">Quantidade</div>
                          {{ produtoInt.quantidade }}
                        </div>
                        <div class="page-table-desc-column valor-total-width">
                          <div class="category-analysis-mobile-header">Valor Total</div>
                          {{ produtoInt.valorTotal | currency }}
                        </div>
                        <div class="page-table-desc-column valor-unit-width">
                          <div class="category-analysis-mobile-header">Valor Unit</div>
                          {{ produto.cli_novoPedido == 1 && produto.cli_tipoPedido == 1 ? formartNumber(produtoInt.valorTotal / produtoInt.quantidade ) : formartNumber(produtoInt.valorTotal / (produtoInt.quantidade)) }}
                        </div>
                        <div class="page-table-desc-column porcento-width">
                          <div class="category-analysis-mobile-header">%</div>
                          {{ produtoInt.percent }}%
                        </div>
                        <div class="page-table-desc-column porcento-acumulado-width">
                          <div class="category-analysis-mobile-header">% Acumulado</div>
                          {{ produtoInt.percentAcumulate }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="page-table-row">
                <table v-show="!produto.loading && produto.produtos && produto.expanded"
                       class="additional-info table"
                       :key="'prod-detail-' + produto.pro_id">
                  <thead>
                    <tr>
                      <th>Descrição</th>
                      <th>Quantidade</th>
                      <th>Valor Total</th>
                      <th>Valor Unit</th>
                      <th>%</th>
                      <th>% Acumulado</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(produtoInt, index) in produto.produtos" :key="`inf-line-${index}`">
                      <td>{{ produtoInt.pro_descricao }}</td>
                      <td>{{ produtoInt.quantidade }}</td>
                      <td>{{ produtoInt.valorTotal | currency }}</td>
                      <td>{{ produto.cli_novoPedido == 1 && produto.cli_tipoPedido == 1 ? formartNumber(produtoInt.valorTotal / produtoInt.quantidade ) : formartNumber(produtoInt.valorTotal / (produtoInt.quantidade)) }}</td>
                      <td>{{ produtoInt.percent }}%</td>
                      <td>{{ produtoInt.percentAcumulate }}%</td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="mobile">
        <CategoryMobileRow v-for="(produto, idx) in produtos"
        :key="'pro-tab-' + produto.pro_id" :cat="produto" />
      </div>

      <!-- tabela apenas impressão -->
      <div class="container-fluid p-4 shadow mb-4 only-print">
        <table class="table mb-3" v-if="produtos.length > 0 && !loading">
          <thead>
            <tr>
              <th>Categoria</th>
              <th class="text-center">
                <cc-label-sort :showicon="ordernar=='quantidade'" :direction="tipoOrder == 'asc' ? 1 : 0" :content="'Quantidade'" @click="setFiltroOrder('quantidade', 'desc')"/>
              </th>
              <th>
                <cc-label-sort :showicon="ordernar=='valorTotal'" :direction="tipoOrder == 'asc' ? 1 : 0" :content="'Valor Total'" @click="setFiltroOrder('valorTotal', 'desc')"/>
              </th>
              <th class="text-center">%</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="produto in produtos">
              <tr :key="'pro-' + produto.cat_id" @click="clickTable(produto)">
                <td>{{ produto.cat_descricao }}</td>
                <td class="text-center">{{ produto.quantidade }}</td>
                <td>{{ produto.valorTotal | currency }}</td>
                <td class="text-center">{{ getPercentual(produto) }}</td>
              </tr>
              <tr v-show="produto.loading" :key="'prod-loader-' + produto.pro_id">
                <td colspan="4"><cc-loader /></td>
              </tr>
              <tr v-show="!produto.loading && produto.produtos && produto.expanded" class="additional-info" :key="'prod-detail-' + produto.pro_id">
                <td colspan="4" class="p-0">
                  <div class="m-4 p-4 shadow">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Descricao</th>
                          <th class="text-center">Quantidade</th>
                          <th>Valor Total</th>
                          <th>Valor Unit.</th>
                          <th class="text-center">%</th>
                          <th class="text-center">% Acumulado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(produtoInt, index) in produto.produtos" :key="`inf-line-${index}`">
                          <td>{{ produtoInt.pro_descricao }}</td>
                          <td class="text-center">{{ produtoInt.quantidade }}</td>
                          <td>{{ produtoInt.valorTotal | currency }}</td>
                          <td>{{ produto.cli_novoPedido == 1 && produto.cli_tipoPedido == 1 ? formartNumber(produtoInt.valorTotal / produtoInt.quantidade ) : formartNumber(produtoInt.valorTotal / (produtoInt.quantidade)) }}</td>
                          <td class="text-center">{{ produtoInt.percent }}%</td>
                          <td class="text-center">{{ produtoInt.percentAcumulate }}%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <cc-loader style="margin: 15vh auto; width: 120px;" v-show="loading" />
    </div>
    <tr v-show="total_items > items_by_page_value && !showTable" class="seemore-button-containear not-print">
      <td class="text-center" colspan="6">
        <Paginacao  classes="orange justify-end"
                    :items_by_page_value="items_by_page_value"
                    :total="total_items"
                    :page="page"
                    v-on:change="updatePage($event)">
        </Paginacao>
      </td>
    </tr>
  </div>
    <!-- <div>
        <div class="container-fluid pb-4">
            <div class="row only-print">
                <div class="col p-4 text-center">
                    <img class="logo" style="width:100px;"  src="/images/logotipo.png"/>
                </div>
            </div>
            <div class="row only-print">
                <div class="col">
                    <p style="padding: 0px;margin: 0px">Nome Produto:</p>
                    <span>{{filtroProduto ? filtroProduto : "Todos"}}</span>
                </div>
                <div class="col">
                    <p style="padding: 0px;margin: 0px">Requisitante:</p>
                    <span>{{getNomeRequisitante ? getNomeRequisitante : "Todos"}}</span>
                </div>
                <div class="col">
                    <p style="padding: 0px;margin: 0px">Fornecedor:</p>
                    <span>{{getNomeFornecedor ? getNomeFornecedor : "Todos"}}</span>
                </div>
                <div class="col">
                    <p style="padding: 0px;margin: 0px">Categoria:</p>
                    <span>{{getNomeCategoria ? getNomeCategoria : "Todos"}}</span>
                </div>
                <div class="col" v-if="$store.state.dateIniRelatorio">
                    <p style="padding: 0px;margin: 0px">Data Inicial:</p>
                    <span>{{ $store.state.dateIniRelatorio | formatDate }}</span>
                </div>
                <div class="col" v-if="$store.state.dateFimRelatorio">
                    <p style="padding: 0px;margin: 0px">Data Final:</p>
                    <span>{{ $store.state.dateFimRelatorio | formatDate }}</span>
                </div>
            </div>
            <div class="row py-4 not-print">
                <div class="not-print col-2">
                    <label class="label-control">Busca por produtos</label>
                    <cc-search :placeholder="'Nome do produto'" v-model="filtroProduto" :noicon="true" />
                </div>
                <div class="col">
                    <label class="label-control">Empresa</label>
                    <cc-select-v2 :tracker="'loj_id'" :text="'loj_descricao'" :options="$store.state.requisitantesFiltro" v-model="filtroRequisitante"></cc-select-v2>
                </div>
                <div class="col">
                    <label class="label-control">Fornecedor</label>
                    <cc-select-v2 :tracker="'for_id'" :text="'for_nome'" :options="$store.state.fornecedorFiltro" v-model="filtroFornecedor"></cc-select-v2>
                </div>
                <div class="col">
                    <label class="label-control">Categoria</label>
                    <cc-select-v2 :tracker="'cat_id'" :text="'cat_descricao'" :options="$store.state.categoriasFiltro" v-model="filtroCategoria"></cc-select-v2>
                </div>
                <div class="col-md-2 col-lg-2">
                    <div class="datetime-wrp">
                        <label class="label-control">
                            Data de Início
                        </label>
                        <datetime class="form-control" ref="datepicker" v-model="$store.state.dateIniRelatorio" auto type="date"></datetime>
                        <span class="picker rounded-right" @click="open_datepicker(0)">
                            <i class="fa fa-calendar"></i>
                        </span>
                    </div>
                </div>
                <div class="col-md-2 col-lg-2">
                    <div class="datetime-wrp">
                        <label class="label-control">Data Fim</label>
                        <datetime class="form-control" ref="datepicker" v-model="$store.state.dateFimRelatorio" auto type="date"></datetime>
                        <span class="picker rounded-right" @click="open_datepicker(1)">
                            <i class="fa fa-calendar"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="row not-print">
                <div class="col-4 d-flex align-bottom justify-content-around filter-controls">
                    <cc-button :classes="'fixed secondary'" @click="getProdutos(1, false)" :icon="'fa fa-search fa-lg'" />
                    <cc-button :icon="'fa fa-print'" :classes="'fixed'" :content="'Imprimir'" @click="print" />
                    <cc-button :classes="'fixed danger-outline'" :icon="'far fa-trash-alt'" @click="reset_filter()" :content="'Limpar Filtros'"  />
                </div>
                <div class="col-8 d-flex justify-content-end">
                    <div class="totalDiv mr-3">
                        <b>Total: R$ {{formartNumber(totalPedido)}}</b>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid p-4 shadow mb-4">

            <no-data-available v-if="produtos.length == 0 && !loading" :text="'Nenhum resultado encontrado'" />

            <table class="table mb-3" v-if="produtos.length > 0 && !loading">
                <thead>
                    <tr>
                        <th>Categoria</th>
                        <th class="text-center">
                            <cc-label-sort :showicon="ordernar=='quantidade'" :direction="tipoOrder == 'asc' ? 1 : 0" :content="'Quantidade'" @click="setFiltroOrder('quantidade', 'desc')"/>
                        </th>
                        <th>
                            <cc-label-sort :showicon="ordernar=='valorTotal'" :direction="tipoOrder == 'asc' ? 1 : 0" :content="'Valor Total'" @click="setFiltroOrder('valorTotal', 'desc')"/>
                        </th>
                        <th class="text-center">%</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="produto in produtos">
                        <tr :key="'pro-' + produto.cat_id" @click="clickTable(produto)">
                            <td>{{ produto.cat_descricao }}</td>
                            <td class="text-center">{{ produto.quantidade }}</td>
                            <td>{{ produto.valorTotal | currency }}</td>
                            <td class="text-center">{{ getPercentual(produto) }}</td>
                        </tr>
                        <tr v-show="produto.loading" :key="'prod-loader-' + produto.pro_id">
                            <td colspan="4"><cc-loader /></td>
                        </tr>
                        <tr v-show="!produto.loading && produto.produtos && produto.expanded" class="additional-info" :key="'prod-detail-' + produto.pro_id">
                            <td colspan="4" class="p-0">
                                <div class="m-4 p-4 shadow">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Descricao</th>
                                                <th class="text-center">Quantidade</th>
                                                <th>Valor Total</th>
                                                <th>Valor Unit.</th>
                                                <th class="text-center">%</th>
                                                <th class="text-center">% Acumulado</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(produtoInt, index) in produto.produtos" :key="`inf-line-${index}`">
                                                <td>{{ produtoInt.pro_descricao }}</td>
                                                <td class="text-center">{{ produtoInt.quantidade }}</td>
                                                <td>{{ produtoInt.valorTotal | currency }}</td>
                                                <td>{{ produto.cli_novoPedido == 1 && produto.cli_tipoPedido == 1 ? formartNumber(produtoInt.valorTotal / produtoInt.quantidade ) : formartNumber(produtoInt.valorTotal / (produtoInt.quantidade)) }}</td>
                                                <td class="text-center">{{ produtoInt.percent }}%</td>
                                                <td class="text-center">{{ produtoInt.percentAcumulate }}%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>

            <cc-loader v-show="loading" />

            <div class="clearfix not-print" v-if="produtos.length > 0 && !loading">
                <cc-paginacao :paginaAtual="paginaAtual" :totalPaginas="totalPaginas" :funcao="getProdutos"></cc-paginacao>
            </div>

        </div>
    </div> -->
</template>

<script>
import { filtroData } from "@/mixins/filtroData";
import { Money } from "v-money";
import { mascaraCNPJ } from "@/mixins/mascaraCNPJ";
import numeral from "numeral";
//import Paginacao from "@/components/shared/Paginacao";
import Paginacao from "@/components/cliente/base-components/Pagination";
import * as CONSTANTS from "@/constants/constants";
import Button from "@/components/ui/buttons/Standard-Button.vue"
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import { mapState } from "vuex";
import CategoryMobileRow from "./CategoryMobileRow.vue";

export default {
    components: {
      Paginacao,
      Button,
      StandardInput,
      CategoryMobileRow
    },
    mixins: [filtroData, mascaraCNPJ],
    data() {
        return {
        showTable: false,
        page: 1,
        items_by_page_value: 30,
        total_items: 1000,
        image:"/images/search.png",
        image2:"/images/calendar.png",
        paginaAtual: 1,
        totalPaginas: 1,
        itensPorPagina: 0,
        totalPedido: 0,
        totalItens: 0,
        date_picker_options: {
            format: "DD/MM/YYYY",
            locale: "pt-br",
            useCurrent: false
        },
        produtos: [],
        filtroProduto: "",
        filtroRequisitante: null,
        filtroFornecedor: null,
        filtroCategoria: null,
        classOrder: "fa-sort-asc",
        ordernar: "valorTotal",
        tipoOrder: "desc",
        loading: false,
        fDateIni: moment().format("YYYY-MM-DD"),
        fDateFim: moment().format("YYYY-MM-DD")
        };
    },
    computed: {
      ...mapState(["whitelabel"]),
        getNomeCategoria() {
            let lojaSelect = this.$store.state.categoriasFiltro.filter(
                item => item.cat_id == this.filtroCategoria
            );
            if (lojaSelect.length == 0) {
                return "";
            }
            return lojaSelect[0].cat_descricao;
        },
        getNomeRequisitante() {
            let requisitanteSelect = this.$store.state.requisitantesFiltro.filter(
                item => item.loj_id == this.filtroRequisitante
            );

            if (requisitanteSelect.length == 0) {
                return "";
            }
            return requisitanteSelect[0].loj_descricao;
        },
    getNomeFornecedor() {
      let fornecedorSelect = this.$store.state.fornecedorFiltro.filter(
        item => item.for_id == this.filtroFornecedor
      );
      if (fornecedorSelect.length == 0) {
        return "";
      }
      return fornecedorSelect[0].for_nome;
    }
  },
  watch: {
    filtroProduto: function() {
        if (!this.loading) {
          this.loading = true
          setTimeout(() => {
            this.getProdutos();
          }, 1500);
        }
      },
      filtroRequisitante: function() {
        if (!this.loading) {
          this.loading = true
          setTimeout(() => {
            this.getProdutos();
          }, 1500);
        }
      },
      filtroFornecedor: function() {
        if (!this.loading) {
          this.loading = true
          setTimeout(() => {
            this.getProdutos();
          }, 1500);
        }
      },
      filtroCategoria: function() {
        if (!this.loading) {
          this.loading = true
          setTimeout(() => {
            this.getProdutos();
          }, 1500);
        }
      },
  },
  methods: {
        getSpecialBackground(idx) {
          if (idx % 2 != 0) return 'page-table-line-special'
        },
        openTable() {
          this.showTable = !this.showTable
        },
        setValue(value){
          this.filtroProduto = value;
        },
        setRequisitanteValue(value){
          this.filtroRequisitante = value;
        },
        setFornecedorValue(value){
          this.filtroFornecedor = value;
        },
        setCategoriaValue(value){
          this.filtroCategoria = value;
        },
        print() {
            this.imprimir = true;
            this.getProdutos(1,false,true);
        },
        reset_filter() {
            this.filtroRequisitante = null;
            this.filtroData = null;
            this.filtroProduto = null;
            this.filtroCategoria = null;
            this.filtroFornecedor = null
        },
        open_datepicker(idx) {
            document.getElementsByClassName('vdatetime-input')[idx].click()
        },
        setFiltroOrder(order, tipo) {
            if (order == this.ordernar && tipo == this.tipoOrder && tipo == "asc") {
                this.tipoOrder = "desc";
            } else if (
                order == this.ordernar &&
                tipo == this.tipoOrder &&
                tipo == "desc"
            ) {
                this.tipoOrder = "asc";
            } else {
                this.tipoOrder = tipo;
            }
            this.classOrder = "fa-sort-" + this.tipoOrder;


            this.ordernar = order;

            if (this.produtos.length > 0) {
                this.getProdutos(this.paginaAtual);
            }
        },
        formartNumber(n) {
            if (n) {
                return numeral(n.toString().replace(".", ",")).format("0,0.00");
            } else {
                return n;
            }
        },
        getPercentual(produto) {
            return (
                parseFloat((produto.valorTotal * 100) / this.totalPedido).toFixed(2) +
                "%"
            );
        },
        onDateRangeChangedIni: function(picker) {

            this.fDateIni = picker.startDate.format("YYYY-MM-DD");
            this.$store.commit(
                "changeDateIniRelatorioCliente",
                picker.startDate.format("YYYY-MM-DD")
            );
        },
        clickTable(product) {
            this.getProdutosInternos(product)
        },
        getProdutosInternos(product) {

            product.loading = true
            this.$forceUpdate();

            if(!product.produtos) {

                var requestUrl =
                    `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?categoria=` +
                    product.cat_id +
                    "&groupBy=p.pro_id&imprimir=true&ordenar=valorTotal&tipoorder=desc";

                const dateIni = this.$store.state.dateIniRelatorio;
                const dateFim = this.$store.state.dateFimRelatorio;

                requestUrl +=
                    "&dateIni=" + dateIni +
                    "&dateFim=" + dateFim;
                if (this.filtroProduto) {
                    requestUrl += "&nomeproduto=" + this.filtroProduto;
                }

                if(this.filtroRequisitante)requestUrl += "&requisitante=" + this.filtroRequisitante.loj_id;
                if(this.filtroFornecedor)requestUrl += "&fornecedor=" + this.filtroFornecedor.for_id
                if(this.filtroCategoria)requestUrl += "&categoria=" + this.filtroCategoria.cat_id

                axios.get(requestUrl, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                }).then(({ data }) => {
                    let lastPercent = 0;
                    product.produtos = data.produtos.map(itemInt => {
                        let newItemInt = itemInt;
                        newItemInt.percent = parseFloat(
                            (itemInt.valorTotal * 100) / product.valorTotal
                        ).toFixed(2);
                        newItemInt.percentAcumulate = parseFloat(
                            parseFloat(newItemInt.percent) + parseFloat(lastPercent)
                        ).toFixed(2);
                        lastPercent = newItemInt.percentAcumulate;
                        return newItemInt;
                    });
                    product.expanded = !product.expanded
                    this.$forceUpdate();
                    product.loading = false
                })
                .catch(error => {

                    product.loading = false;
                    if (error.response.status === 401) {
                        if (error.response.data.mensagem == CONSTANTS.TOKEN_EXPIRADO) {
                            // location.href = CONSTANTS.ROUTE_LOGIN;
                            window.location.assign(CONSTANTS.ROUTE_LOGIN)

                        }
                    } else if (error.response.status === 500) {
                        if (error.response.data.mensagem) {
                            this.mensagemErro = error.response.data.mensagem;
                        } else {
                            this.mensagemErro = "Não foi possível abrir os dados gerenciais";
                        }
                    }
                    product.loading = false
                });
            } else {
                product.expanded = !product.expanded
                product.loading = false
                this.$forceUpdate();
            }
        },
        getPercentualNumber(produto) {
            return parseFloat((produto.valorTotal * 100) / this.totalPedido);
        },
        getProdutos(pagina, variable, imprimir = false) {
            this.loading = true;
            var requestUrl =
                `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?groupBy=cat.cat_id&ordenar=` +
                this.ordernar +
                "&tipoorder=" +
                this.tipoOrder +
                "&page=" +
                pagina;

            const dateIni = moment(this.$store.state.dateIniRelatorio).format('YYYY-MM-DD')
            const dateFim = moment(this.$store.state.dateFimRelatorio).format('YYYY-MM-DD')
            requestUrl +=
                "&dateIni=" + dateIni +
                "&dateFim=" + dateFim
            if (this.filtroProduto != "") {
                requestUrl += "&nomeproduto=" + this.filtroProduto;
            }
            if(this.filtroRequisitante)requestUrl += "&requisitante=" + this.filtroRequisitante.loj_id;
            if(this.filtroFornecedor)requestUrl += "&fornecedor=" + this.filtroFornecedor.for_id
            if(this.filtroCategoria)requestUrl += "&categoria=" + this.filtroCategoria.cat_id
            if (imprimir) {
                requestUrl += "&imprimir=" + true;
                // var telaImpressao = window.open("about:blank");
            }
            axios.get(requestUrl, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token")
                }
            }).then(({ data }) => {
                this.loading = false;

                if (imprimir) {
                    requestUrl += "&imprimir=" + true;
                    // var telaImpressao = window.open("about:blank");
                    this.totalPedido = data.totalProdutos.valorTotal
                    ? data.totalProdutos.valorTotal
                    : 0;
                    this.produtos = data.produtos.map((item, index) => {
                    let newItem = item;
                    let lastPercent = 0;
                    newItem.percent = this.getPercentualNumber(item).toFixed(2);
                    //percentual acumulado para itens internos
                    if (item.produtos) {
                        newItem.produtos = item.produtos.map(itemInt => {
                        let newItemInt = itemInt;
                        newItemInt.percent = parseFloat(
                            (itemInt.valorTotal * 100) / item.valorTotal
                        ).toFixed(2);
                        newItemInt.percentAcumulate = parseFloat(
                            parseFloat(newItemInt.percent) + parseFloat(lastPercent)
                        ).toFixed(2);
                        lastPercent = newItemInt.percentAcumulate;
                        return newItemInt;
                        });
                    }

                    return newItem;
                    });

                    setTimeout(() => {
                    window.print();
                    this.imprimindo = false;
                    }, 100);
                } else {
                    this.totalPedido = data.totalProdutos.valorTotal
                    ? data.totalProdutos.valorTotal
                    : 0;
                    this.paginaAtual = data.produtos.current_page;
                    this.totalPaginas = data.produtos.last_page;
                    this.total_items = data.produtos.total;
                    this.itensPorPagina = data.produtos.per_page;
                    this.totalItens = data.produtos.total;
                    this.produtos = data.produtos.data.map((item, index) => {
                    let newItem = item;
                    let lastPercent = 0;
                    newItem.percent = this.getPercentualNumber(item).toFixed(2);
                    //percentual acumulado para itens internos
                    if (item.produtos) {
                        newItem.produtos = item.produtos.map(itemInt => {
                        let newItemInt = itemInt;
                        newItemInt.percent = parseFloat(
                            (itemInt.valorTotal * 100) / item.valorTotal
                        ).toFixed(2);
                        newItemInt.percentAcumulate = parseFloat(
                            parseFloat(newItemInt.percent) + parseFloat(lastPercent)
                        ).toFixed(2);
                        lastPercent = newItemInt.percentAcumulate;
                        return newItemInt;
                        });
                    }

                    return newItem;
                    });
                }
            }).catch(error => {
                console.log(error)
                this.loading = false;
                if (error.response.status === 401) {
                    if (error.response.data.mensagem == CONSTANTS.TOKEN_EXPIRADO) {
                    // location.href = CONSTANTS.ROUTE_LOGIN;
                    window.location.assign(CONSTANTS.ROUTE_LOGIN)
                    }
                } else if (error.response.status === 500) {
                    if (error.response.data.mensagem) {
                    this.mensagemErro = error.response.data.mensagem;
                    } else {
                    this.mensagemErro = "Não foi possível abrir os dados gerenciais";
                    }
                }
            });
        }
  },
  mounted() {}
};
</script>
<style lang="scss" scoped>
.category-analysis {}
.category-analysis__container {}
.category-analysis__add-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 1.5em;
}
.category-analysis__add-button-container {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-right: 1em;
}
.category-analysis__total-value {
  padding: 0.55em;
  border-radius: 8px;
  background-color: var(--primary-color);
}
.category-analysis__total-value-text {
  color: #FFF;
  font-weight: 600;
}
.input-title{
  font-weight: 400;
  font-size: 1.7em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.input-container{
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
}
.date-container {
  align-items: center;
  border-radius: 8px !important;
  display: flex;
  width: 20vw;
}
.date-icon {
  background-color: var(--primary-color);
  color: #fff;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 0px 8px 8px 0px;
  position: relative;
  left: -6px;
}
.input-value{
  border: none;
  flex: 1;
  font-weight: 400;
  font-size: 1.1em;
  letter-spacing: 0.15px;
  color: #B3B3B3 !important;
}
.category-analysis-sub-table{
  width: 100%;
}
.input-value:focus{
  border: none;
  outline: none;
}
.input-icon{
  color: #fff;
  background-color: var(--primary-color);
  margin-left: 5px;
  padding: 0.3em;
  border-radius: 8px;
  cursor: pointer;/*
  position: relative;
  left: -30px; */
}
.input-select-value{
  border: none;
  font-weight: 400;
  font-size: 1.1em;
  letter-spacing: 0.15px;
  color: #B3B3B3;
  flex: 1;
  height: 24px;
}
.input-select-value:focus{
  border: none;
  outline: none;
  color: #B3B3B3;
}
.category-analysis__page-filter-container{
  display: flex;
  align-items: center;
  margin-top: 2em;
  justify-content: space-between;
}
.category-analysis__page-filter-wrapper {
  width: 20%;
  margin-right: 1em;
}
.category-analysis__page-filter-wrapper:last-child {

}
.page-table-container{
  margin-top: 20px;
}
.page-table-header{
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  font-weight: 400;
  font-size: 1.3em;
  color: #605F5F;
  display: flex;
  padding: 1vh 0;
}
.page-table-store-width{
  width: 15%;
  padding-left: 10px;
}
.page-table-row-container{
  margin-top: 10px;
}
.page-table-row{
  display: flex;
  font-weight: 300;
  align-items: center;
  line-height: 14px;
  color: #605F5F;
  cursor: pointer;
  background-color: white;
}
.page-table-desc-column{
  padding: 0.6em 0.8em;
  border-right: 1px solid #DDE5E9;
}
.page-table-unit-column{
  padding-left: 10px;
  border-right: 1px solid #DDE5E9;
}
.page-table-price-column{
  padding-left: 10px;
  border-right: 1px solid #DDE5E9;
}
.page-table-date-column{
  padding-left: 10px;
  border-right: 1px solid #DDE5E9;
}
.page-table-actions-colum{
  padding-left: 10px;
  border-right: 1px solid #DDE5E9;
}
.table {
  margin: 10px !important;
  -webkit-box-shadow: 6px 6px 8px 0.5px rgba(0,0,0,0.16);
  box-shadow: 6px 6px 8px 0.5px rgba(0,0,0,0.16);
}
.page-table-header-text{
  font-weight: 400;
  color: #605F5F;
  padding-left: 10px;
}
.seemore-button-containear{
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 70px;
}
.category-width {
  width: 25%;
  display: flex;
  align-items: center;
}
.qty-width {
  width: 25%;
}
.unit-val-width {
  width: 8%;
}
.total-val-width {
  width: 25%;
}
.percent-width {
  width: 25%;
}
.percent-accumulated-width {
  width: 12%;
}
.page-table-line-special {
  background: #F7F7F7;
}
.seemore-button-containear {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 70px;
}
.page-selected-filters-containear {
  display: flex;
  margin-top: 14px;
}
.page-selected-filters {
  background: #FCF9F7;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 7px 12px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.page-selected-filters-text {
  font-weight: 300;
  font-size: 1.1em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--primary-color);
  margin-right: 5px;
}
.page-selected-filters-icon {
  color: var(--primary-color);
  cursor: pointer;
}
.page-buttons-container {
  display: flex;
  margin: 2em 0em;
  align-items: center;
  justify-content: space-between;
}
.page-buttons-container__left-container {
  display: flex;
  align-items: flex-end;
}
.page-buttons-container__right-container {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
.page-buttons-wrapper {
  width: 20vw;
  margin-right: 1em;
}
.category-analysis-mobile-header{
  display: none
}
.page-buttons-wrapper__title {
  font-weight: 400;
  font-size: 1.6em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.action-button {
  width: 15vw;
}
.page-filters-container {
  display: flex;
  justify-content: space-between;
}
.page-filters__left-container {}
.page-filters__left-wrapper {}
.search-input {
  width: 23.3vw;
}
.page-filters__left-title-wrapper {}
.page-filters__left-title {
  font-weight: 400;
  font-size: 1.6em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.page-filters__left-select-wrapper {
  display: flex;
}
.page-filters__right-container {
  display: flex;
}
.page-filters__right-wrapper {
  margin-right: 10px;
}
.page-filters__right-title-wrapper {
  display: flex;
  align-items: center;
}
.page-filters__right-title {
  font-weight: 400;
  font-size: 1.6em;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #898989;
}
.page-filters__right-select-wrapper {
  display: flex;
  align-items: center;
}
.page-filters__total-value {
  display: flex;
  padding: 1.8em;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #E5E5E5;
}
.page-filters__total-value-text {
  color: #202020;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.05em;
}
.action-buttons-width {
  margin-left: 10px;
}
.page-filters__total-value__img {
  margin-right: 10px;
}
.search-icon {
  margin: 0em 0.2em;
  color: #fff;
  background-color: var(--primary-color);
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
}
.preorder-seller-title-button-excel {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #E5E5E5;
  border-radius: 9.5px;
  font-weight: 600;
  padding: 0 1vw;
  height: 7.5vh;
  font-size: 1.3em;
  cursor: pointer;
  margin-left: 10px;
}
.preorder-seller-title-button-excel-icon {
  margin-right: 1vw;
}
.reports-page__title-button-total {
  background: rgba(23, 162, 184, 0.1);
  border: 1px solid #E5E5E5;
  border-radius: 9.5px;
  font-weight: 600;
  font-size: 1.3em;
  color: #5281FF;
  display: flex;
  align-items: center;
  padding: 0 1vw;
  height: 7.5vh;
}
.descricao-width {
  width: 24.6%;
}
.quantidade-width {
  width: 12.6%;
}
.valor-total-width {
  width: 16.6%;
}
.valor-unit-width {
  width: 12.6%;
}
.porcento-width {
  width: 16.6%;
}
.porcento-acumulado-width {
  width: 16.6%;
}
.product-tab-colapsed-icon{
  font-size: 1.5em;
  margin-right: 1vw;
}
.product-tab-expanded-icon{
  color: var(--primary-color);
  font-size: 1.5em;
  rotate: 90deg;
  margin-right: 1vw;
}
.mobile{
  display: none;
}
    @media only screen and (max-width: 800px) {
        .category-analysis__add-buttons {
          flex-direction: column;
        }
        .mobile{
          display: block;
        }
        .category-analysis__add-button-container {
          margin: 5px 0px;
        }
        .category-analysis__page-filter-container {
          flex-direction: column;
        }
        .category-analysis__page-filter-wrapper {
          margin: 10px 0px;
          width: 95vw;
        }
        .date-container {
          width: 100vw;
        }
        .page-filters-container {
          flex-wrap: wrap;
        }
        .page-buttons-container {
          flex-direction: column;
          align-items: flex-start;
        }
        .page-buttons-container__left-container {
          flex-wrap: wrap;
        }
        .page-buttons-wrapper {
          width: 100vw;
        }
        .page-buttons-container__right-container {
          flex-wrap: wrap;

        }
        .page-filters__right-container {
          flex-direction: column;
        }
        .end-date{
          width: 50vw;
        }
        .page-filters__total-value {
          width: 100vw;
        }
        .button-containear {
          margin: 10px 0px;
        }
        .page-buttons-wrapper {
          margin: 5px 0px;
        }
        .button-containear {
          margin: 5px 0px;
        }
        .page-buttons-wrapper {
          margin: 5px 0px;
        }
        .page-filters__left-select-wrapper {
          margin: 5px 0px;
        }
        
        .standard-input-width {
          width: 58vw !important;
          margin: 5px;
        }
        .action-buttons-width {
          width: 35vw !important;
        }
        
        .page-table-header{
          display: none
        }
        .page-table-row{
          flex-direction: column;
          gap: 1vh;
          margin-bottom: 2vh
        }
        .page-table-desc-column{
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex: unset;
          text-align: end;
        }
        .category-analysis-mobile-header{
          display: flex;
          font-weight: 700;
          font-size: 1.2em
        }
        .page-table-container{display: none}
    }

@import '@/sass/commons/_variables';

.totalDiv {
    width: fit-content;
    color: white;
    height: 100%;
    background-color: $secondary-color;
    text-align: right;
    padding: 0.7rem 1.5rem !important;
}
</style>

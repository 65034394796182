<template>
    <div class="mobile-category-container">
        <h6 @click="getProdutosInternos(category)">
            <span v-if="!category.expanded" class="material-icons category-tab-colapsed-icon">arrow_forward_ios</span>
            <span v-else class="material-icons category-tab-expanded-icon">arrow_forward_ios</span>
            {{ category.cat_descricao }}
        </h6>
        <div class="mobile-table-row">
            <div class="unit-width">
                <h6 class="page-table-mobile-header">
                    Qtd.
                </h6>
                {{ category.quantidade }}
            </div>
            <div class="unit-width" style="text-align: center;">
                <h6 class="page-table-mobile-header">
                    Valor Total
                </h6>
                {{ formartNumber(category.valorTotal) }}
            </div>
            <div class="unit-width" style="text-align: end;">
                <h6 class="page-table-mobile-header">
                    %
                </h6>
                {{ category.percent }}%
            </div>
        </div>
        <div v-if="category.loading" >
            <cc-loader style="margin: 10px auto" />
        </div>
        <div v-if="category.expanded">
            <SubCategoryMobileRow v-for="(info, index ) in category.produtos" :key="`inf-line-${index}`"
                :produtoInt="info" :cat="category" />
        </div>

    </div>
</template>

<script>
import numeral from "numeral";
import * as CONSTANTS from "@/constants/constants";
import SubCategoryMobileRow from "./SubCategoryMobileRow.vue";

export default {
    name: 'CategoryMobileRow',
    props: {
        cat: Object
    },
    components: {
        SubCategoryMobileRow,
    },
    data() {
        return {
            category: this.cat
        }
    },
    methods: {
        formartNumber(n) {
            if (n) {
                return numeral(n.toString().replace(".", ",")).format("0,0.00");
            } else {
                return n;
            }
        },
        getProdutosInternos(product) {

            product.loading = true
            this.$forceUpdate();

            if (!product.produtos) {

                var requestUrl =
                    `${CONSTANTS.API_URL}/getprodutosrelatoriocliente?categoria=` +
                    product.cat_id +
                    "&groupBy=p.pro_id&imprimir=true&ordenar=valorTotal&tipoorder=desc";

                const dateIni = this.$store.state.dateIniRelatorio;
                const dateFim = this.$store.state.dateFimRelatorio;

                requestUrl +=
                    "&dateIni=" + dateIni +
                    "&dateFim=" + dateFim;

                axios.get(requestUrl, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                }).then(({ data }) => {
                    let lastPercent = 0;
                    product.produtos = data.produtos.map(itemInt => {
                        let newItemInt = itemInt;
                        newItemInt.percent = parseFloat(
                            (itemInt.valorTotal * 100) / product.valorTotal
                        ).toFixed(2);
                        newItemInt.percentAcumulate = parseFloat(
                            parseFloat(newItemInt.percent) + parseFloat(lastPercent)
                        ).toFixed(2);
                        lastPercent = newItemInt.percentAcumulate;
                        return newItemInt;
                    });
                    product.expanded = !product.expanded
                    this.$forceUpdate();
                    product.loading = false
                })
                    .catch(error => {

                        product.loading = false;
                        if (error.response.status === 401) {
                            if (error.response.data.mensagem == CONSTANTS.TOKEN_EXPIRADO) {
                                // location.href = CONSTANTS.ROUTE_LOGIN;
                                window.location.assign(CONSTANTS.ROUTE_LOGIN)

                            }
                        } else if (error.response.status === 500) {
                            if (error.response.data.mensagem) {
                                this.mensagemErro = error.response.data.mensagem;
                            } else {
                                this.mensagemErro = "Não foi possível abrir os dados gerenciais";
                            }
                        }
                        product.loading = false
                    });
            } else {
                product.expanded = !product.expanded
                product.loading = false
                this.$forceUpdate();
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.mobile-table-row {
    justify-content: space-between;
    display: flex;
    margin-top: 1.5vh;
}

.mobile-category-container {
    border-bottom: 1px solid lightgray;
    padding-bottom: 3vh;
    padding-top: 2vh;
}

.unit-width {
    width: 30%;
    font-size: 1.4em;
}

h6 {
    font-weight: bold;
    margin: 0;
}

.category-tab-colapsed-icon {
    font-size: 1em;
    margin-right: 1vw;
}

.category-tab-expanded-icon {
    color: var(--primary-color);
    font-size: 1em;
    rotate: 90deg;
    margin-right: 1vw;
}

.page-table-mobile-header {
    font-size: 1.5em;
    font-weight: normal;
}
</style>
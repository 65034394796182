<template>
    <div class="mobile-seller-container">
        <h6 @click="openItem(buyer)">
            <span v-if="!buyer.expanded" class="material-icons seller-tab-colapsed-icon">arrow_forward_ios</span>
            <span v-else class="material-icons seller-tab-expanded-icon">arrow_forward_ios</span>
            {{ buyer.usu_nome }}
        </h6>
        <div class="mobile-table-row">
            <div class="unit-width">
                <h6 class="page-table-mobile-header">
                    valor total
                </h6>
                {{ buyer.total_value | currency }}
            </div>
            <div class="unit-width" style="text-align: end;">
                <h6 class="page-table-mobile-header">
                    Percentual
                </h6>
                {{buyer.percent.toFixed(2)}}%
            </div>
        </div>
        
        <div v-if="buyer.loading" :key="'prod-loader-' + buyer.pro_id">
            <cc-loader style="margin: 10px auto" />
        </div>
        <div v-if="buyer.expanded">
            <SubBuyerMobileRow v-for="(info, index ) in buyer.stores" :key="`inf-line-${index}`"
                 :store="info" :buyer="buyer" />
        </div>

    </div>
</template>

<script>
import numeral from "numeral";
import SubBuyerMobileRow from "./SubBuyerMobileRow.vue";
import * as CONSTANTS from "@/constants/constants";
import ClientServiceV3 from "@/services/v3/ClientService.js"

export default {
    name: 'BuyerMobileRow',
    props: {
        buyer: Object
    },
    components: {
        SubBuyerMobileRow,
    },
    data() {
        return {
            svc: new ClientServiceV3(),
        }
    },
    methods: {
        formartNumber(n) {
            if (n) {
                return numeral(n.toString().replace(".", ",")).format("0,0.00");
            } else {
                return n;
            }
        },
        getPercentual(produto) {
            return (
                parseFloat((produto.valorTotal * 100) / this.totalPedido).toFixed(2) +
                "%"
            );
        },
        openItem(i) {
            if (i.stores) {
                i.expanded = !i.expanded
                this.$forceUpdate()
                return
            }
            i.loading = true
            this.$forceUpdate()
            this.svc.getBuyer(i.usu_id, this.$store.state.dateIniRelatorio, this.$store.state.dateFimRelatorio).then(resp => {
                i.stores = resp.data
                i.expanded = true
                i.loading = false
                this.$forceUpdate()
            })
        }
    },
}
</script>

<style lang="scss" scoped>
.mobile-table-row {
    justify-content: space-between;
    display: flex;
    margin-top: 1.5vh;
}

.mobile-seller-container {
    border-bottom: 1px solid lightgray;
    padding-bottom: 3vh;
    padding-top: 2vh;
}

.unit-width {
    width: 30%;
    font-size: 1.4em;
}

h6 {
    font-weight: bold;
    margin: 0;
}

.seller-tab-colapsed-icon {
    font-size: 1em;
    margin-right: 1vw;
}

.seller-tab-expanded-icon {
    color: var(--primary-color);
    font-size: 1em;
    rotate: 90deg;
    margin-right: 1vw;
}

.page-table-mobile-header {
    font-size: 1.5em;
    font-weight: normal;
}
</style>
<template>
    <div class="mobile-sub-product-container">
        <h6>
            {{ product.marca }}
        </h6>
        <div class="mobile-table-row">
            <div class="unit-width">
                <h6 class="page-table-mobile-header">
                    Fornecedor
                </h6>
                {{ product.nomeFornecedor }}
            </div>
            <div class="unit-width" style="text-align: center;">
                <h6 class="page-table-mobile-header">
                    N. Requisitantes
                </h6>
                {{ product.requisitantes }}
            </div>
            <div class="unit-width" style="text-align: end;">
                <h6 class="page-table-mobile-header">
                    Data Compra
                </h6>
                {{ product.data_geracao | moment("DD/MM/YYYY") }}
            </div>
        </div>
        <div class="mobile-table-row">
            <div class="unit-width">
                <h6 class="page-table-mobile-header">
                    Número Cotação
                </h6>
                {{ product.id_cotacao }}
            </div>
            
            <div class="unit-width" style="text-align: end;">
                <h6 class="page-table-mobile-header">
                    Quantidade
                </h6>
                {{ product.quantidade }}
            </div>
        </div>
        <div class="mobile-table-row">
            <div class="unit-width">
                <h6 class="page-table-mobile-header">
                    Valor Unit
                </h6>
                {{
                    product.cli_novoPedido ==
                        1 &&
                        product.cli_tipoPedido ==
                        1
                        ? formartNumber(
                            product.valorTotal /
                            product.quantidade
                        )
                        : formartNumber(
                            product.valorTotal /
                            product.quantidade
                        )
                }}
            </div>
            <div class="unit-width" style="text-align: end;">
                <h6 class="page-table-mobile-header">
                    Valor Total
                </h6>
                {{ formartNumber(product.valorTotal) }}
            </div>
        </div>
    </div>
</template>

<script>
import numeral from "numeral";

export default {
    name: 'SubProductMobileRow',
    props: {
        produto: Object
    },
    data() {
        return {
            product: this.produto
        }
    },
    methods: {
        formartNumber(n) {
            if (n) {
                return numeral(n.toString().replace(".", ",")).format("0,0.00");
            } else {
                return n;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.mobile-table-row {
    justify-content: space-between;
    display: flex;
}

.mobile-sub-product-container {
    border-top: 1px solid lightgray;
    padding-bottom: 3vh;
    padding-top: 2vh;
}

.unit-width {
    width: 30%;
    font-size: 1.4em;
}

h6 {
    font-weight: bold;
    margin: 0;
}

.product-tab-colapsed-icon {
    font-size: 1em;
    margin-right: 1vw;
}

.product-tab-expanded-icon {
    color: var(--primary-color);
    font-size: 1em;
    rotate: 90deg;
    margin-right: 1vw;
}

.page-table-mobile-header {
    font-size: 1.5em;
    font-weight: normal;
}
</style>
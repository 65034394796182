<template>
    <!-- <div class="tab-pane fade show active in" id="quotes" role="tabpanel" aria-labelledby="quotes-tab">
        <form @submit.prevent="reload_requests">
            <div class="container-fluid">
                <div class="row">
                    <cc-mobile-note />
                    <div class="col-4">
                        <label for="" class="label-control">Filtrar por numero da cotação</label>
                        <cc-search v-model.number="filters.request_id" type="number" @search="reload_requests"></cc-search>
                    </div>
                    <div class="col-3">
                        <label for="" class="label-control">Status</label>
                        <cc-select-v2 :multiple="true" :tracker="'value'" :text="'name'" :options="statuses" v-model="filters.statuses" @onselect="update_statuses_filters"></cc-select-v2>
                    </div>
                </div>
            </div>
        </form>

        <div class="container-fluid  p-4 mb-5 mt-4">

            <cc-loader v-show="loading && page == 1"/>

            <no-data-available v-if="list.length == 0 && !loading" :text="'Sua lista de cotações está vazia'" />

            <div v-if="list.length > 0" class="table-responsive m-0" :class="{ hidden : loading && page == 1 }">
                <table class="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Data Exp</th>
                        <th>Núm. Cotação</th>
                        <th>Produtos</th>
                        <th>Concorrentes</th>
                        <th>Empresa</th>
                        <th>Vencedores</th>
                        <th>Status</th>
                        <th style="width:10rem">Pedidos</th>
                        <th class="text-left pl-4">Ações</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in list" v-bind:key="item.numeroCotacao + Math.random()">
                        <td>
                            <i class="fas fa-info-circle request-info" @mouseover="show_info(item, true)" @mouseout="show_info(item, false)"></i>
                            <cc-popover :visible="!!item.show_info">
                                <div>
                                    <p><strong>Observação:</strong> {{ item.cot_observacao }}</p>
                                    <p class="mb-0">
                                        <strong class="obs-key">Condição de Pgto:</strong> {{ item.ttp_nome }}
                                    </p>
                                    <p v-if="item.creation">
                                        <strong class="obs-key">Enviado por: </strong>
                                        <span class="text-capitalize">{{ item.creation.owner.name }}</span>
                                        em <strong>{{ item.creation.created_at | formatDate }}</strong>
                                    </p>
                                </div>
                            </cc-popover>
                        </td>
                        <td>{{ item.dataExpiracao | formatDate }}</td>
                        <td>{{item.numeroCotacao}}</td>
                        <td>
                            <span class="list-products" @click="can('REQUEST_PRODUCT', 'READ') && toggle_products(item.numeroCotacao)">
                                {{item.totalProdutos}}
                            </span>
                        </td>
                        <td>
                            <span class="list-products" @click="can('REQUEST_PRODUCT', 'READ') && toggle_providers(item.numeroCotacao)">
                                {{item.totalConcorrentes}}
                            </span>
                        </td>
                        <td class="company-name">{{item.nomeCotacao}}</td>
                        <td class="show-quotation">
                            <a class="show-winners"
                                :class="{
                                    'disabled' : !can('REQUEST_PRODUCT', 'READ') ||
                                                 item.status_nome == 'NÃO DIGITADA' ||
                                                 (attendance && attendance.status == 'SUSPENDED')
                                }"
                                v-on:click="
                                    (!attendance || attendance.status != 'SUSPENDED') &&
                                    can('REQUEST_PRODUCT', 'READ') &&
                                    item.status_nome !== 'NÃO DIGITADA' && goToWinners( item )
                                ">
                                <i class="fas fa-shopping-cart fa-flip-horizontal show-winners-icon" aria-hidden="true"></i>
                            </a>
                        </td>
                        <td>
                            <cc-status :status="item.status_nome"></cc-status>
                        </td>
                        <td>


                            <div class="d-flex p-0" :title="item.qtd_pedidos + ' Ordens de Compra'">
                                <span class="summary-label">{{ item.qtd_pedidos }}</span>
                                <div class="progress w-100 mt-0" style="height: 11px;">
                                    <div class="progress-bar-striped bg-info mt-0" role="progressbar" :style="{ width: item.status_nome == 'PEDIDO GERADO' ? `100%` : `0%` }" :aria-valuenow="item.status_nome == 'PEDIDO GERADO' ? 100 : 0" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="d-flex p-0" :title="item.confirmed_orders + ' pedidos confirmados'">
                                <span class="summary-label">{{ item.confirmed_orders }}</span>
                                <div class="progress w-100 mt-0" style="height: 11px;">
                                    <div class="progress-bar-striped bg-warning mt-0" role="progressbar" :style="{ width: `${calculate_perc(item, item.confirmed_orders)}%` }" :aria-valuenow="calculate_perc(item, item.confirmed_orders)" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div class="d-flex p-0" :title="item.delivered_orders + ' pedidos entregues'">
                                <span class="summary-label">{{ item.delivered_orders }}</span>
                                <div class="progress w-100 mt-0" style="height: 11px;">
                                    <div class="progress-bar-striped bg-success mt-0" role="progressbar" :style="{ width: `${calculate_perc(item, item.delivered_orders)}%` }" :aria-valuenow="calculate_perc(item, item.delivered_orders)" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>



                        </td>
                        <td class="actions text-left">
                            <i class="fa fa-clock-o"
                                title="Prorrogar data de expiração"
                                v-if="item.status_nome != 'PEDIDO GERADO'"
                                :class="{ 'disabled' : (
                                        item.status_nome == 'PEDIDO GERADO' ||
                                        item.status_nome == 'CONGELADA' ||
                                        !can('REQUEST', 'UPDATE')
                                    )
                                }"
                                aria-hidden="true"
                                @click="!(item.status_nome == 'PEDIDO GERADO' ||
                                          item.status_nome == 'CONGELADA') &&
                                          can('REQUEST', 'UPDATE') &&
                                          changeDeadline(item)"></i>
                            <i style="cursor: pointer;padding-left: 4px;"
                                v-if="item.status_nome == 'PEDIDO GERADO' && can('REQUEST_ORDER', 'READ')"
                                class="fas fa-hand-holding-usd quotation-orders-sent"
                                aria-hidden="true"
                                title="Ver Pedidos"
                                @click="show_orders(item)"></i>
                            <i class="fas fa-users" title="Fornecedores"
                                @click="open_sellers(item)"
                                :class="{ 'disabled' : (
                                        item.status_nome == 'PEDIDO GERADO' ||
                                        item.status_nome == 'CONGELADA' ||
                                        !can('REQUEST', 'READ_SELLERS')
                                    )
                                }"></i>
                            <i class="fas fa-bullhorn"
                                title="Reenviar notifição as vendedores"
                                @click="can('REQUEST', 'SEND_NOTIFICATION') && resend_notifications(item)"
                                :class="{ 'disabled' : !can('REQUEST', 'SEND_NOTIFICATION') }"
                                v-if="item.status_nome == 'NÃO DIGITADA' || item.status_nome == 'DIGITADA'"></i>
                            <i class="far fa-folder-open"
                                v-if="item.status_nome != 'PEDIDO GERADO' && can('REQUEST', 'MANAGE_OFFER')"
                                title="Gerenciar produtos"
                                @click="request=item;show_manage_request_products_by_seller = true;"></i>
                            <i class="fa fa-bar-chart"
                                style="cursor: pointer;padding-left: 4px;"
                                v-if="item.status_nome == 'PEDIDO GERADO' && can('REQUEST', 'SEE_REPORT')"
                                aria-hidden="true"
                                title="Ver relatório"
                                @click="show_report(item)" ></i>
                            <i class="fa fa-refresh action-replicate"
                                title="Replicar Solicitações"
                                :class="{ 'disabled' : !can('REQUEST', 'CREATE') }"
                                @click="can('REQUEST', 'CREATE') && replicate_quotation(item)" ></i>
                            <i class="fas fa-recycle action-replicate"
                                v-if="item.status_nome == 'PEDIDO GERADO' && can('REQUEST', 'ACCESS_V4')"
                                title="Gerar Novos Pedidos"
                                @click="show_request(item)"></i>
                        </td>
                    </tr>
                    <tr :class="{ hidden : !(loading && page > 1) }">
                        <td colspan="10">
                            <cc-loader :show_txt="false" />
                        </td>
                    </tr>
                    <tr v-if="showPagination">
                        <td colspan="10" style="height:6rem">
                            <a v-on:click="next_page" class="load-more shadow">MOSTRAR MAIS</a>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
        </div>
        <RequestProvidersModal :request_id="request_id" v-if="show_request_providers" @close="show_request_providers=false" />
        <ModalQuotationProductsProducts id="modal" :quotation="request_id" v-if="show_request_products" @close="show_request_products=false" />
        <ChangeQuotationDeadlineModal id="modal" :request="request" v-if="show_deadline_modal" @close="show_deadline_modal = false;" @reload="reload_requests" />
        <ManageRequestSellersModal id="modal" :request="request" v-if="show_manage_request_sellers" @close="show_manage_request_sellers = false;reload_requests()" />
        <ManageRequestProductsBySellersModal id="modal" :request="request" v-if="show_manage_request_products_by_seller" @close="show_manage_request_products_by_seller = false;reload_requests()" />
    </div> -->
    <div class="page">
        <cc-loader-full v-if="loading"/>
        <div class="page-route-select">
            <router-link to="/cliente/monitores/cotacoes" class="page-route-select-text">
                Home
            </router-link>
            <span class="material-icons page-route-select-icon">play_arrow</span>
            <div class="page-route-select-text">
                Monitor de Cotação
            </div>
        </div>
        <div class="page-title">
            Monitor de Cotação
        </div>
        <div class="page-filters-containear">
            <MobileNote class="desktop-only"/>

            <div class="page-filters-inputs">
                <StandardInputAuto class="desktop" :action="setQuoteFilter" :title="'Número da Cotação'" :width="'40%'" :type="'text'"/>
                <StandardInput class="desktop" :placeholder="'Selecione o status'" :action="setStatusFilter" :title="'Status'" :width="'25%'" :list="status" :type="'select'"/>
                <StandardInput  class="desktop" :placeholder=" myQuoteOnly ? user.user_name :'Selecione o comprador'" :action="setBuyerFilter" :title="'Comprador'" :width="'25%'" :list="buyers" :type="'select'"/>

                <StandardInputAuto v-if="showMobileFilter" class="mobile" :action="setQuoteFilter" :title="'Número da Cotação'" :width="'100%'" :type="'text'"/>
                <StandardInput v-if="showMobileFilter" class="mobile" :action="setStatusFilter" :placeholder="'Selecione o status'" :title="'Status'" :width="'100%'" :list="status" :type="'select'"/>
                <StandardInput v-if="showMobileFilter"  class="mobile" :placeholder=" myQuoteOnly ? user.user_name :'Selecione o comprador'" :action="setBuyerFilter" :title="'Comprador'" :width="'100%'" :list="buyers" :type="'select'"/>
                <StandardButton v-if="!showMobileFilter" class="mobile show-filter-mobile" :action="() => showMobileFilter = true" iconName="tune" text="Mostrar filtros" />
                <StandardButton v-if="showMobileFilter" style="margin-top: 2vh;" class="mobile show-filter-mobile" :action="() => showMobileFilter = false" iconName="close_fullscreen" text="Esconder filtros" />
            </div>
        </div>
        <div class="selected-filters-containear">
            <div class="selected-value" v-if="quoteNumber">
                <div>{{quoteNumber}}</div>
                <span class="material-icons close-select-value" v-on:click="cleanQuote()">close</span>
            </div>
            <div class="selected-value" v-if="statusFilter">
                <div>{{getStatusText(statusFilter)}}</div>
                <span class="material-icons close-select-value" v-on:click="cleanStatus()">close</span>
            </div>
        </div>
        <div class="table-placeholder">
            <div class="pagle-table-size " v-if="!loading">
                <div class="page-table-header desktop">
                    <div class="page-table-colum-width page-table-header-text">Data Exp</div>
                    <!-- <div class="page-table-colum-width-number page-table-header-text"></div> -->
                    <div class="page-table-colum-width-company page-table-header-text">Empresa</div>
                    <div class="page-table-colum-width page-table-header-text">Status</div>
                    <div class="page-table-colum-width page-table-header-text">Pedidos</div>
                    <div class="page-table-colum-width-products page-table-header-text">Produtos</div>
                    <div class="page-table-colum-width-competitors page-table-header-text">Concorrentes</div>
                    <div class="page-table-colum-width page-table-header-text">Total</div>
                    <div class="page-table-colum-width-actions page-table-header-text">Ações</div>
                    <div class="page-table-colum-width page-table-header-text">Vencedores</div>
                    <div class="page-table-colum-width page-table-header-text">Observação</div>
                </div>
                <div class="page-table-row" :class="getSpecialBackground(index)" v-for="(item, index) in list" :key="index">

                    <!-- mobile -->
                    <div class="page-table-colum-width-company colum-divider mobile request-tab-mobile-header" style="width: 100%; font-size: 1.2em; margin-bottom: 0;">
                        {{item.nomeCotacao}}
                    </div>

                    <!-- mobile -->
                    <div class="mobile-cot-row mobile">
                        <div class="request-tab-mobile-header">Nº Cotação</div>
                        {{item.numeroCotacao}}
                    </div>

                    <!-- mobile -->
                    <div  class="mobile page-table-mobile-row">
                        <div class="page-table-colum-width colum-divider mobile-colum-left" :style="getStatusColor(item.status_nome)">

                            {{capitalize(item.status_nome)}}
                        </div>

                        <div class="page-table-colum-width colum-divider">
                            <div class="request-tab-mobile-header">Data Exp</div>
                            {{item.dataExpiracao | formatDate}}
                        </div>
                    </div>

                    <!-- desktop -->
                    <div class="page-table-colum-width colum-divider desktop">
                        <div class="request-tab-mobile-header">Data Exp</div>
                        {{item.dataExpiracao | formatDate}}<br>
                        #{{item.numeroCotacao}}
                    </div>


                    <!-- mobile -->
                    <div class="mobile page-table-mobile-row" style="justify-content: space-between;">
                        <div style="width: 45%;">
                            <div style="text-align: center;" class="request-tab-mobile-header">Prod.</div>
                            <div style="display: flex; justify-content: space-evenly; gap: 4vw;">
                                <div title="Produtos da cotação" class="product-info-item">{{ item.totalProdutos || '-' }}</div>
                                <div title="Produtos digitados" class="product-info-item">{{ item.distinct_products_groups_offered_count || '--' }}</div>
                                <div title="Produtos comprados" class="product-info-item">{{ item.distinct_purchased_products_groups_count || '--' }}</div>
                            </div>
                            
                        </div>
                        <div style="width: 45%;">
                            <div style="text-align: center;" class="request-tab-mobile-header">Conc.</div>
                            <div style="display: flex; justify-content: space-evenly; gap: 4vw;">
                                <div title="Concorentes" class="sellers-info-item">{{ item.totalConcorrentes || '--' }}</div>
                                <div title="Concorentes digitados" class="sellers-info-item">{{ item.distinct_sellers_offered_count || '--' }}</div>
                            </div>
                            
                        </div>
                    </div>
                    <!-- mobile -->
                    <div class="mobile page-table-mobile-row" style="justify-content: space-between;">
                        <div class=" page-table-colum-pogress" style="width: 30%;">
                            <div class="request-tab-mobile-header">Total</div>
                            <p :style="getStatusColor(item.status_nome)">{{ item.total_ordered_amount != '0.00' ? 'R$'+item.total_ordered_amount : '--' }}</p>
                        </div>
                        <div class=" page-table-colum-pogress" style="width: 70%;">
                            <div class="request-tab-mobile-header">Pedidos</div>
                            <vue-ellipse-progress :title="item.qtd_pedidos + ' Ordens de Compra'" :progress="getQuantityNumbers(item)" :size="35" color="#17A2B8" :legend="false"><p slot="legend-caption" class="circle-content">{{item.qtd_pedidos}}</p></vue-ellipse-progress>
                            <vue-ellipse-progress :title="item.confirmed_orders + ' pedidos confirmados'" :progress="calculate_perc(item, item.confirmed_orders)" :size="35" color="#FFCA2B" :legend="false"><p slot="legend-caption" class="circle-content">{{item.confirmed_orders}}</p></vue-ellipse-progress>
                            <vue-ellipse-progress :title="item.delivered_orders + ' pedidos entregues'" :progress="calculate_perc(item, item.delivered_orders)" :size="35" color="#30AA4C" :legend="false"><p slot="legend-caption" class="circle-content">{{item.delivered_orders}}</p></vue-ellipse-progress>
                        </div>
                        
                    </div>

                    <!-- desktop -->
                    <div class="page-table-colum-width-company colum-divider desktop">
                        {{item.nomeCotacao}} 
                    </div>
                    <!-- desktop -->
                    <div class="page-table-colum-width colum-divider desktop" :style="getStatusColor(item.status_nome)">
                        {{capitalize(item.status_nome)}}
                    </div>
                    <!-- desktop -->
                    <div class="page-table-colum-width colum-divider page-table-colum-pogress desktop">
                        <div class="request-tab-mobile-header">Pedidos</div>
                        <vue-ellipse-progress :title="item.qtd_pedidos + ' Ordens de Compra'" :progress="getQuantityNumbers(item)" :size="35" color="#17A2B8" :legend="false"><p slot="legend-caption" class="circle-content">{{item.qtd_pedidos}}</p></vue-ellipse-progress>
                        <vue-ellipse-progress :title="item.confirmed_orders + ' pedidos confirmados'" :progress="calculate_perc(item, item.confirmed_orders)" :size="35" color="#FFCA2B" :legend="false"><p slot="legend-caption" class="circle-content">{{item.confirmed_orders}}</p></vue-ellipse-progress>
                        <vue-ellipse-progress :title="item.delivered_orders + ' pedidos entregues'" :progress="calculate_perc(item, item.delivered_orders)" :size="35" color="#30AA4C" :legend="false"><p slot="legend-caption" class="circle-content">{{item.delivered_orders}}</p></vue-ellipse-progress>
                    </div>


                    <!-- desktop -->
                    <div class="page-table-colum-width-products colum-divider desktop" style="cursor: pointer;" @click="can('REQUEST_PRODUCT', 'READ') && toggle_products(item.numeroCotacao); showRequestedProductsModal = true">
                        <div title="Produtos da cotação" class="product-info-item">{{ item.totalProdutos || '-' }}</div>
                        <div title="Produtos digitados" class="product-info-item">{{ item.distinct_products_groups_offered_count || '--' }}</div>
                        <div title="Produtos comprados" class="product-info-item">{{ item.distinct_purchased_products_groups_count || '--' }}</div>
                    </div>
                    <!-- desktop -->
                    <div @click="can('REQUEST_PRODUCT', 'READ') && toggle_providers(item);" style="cursor: pointer;" class="page-table-colum-width-competitors colum-divider desktop">
                        <div title="Concorentes" class="sellers-info-item">{{ item.totalConcorrentes || '--' }}</div>
                        <div title="Concorentes digitados" class="sellers-info-item">{{ item.distinct_sellers_offered_count || '--' }}</div>
                    </div>

                    <!-- desktop -->
                    <div style="text-align: end; padding-right: 1.5%;"  class="page-table-colum-width colum-divider desktop" :style="getStatusColor(item.status_nome)">
                        {{ formatPrice(item.total_ordered_amount) }}
                    </div>

                    <!-- mobile -->
                    <div class="mobile page-table-mobile-row">
                        <div class="page-table-colum-width-actions mobile-action-row ">
                            <div class="request-tab-mobile-header">Ações</div>
                                <div class="table-actions-colum" v-if="item.status_nome == 'PEDIDO GERADO'">
                                    <div style="flex: 1"></div>
                                    <div style="flex: 1">
                                        <i title="Ver Pedidos" @click="show_orders(item)" class="fas fa-hand-holding-usd table-action-icon" style="color: #30AA4C"></i>
                                    </div>
                                    <div style="flex: 1">
                                        <span title="Ver relatório" @click="show_report(item)" class="material-icons table-action-icon" style="color: var(--primary-color)">trending_up</span>
                                    </div>
                                    <div style="flex: 1">
                                        <span title="Replicar Solicitações" @click="can('REQUEST', 'CREATE') && replicate_quotation(item)" class="material-icons table-action-icon" style="color: #17A2B8">cached</span>
                                    </div>
                                    <div style="flex: 1">
                                        <i v-if="!item.cot_prorrogation" title="Gerar Novos Pedidos" @click="show_request(item)" class="fas fa-recycle table-action-icon" style="color: var(--primary-color)"></i>
                                    </div>
                                    <div class="page-table-colum-width table-info-icon">
                                        <span @mouseover="show_info(item, true)" @mouseout="show_info(item, false)" class="material-icons">info</span>
                                    </div>
                                    <div class="table-winners-button">
                                        <span v-on:click=" (!attendance || attendance.status != 'SUSPENDED') && can('REQUEST_PRODUCT', 'READ') && goToWinners( item )" class="material-icons">shopping_cart</span>
                                    </div>
                                </div>
                                <div class="table-actions-colum" v-else-if="item.status_nome == 'DIGITADA' || item.status_nome == 'NÃO DIGITADA'">
                                    <div style="flex: 1">
                                        <span title="Prorrogar data de expiração" @click="!(item.status_nome == 'PEDIDO GERADO' || item.status_nome == 'CONGELADA') && can('REQUEST', 'UPDATE') && changeDeadline(item)" class="material-icons table-action-icon" style="color: #30AA4C">schedule</span>
                                    </div>
                                    <div style="flex: 1">
                                        <!-- aqui -->
                                        <i title="Fornecedores" @click="open_sellers(item); showManageSellersModal = true" class="fas fa-users table-action-icon" style="color: var(--primary-color)"></i>
                                    </div>
                                    <div style="flex: 1">
                                        <span title="Reenviar notifição as vendedores" @click="can('REQUEST', 'SEND_NOTIFICATION') && resend_notifications(item)" class="material-icons table-action-icon" style="color: var(--primary-color)">campaign</span>
                                    </div>
                                    <div style="flex: 1">
                                        <span title="Replicar Solicitações" @click="can('REQUEST', 'CREATE') && replicate_quotation(item)" class="material-icons table-action-icon" style="color: #17A2B8">cached</span>
                                    </div>
                                    <div style="flex: 1">
                                        <span title="Gerenciar produtos" @click="sendSuplierPage(item)" class="material-icons table-action-icon" style="color: var(--primary-color)">folder</span>
                                    </div>
                                    <div class="page-table-colum-width table-info-icon">
                                        <span @mouseover="show_info(item, true)" @mouseout="show_info(item, false)" class="material-icons">info</span>
                                    </div>
                                    <div class="table-winners-button">
                                        <span v-on:click=" (!attendance || attendance.status != 'SUSPENDED') && can('REQUEST_PRODUCT', 'READ') && goToWinners( item )" class="material-icons">shopping_cart</span>
                                    </div>
                                </div>
                                <div class="table-actions-colum" v-else>
                                    <div style="flex: 1"></div>
                                    <div style="flex: 1"></div>
                                    <div style="flex: 1"></div>
                                    <div style="flex: 1">
                                        <span title="Replicar Solicitações" @click="can('REQUEST', 'CREATE') && replicate_quotation(item)" class="material-icons table-action-icon" style="color: #17A2B8">cached</span>
                                    </div>
                                    <div style="flex: 1">
                                        <span title="Gerenciar produtos" @click="sendSuplierPage(item)" class="material-icons table-action-icon" style="color: var(--primary-color)">folder</span>
                                    </div>
                                    <div class="page-table-colum-width table-info-icon">
                                        <span @mouseover="show_info(item, true)" @mouseout="show_info(item, false)" class="material-icons">info</span>
                                    </div>
                                    <div class="table-winners-button">
                                        <span v-on:click=" (!attendance || attendance.status != 'SUSPENDED') && can('REQUEST_PRODUCT', 'READ') && goToWinners( item )" class="material-icons">shopping_cart</span>
                                    </div>
                                </div>


                        </div>

                    </div>

                    <!-- desktop -->
                    <div class="page-table-colum-width-actions colum-divider desktop">
                        <div>
                            <div class="table-actions-colum" v-if="item.status_nome == 'PEDIDO GERADO'">
                                <div style="flex: 1"></div>
                                <div style="flex: 1">
                                    <i title="Ver Pedidos" @click="show_orders(item)" class="fas fa-hand-holding-usd table-action-icon" style="color: #30AA4C"></i>
                                </div>
                                <div style="flex: 1">
                                    <span title="Ver relatório" @click="show_report(item)" class="material-icons table-action-icon" style="color: var(--primary-color)">trending_up</span>
                                </div>
                                <div style="flex: 1">
                                    <span title="Replicar Solicitações" @click="can('REQUEST', 'CREATE') && replicate_quotation(item)" class="material-icons table-action-icon" style="color: #17A2B8">cached</span>
                                </div>
                                <div style="flex: 1">
                                    <i v-if="!item.cot_prorrogation" title="Gerar Novos Pedidos" @click="show_request(item)" class="fas fa-recycle table-action-icon" style="color: var(--primary-color)"></i>
                                </div>
                            </div>
                            <div class="table-actions-colum" v-else-if="item.status_nome == 'DIGITADA' || item.status_nome == 'NÃO DIGITADA'">
                                <div style="flex: 1">
                                    <span title="Prorrogar data de expiração" @click="!(item.status_nome == 'PEDIDO GERADO' || item.status_nome == 'CONGELADA') && can('REQUEST', 'UPDATE') && changeDeadline(item)" class="material-icons table-action-icon" style="color: #30AA4C">schedule</span>
                                </div>
                                <div style="flex: 1">
                                    <!-- aqui -->
                                    <i title="Fornecedores" @click="open_sellers(item); showManageSellersModal = true" class="fas fa-users table-action-icon" style="color: var(--primary-color)"></i>
                                </div>
                                <div style="flex: 1">
                                    <span title="Reenviar notifição as vendedores" @click="can('REQUEST', 'SEND_NOTIFICATION') && resend_notifications(item)" class="material-icons table-action-icon" style="color: var(--primary-color)">campaign</span>
                                </div>
                                <div style="flex: 1">
                                    <span title="Replicar Solicitações" @click="can('REQUEST', 'CREATE') && replicate_quotation(item)" class="material-icons table-action-icon" style="color: #17A2B8">cached</span>
                                </div>
                                <div style="flex: 1">
                                    <span title="Gerenciar produtos" @click="sendSuplierPage(item)" class="material-icons table-action-icon" style="color: var(--primary-color)">folder</span>
                                </div>
                            </div>
                            <div class="table-actions-colum" v-else>
                                <div style="flex: 1"></div>
                                <div style="flex: 1"></div>
                                <div style="flex: 1"></div>
                                <div style="flex: 1">
                                    <span title="Replicar Solicitações" @click="can('REQUEST', 'CREATE') && replicate_quotation(item)" class="material-icons table-action-icon" style="color: #17A2B8">cached</span>
                                </div>
                                <div style="flex: 1">
                                    <span title="Gerenciar produtos" @click="sendSuplierPage(item)" class="material-icons table-action-icon" style="color: var(--primary-color)">folder</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- desktop -->
                    <div class="page-table-colum-width colum-divider desktop">
                        <div class="table-winners-button">
                            <span v-on:click=" (!attendance || attendance.status != 'SUSPENDED') && can('REQUEST_PRODUCT', 'READ') && goToWinners( item )" class="material-icons">shopping_cart</span>
                        </div>
                    </div>
                    <div class="page-table-colum-width table-info-icon desktop">
                        <span @mouseover="show_info(item, true)" @mouseout="show_info(item, false)" class="material-icons">info</span>
                    </div>
                    <cc-popover :visible="!!item.show_info">
                        <div>
                            <p><strong>Observação:</strong> {{ item.cot_observacao }}</p>
                            <p class="mb-0">
                                <strong class="obs-key">Condição de Pgto:</strong> {{ item.ttp_nome }}
                            </p>
                            <p v-if="item.creation">
                                <strong class="obs-key">Enviado por: </strong>
                                <span class="text-capitalize">{{ item.creation.owner.name }}</span>
                                em <strong>{{ item.creation.created_at | formatDate }}</strong>
                            </p>
                        </div>
                    </cc-popover>
                </div>
            </div>
        </div>
        <tr class="seemore-button-containear" >
            <td class="text-center" colspan="6">
                <Paginacao
                    classes="orange justify-end"
                    :items_by_page_value="30"
                    :total="totalItems"
                    :page_limit = '30'
                    :page="1"
                    v-on:change="updatePage($event)"
                ></Paginacao>
            </td>
        </tr>



        <RequestProvidersModal :requestSelected="selectRequest"
                               v-if="show_request_providers "
                               :closeDialog="() => show_request_providers = false"
                                />
        <ModalQuotationProductsProducts id="modal"
                                        :quotation="request_id"
                                        v-if="show_request_products && showRequestedProductsModal"
                                        :closeDialog="closeRequestedProductsModal"
                                        @close="show_request_products=false" />
        <ChangeQuotationDeadlineModal id="modal" :request="request" v-if="show_deadline_modal" @close="show_deadline_modal = false;" @reload="reload_requests" />
        <ManageRequestSellersModal id="modal"
                                   :request="request"
                                   v-if="show_manage_request_sellers && showManageSellersModal"
                                   :closeDialog="closeManageSellersModal"
                                   @close="show_manage_request_sellers = false;reload_requests()" />
        <!-- <ManageRequestProductsBySellersModal id="modal" :request="request" v-if="show_manage_request_products_by_seller" @close="show_manage_request_products_by_seller = false;reload_requests()" /> -->
        <PendecieNotification v-if="showPendencieNotification" :title="pendecieTitle" :hideButton="hidePendeciButton" :close="() => showPendencieNotification = false" :text="pendencieText" :text2="pendencieText2"/>
    </div>
</template>

<script>
import RequestProvidersModal from '@/modals/request-providers/request-providers.modal'
import ModalQuotationProductsProducts from "@/modals/requested-products/requested-products.modal";
import ChangeQuotationDeadlineModal from "@/modals/requests/deadline/request-deadline.modal";
import ManageRequestSellersModal from "@/modals/requests/manage-sellers/manage-request-sellers.modal";
import ManageRequestProductsBySellersModal from "@/modals/requests/products-by-seller/manage-request-products-by-seller.modal";
import QuotationService from '../../../services/QuotationService';
import RequestService from '@/services/v2/requests.service'
import EventService from '@/services/v3/event.service'
import ErrorHandlerService from "@/services/ErrorHandlerService";
import Request from "@/models/request.model";
import { mapGetters, mapState } from 'vuex';
import { requestsMixin } from '@/mixins/requests.mixing'
import { perm_mixin } from '@/mixins/permission.mixin'
import { loaderMixin } from '@/mixins/sweet-loader.mixin'
import Paginacao from "@/components/cliente/base-components/Pagination";
import StandardInput from '@/components/ui/inputs/StandardInput.vue'
import StandardInputAuto from '@/components/ui/inputs/StandardInputAuto.vue'
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";
import MobileNote from '@/components/notes/mobile-promotion.noteV2';
import StorageService from '@/services/TokenService';
import PendecieNotification from '@/components/notifications/PendecieNotification.vue';


export default {
    components: {
        RequestProvidersModal,
        ModalQuotationProductsProducts,
        ChangeQuotationDeadlineModal,
        ManageRequestSellersModal,
        Paginacao,
        StandardInput,
        StandardInputAuto,
        StandardButton,
        MobileNote,
        PendecieNotification
    },
    mixins: [ requestsMixin, perm_mixin, loaderMixin ],
    data() {
        return {
            loading: true,
            show_request_products: false,
            show_request_providers: false,
            show_deadline_modal: false,
            show_manage_request_sellers: false,
            show_manage_request_products_by_seller: false,
            showRequestedProductsModal: true,
            myQuoteOnly: false,
            showManageSellersModal: true,
            request_id: null,
            selectRequest: undefined,
            request: {},
            list: [],
            page: 1,
            page_size: 30,
            service: new QuotationService(),
            hidePendeciButton: false,
            svc: new RequestService(),
            evt_svc: new EventService(),
            storage_svc: new StorageService(),
            showMobileFilter: false,
            showPagination: false,
            status_options: [
                {
                    name: "Todos",
                    value: ""
                }
            ],
            filters: {
                statuses: [],
                request_id: null
            },
            statuses_filters: [],

            pageTab: 'number',
            totalItems: undefined,
            quoteNumber: '',
            statusFilter: '',
            showPendencieNotification: false,
            pendecieTitle: '',
            pendencieText: undefined,
            pendencieText2: undefined,
            status: [
                {
                    text: 'TODAS',
                    value: null
                },
                {
                    text: 'CONGELADO',
                    value: '10'
                },
                {
                    text: 'EM DIGITAÇÃO',
                    value: '1'
                },
                {
                    text: 'NÃO DIGITADA',
                    value: '7'
                },
                {
                    text: 'PEDIDO GERADO',
                    value: '4'
                },
            ],
            buyers: []
        }
    },
    methods: {
        sendSuplierPage (item) {
            this.$router.push('/cliente/fornecedor')
            this.$store.commit('setClientSuplier', item)
        },
        closeRequestedProductsModal: function() {
            this.showRequestedProductsModal = false;
        },
        closeManageSellersModal: function() {
            this.showManageSellersModal = false;
        },
        getSpecialBackground (index) {
            if (index % 2 != 0) return 'page-table-line-special'
        },
        cleanStatus () {
            this.statusFilter = ''
            this.load_quotations()
        },
        cleanQuote () {
            this.quoteNumber = ''
            this.load_quotations()
        },
        formatPrice(price){
            if (price == '0.00') return '--'
            return this.$options.filters.currency(Number(price))
        },
        getStatusText(statusFilter){
            if(!this.status) return ''
            for (let i = 0; i < this.status.length; i++){
                if (this.status[i].value == statusFilter){
                    return this.status[i].text
                }
            }
        },
        setStatusFilter (value) {
            this.statusFilter = value
            this.load_quotations()
        },
        setBuyerFilter (value) {
            this.buyerFilter = value
            this.load_quotations()
        },
        setQuoteFilter (text) {
            this.quoteNumber = text
            if (!this.loading) {
                this.loading = true
                setTimeout(() => {
                    this.load_quotations()
                },1500)
            }
        },
        getStatusColor (status) {
            if (status == 'PEDIDO GERADO') return 'color: #7683F6; font-weight: 700;'
            else if (status == 'CONGELADA') return 'color: gray; font-weight: 700;'
            else if (status == 'DIGITADA') return 'color: #30AA4C; font-weight: 700;'
            else if (status == 'NÃO DIGITADA') return 'color: #CD2A2A; font-weight: 700;'
        },
        capitalize (string) { return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase(); },
        getQuantityNumbers (item) {
            if (item.status_nome == 'PEDIDO GERADO') return 100
            return 0
        },
        updatePage (event) {
            this.page = event
            this.load_quotations()
        },
        calculate_perc(req, val) {
            const value = (val/req.qtd_pedidos)*100
            if (value > 100) return 100
            else if (!value) return 0
            return value
        },
        show_info(item, show) {
            this.$set(item, 'show_info', show)
        },
        open_sellers(item) {
            if(item.status_nome !== 'PEDIDO GERADO' && item.status_nome !== 'CONGELADA' && this.can('REQUEST', 'READ_SELLERS')) {
                this.request=item;
                this.show_manage_request_sellers = true;
            }
        },
        show_orders(item) {
            this.$router.push({ name: 'monitors-page', params: { tab: 'pedidos', request_id: item.numeroCotacao }})
        },
        show_report(item) {
            this.$router.push({ name: 'relatorios', params: { tab: 'analise', request_id: item.numeroCotacao }})
        },
        show_request(item) {
            this.$router.push({ name: 'request', params: { id: item.numeroCotacao }})
        },
        changeDeadline(request) {
            this.request = request
            this.show_deadline_modal = true;
        },
        next_page() {
            this.page++;
            this.reload_requests()
        },
        reload_requests() {
            this.load_quotations(this.filters.dataIni, this.filters.cliente, this.filters.statuses, this.filters.request_id)
        },
        update_statuses_filters(statuses) {
            this.filters.statuses = statuses
            this.load_quotations(this.filters.dataIni, this.filters.cliente, this.filters.statuses, this.filters.request_id)
        },
        isBloqued(contracts){
            return contracts.find(c => c.evento =="bloqueio")
        },
        blockAlert(){
            this.pendencieText = "Desculpe, sua conta esta bloqueada, entre em contato com o financeiro para resolver a pendencia."
            this.hidePendeciButton = true
            this.showPendencieNotification = true
        },
        async goToWinners(item) {
            const contracts = JSON.parse(this.storage_svc.get('contracts'))
            if (this.isBloqued(contracts)) {
                this.blockAlert()
                return
            }
            const resp = await this.$store.dispatch('user/fetch_stores')
            for (let index = 0; index < resp.length; index++) {
                if(resp[index].cliConexa && resp[index].cliConexa.evento == "bloqueio") {
                    this.blockAlert()
                    return
                }
            }
            if (item.cot_prorrogation && item.status == 1) {
                this.$swal.fire({
                    title: "Deseja iniciar o fechamento agora?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sim, fechar cotação"
                }).then(result => {
                    if (result.isConfirmed) this.recloseRequest( item.numeroCotacao )
                })
            } else if (item.cot_prorrogation && item.status == 4){
                this.$router.push({ name: 'request', params: { id: item.numeroCotacao }})
            } else this.$router.push({ name: 'cliente-monitores-vencedores', params: { tab: this.$route.params.tab, id: item.numeroCotacao }});
        },
        recloseRequest(id) {
            this.loading = true
            this.service.recreateQuotation({
                quotationId: id,
                close: true
            }).then(() => {
                this.$router.push({ name: 'request', params: { id: id }})
            }).catch(() => {
                this.loading = false
                this.$swal.fire(
                    "Erro!",
                    "Ocorreu um erro ao reabrir a cotação",
                    "error"
                );
            })
        },
        toggle_products(request_id, open = true) {
            this.request_id = request_id;
            this.show_request_products = open;
        },
        toggle_providers(request) {
            this.selectRequest = request;
            this.show_request_providers = true;
        },
        resend_notifications(req) {
            this.confirm_action({
                message: 'Confirma envio de notificação?',
                subtitle: 'Os vendedores serão novamente notificados sobre essa cotação!',
                callback: () => {
                    this.svc.notify_sellers(req.numeroCotacao).then(res => {
                        this.present_info('Notificações reenviadas com sucesso!')
                    })
                }
            })
        },
        load_events() {
            this.evt_svc.list({ etype: 'SEND_REQUEST' }).then(response => response.data).then(data => {
                this.list.forEach(req => {
                    data.forEach(evt => {
                        if(evt.resource_id == req.numeroCotacao) {
                            req.creation = evt
                        }
                    })
                })
            })
        },
        load_quotations(dataIni, cliente) {

            this.loading = true;

            this.service
                .list(this.page, dataIni, cliente, this.statusFilter, this.quoteNumber, this.page_size, this.buyerFilter)
                .then(response => response.data)
                .then(data => {
                    var statusMap = {};
                    if (data.cotacoes.data) {
                        for (var i = 0; i < data.cotacoes.data.length; i++) {
                            statusMap[data.cotacoes.data[i].numeroCotacao] =
                            data.cotacoes.data[i].status_nome;
                        }
                    }
                    this.service.persisteQuotationStatuses(statusMap);

                    this.list = Request.convert(data.cotacoes.data)

                    this.loading = false;
                    setTimeout(() => {
                        this.totalItems = data.cotacoes.total
                    }, 50)
                    this.load_events()
                })
                .catch(error => {
                    this.loading = false;
                    ErrorHandlerService.handle(error, this.$store, this.$store);
                });
        },
        update_breadcrumbs() {
            this.$store.dispatch('set_breadcrumbs', [
                {
                    name: "Painel de Operações",
                    route: 'monitors-page'
                },
                {
                    name: "Cotações",
                    route: 'monitors-page'
                }
            ]);
        },
        replicate_quotation(quotation){

            this.$swal.fire({
                title: "Confirma a replicação das solicitações?",
                text: "Suas solicitações serão geradas no monitor de ressuprimento!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Sim, realizar replicação!",
                cancelButtonText: "Não!"
            }).then(result => {
                if (result.value) {
                    this.loading = true;
                    this.service.replicate_quotantion(quotation.numeroCotacao)
                    .then(response => response.data)
                    .then(data => {
                        this.loading = false;
                        this.$swal.fire(
                            "Realizado!",
                            "Sua replicação foi gerada com sucesso!",
                            "success"
                        );
                    })
                    .catch(error => {
                        this.loading = false;
                        ErrorHandlerService.handle(error, this.$store, this.$store);
                    });
                }
            });
        },
        getBuyers(){
            this.service.getBuyers(localStorage.getItem('cli_id')).then(response => {
                response.data.usuarios.forEach(item => this.buyers.push({text: item.usu_nome, value: item}))
            })
        }
    },
    computed: {
        ...mapState([ 'user' ]),
        ...mapState([ 'statuses' ]),
        ...mapGetters({ attendance: 'user/attendance' })
    },
    created() {
        this.request_id = this.$route.params.id
        this.update_breadcrumbs()
        setTimeout(() => {
            try {
                let contracts = JSON.parse(this.storage_svc.get('contracts'))
                const contract = contracts[0]
                
                if (this.isBloqued(contracts)) this.blockAlert();
                else if ( contract && contract.cobracas.length > 0){
                    let lastBill = contract.cobracas[contract.cobracas.length - 1]
                    const dueDate = new Date(lastBill.dueDate)
                    const dateNow = new Date()
                    dateNow.setHours(0)
                    dateNow.setMinutes(0)
                    dateNow.setMilliseconds(0)

                    if ((dueDate - dateNow) < -86300000 && (dueDate - new Date()) < -147500000){
                        this.pendencieText2 = "Se o boleto ja tiver sido pago, por favor desconsidere essa mensagem"
                        if (localStorage.getItem('AlertCount')=='0') this.showPendencieNotification = true
                    }
                    else if (dueDate < dateNow) {
                        this.pendencieText = "Se o boleto ja tiver sido pago, por favor desconsidere essa mensagem"
                        this.pendecieTitle = 'Identificamos um boleto que vence hoje.'
                        if (localStorage.getItem('AlertCount')=='0') this.showPendencieNotification = true
                    }
                    setTimeout(() => localStorage.setItem('AlertCount', '1'), 5000);
                }
                if (this.perms){
                this.perms.forEach(element => {
                    if (element.action == 'READ' && element.resource == 'MY_QUOTE') this.myQuoteOnly = true
                    });
                }
            } catch (error) {
              console.log(error);
            }
            if (this.myQuoteOnly) this.buyerFilter = { usu_id: localStorage.getItem('usu_id') }
            else this.getBuyers()
            this.load_quotations()
        }, 1500);

    }
}
</script>
<style lang="scss" scoped>
@import "../monitors.page";
.page{
    font-size: 1vw;
}
.page-route-select{
    display: flex;
    align-items: center;
}
.page-route-select-text{
    font-weight: 400;
    font-size: 1.36em;
    color: #ABABAB;
    margin-right: 20px;
    cursor: pointer;
}
.page-route-select-icon{
    color: var(--primary-color);
    margin-right: 20px;
}
.page-title{
    font-weight: 600;
    font-size: 2.72em;
    letter-spacing: 0.15px;
    color: #605F5F;
    margin-top: 40px;
}
.table-placeholder{
    min-height: 65vh;
    margin-top: 4vh;
}
.page-table-header{
    display: flex;
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    padding: 1vh 0;
    margin-bottom: 3vh;
}
.page-table-colum-width{ width: 10%; }
.page-table-colum-width-number{ width:  7%; }
.page-table-colum-width-company{ width:  15%; }
.page-table-colum-width-products{ 
    width: 10%; 
    display: flex;
    justify-content: space-evenly;
}
.page-table-colum-width-competitors{ 
    width: 10%; 
    display: flex;
    justify-content: space-evenly;
}
.page-table-colum-width-actions{ width: 14%; }

.page-table-header-text{
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
    text-align: center;
}
.page-table-row{
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 1.5vh;
    font-weight: 300;
    font-size: 1.2em;
    color: #605F5F;
}
.colum-divider{
    border-right: 0.5px solid #DDE5E9;
}
.page-table-colum-pogress{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.table-actions-colum{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.circle-content{
    margin: auto;
    cursor: default;
}
.table-product-button{
    background: rgba(23, 162, 184, 0.05);
    border-radius: 8px;
    width: 80%;
    margin: auto;
    cursor: pointer;
    color: #17A2B8;
}

.product-info-item{
    color: #30AA4C;
    background: rgba(42, 205, 114, 0.1);
    border-radius: 8px;
    padding: 0 7%;
}
.sellers-info-item{
    color: #17A2B8;
    background: rgba(23, 162, 184, 0.05);
    border-radius: 8px;
    padding: 0 10%;
}
.request-tab-mobile-header{
    display: none;
}
.table-winners-button{
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    border-radius: 8px;
    width: 30%;
    color: white;
    margin: 0 auto;
    padding-top: 5px;
    cursor: pointer;
}
.table-winners-button:hover{
    background-color: white;
    color: var(--primary-color);
}
.table-info-icon{
    margin: 0 auto;
    padding-top: 5px;
    color: var(--primary-color);
    cursor: default;
}
.table-action-icon{ cursor: pointer; }
.seemore-button-containear{
  display: flex;
  justify-content: center;
  margin-bottom: 70px;
}
.page-filters-containear{
    display: flex;
    margin-top: 3vh;
    align-items: flex-end;
}
.page-filters-inputs{
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-left: 3vw;
    align-items: flex-end;
    padding-right: 2vw;
}
.selected-filters-containear{
    display: flex;
    margin-top: 2vh;
}
.selected-value{
    background: #FCF9F7;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    display: flex;
    font-weight: 500;
    font-size: 1.27em;
    letter-spacing: 0.15px;
    color: var(--primary-color);
    padding: 1vh;
    margin-right: 2vw;
}
.close-select-value{
    cursor: pointer;
    margin-left: 1.5vw
}
.page-table-line-special{
  background: #F7F7F7;
}
.mobile{
    display: none;
}

@media only screen and (max-width: 800px) {
    .mobile{display: unset;}
    .page{ font-size: 2vw; }
    .page-table-header{display: none;}
    .page-table-row{
        flex-direction: column;
        gap: 1vh;
        font-size: 1.5em;
        margin-bottom: 1.5vh;
        padding: 1.5vh 0;
    }
    .desktop-only{
        display: none;
    }
    .show-filter-mobile{
        width: 40vw;
        margin-right: auto;
        display: flex !important;
    }
    .colum-divider{
        width: 58%;
        flex: unset;
        display: flex !important;
        justify-content: space-between;
        padding-right: 2vw;
        border-right: none;
    }
    .mobile-colum-left{
        width: 42%;
        text-transform: capitalize;
    }
    .request-tab-mobile-header{
        display: block;
        font-weight: 700;
        font-size: 1em;
        color: #605f5f;
    }
    .table-winners-button{margin: 0; width: 15%; margin-right: 2vw;}
    .page-filters-containear{
        flex-direction: column;
        align-items: flex-start;
    }
    .page{ font-size: 3vw; }
    .page-table-mobile-row{
        width: 100%;
        display: flex !important;
        align-items: center;
    }
    .mobile-cot-row{
        width: 100%;
        display: flex;
        gap: 2vw;
        margin-bottom: 2.5vh;
        margin-top: -1vh;
    }
    .mobile-action-row{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }
    .page-filters-inputs{
        flex-direction: column;
        margin-left: 0;
        width: 90%;
    }
    .desktop{display: none !important; }

    .table-product-button{
        width: 20vw;
        margin: 0;
    }
    .table-competitor-button{
        width: 20vw;
        margin: 0;
    }
    .table-actions-colum{
        gap: 6vw;
        width: 100%;
    }
}

</style>

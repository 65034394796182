<template>
  <div class="modal-mask">
	<div class="modal-content-container">
	  <div class="add-product__main-container">
	    <div class="new-product__header-container">
          <span class="new-product__header-title">
            Adicionar Produto
          </span>
          <span @click="closeDialog" class="material-icons new-product__closebtn-icon">
            cancel
          </span>
        </div>
		<StandardButton class="standard-btn" :action="closeDialog" :iconName="'arrow_back'" :text="'Voltar'"/>
		<form @submit.prevent="load_products()">
		  <div class="standard-buttons__wrapper">
			<div class="new-product__input-wrapper">
              <span class="new-product__input-title">Buscar produto</span>
		      <input :placeholder="'Pesquisar produto'" v-model="product_name" @keyup="nameSearch()" class="new-product__input">
		    </div>
			<div class="new-product__input-wrapper">
              <span class="new-product__input-title">Categorias</span>
			  <cc-select-v2 class="select-input" :tracker="'cat_id'" :text="'cat_descricao'" :options="categories" v-model="cat"></cc-select-v2>
		    </div>
			<div class="new-product-300-filter" v-on:click="filter300 = !filter300; load_products()">
				<span v-if="filter300" class="material-icons-outlined" style="color: var(--primary-color);">check_box</span>
				<span v-else class="material-icons-outlined">check_box_outline_blank</span>
				Apenas os 300+
			</div>
			<StandardButton class="new-product-btn" :action="() => { new_product = {}; showNewProductModal = true }" :iconName="'add_circle'" :text="'Novo Produto'"/>
		  </div>
		</form>
		<cc-loader v-if="loading" style="position: fixed; top: 50%; right: 46%;"/>
		<div class="overflow-container">
          <div class="modal-table-container">
            <div class="modal-table-header">
              <div class="modal-table-colum product-width">Produto</div>
              <div class="modal-table-colum package-width">Embalagem</div>
              <div class="modal-table-colum code-width">Código</div>
            </div>
            <div v-for="(product, idx) in products"
			  	 :key="'prod-' + product.id"
				 :class="{ active : product.focused == true, 'page-table-line-special': idx % 2 != 0}">
              <div class="modal-table-row">
                <div :class="{ added : product.added }" class="modal-table-colum modal-table-separator product-width">
					<div class="mobile-product-name" style="display: flex; align-items: center;" v-if="!add_only">
						<input min="0"
							type="number"
							:data-idx="idx"
							ref="product-amount"
							class="new-quotation__new-product__input product-amount"
							:class="{ active : product.new_amount && product.new_amount > 0 && product.is_saved == true, productError: product.error, productAdded: product.added}"
							v-model.number="product.new_amount"
							@focus="is_focused(idx, product)"
							@keydown="process_event($event, product)"
							@blur="add_product({ ...product, checked: product.new_amount > 0 }, product)"
							:key="'qtd' + product.id" />
						{{product.name}}
						</div>
						<cc-checkbox v-if="add_only"
									class="mobile-product-name"
									:checked="product.saved"
									:disabled="product.saved"
									:label="product.name.toUpperCase()" @change="(evt) => {
										if(!adding){
										product.checked = evt.target.checked
										add_product(product)}
									}"/>
				</div>

                <div class="modal-table-colum modal-table-separator package-width desktop">
					{{ product.purchase_unit }}
				</div>

                <div class="modal-table-colum modal-table-separator code-width desktop">
					{{ product.ean }}
				</div>

				<div class="mobile-product-info">
					<div class="add-product-mobile-header">Embalagem</div>
					{{ product.purchase_unit }}
					<div class="add-product-mobile-header">Código</div>
					{{ product.ean }}
				</div>

              </div>
            </div>
          </div>
		<Paginacao classes="orange justify-end"
					:items_by_page_value="items_by_page_value"
					:total="total_items"
					:page="page"
					:page_limit="10"
					v-on:change="update_page($event)">
		</Paginacao>
        </div>
	  </div>
	</div>
	<new-product-modal v-if="new_product && showNewProductModal"
					   :closeDialog="closeNewProductModal"
            		   :product="new_product"
            		   :to_request="to_request"
            		   :categories="categories"
            		   @emit_product="emit_product"
            		   @reload_products="load_products"
            		   @close="new_product = null" />
  </div>
</template>

<script>

import QuotationService from "@/services/QuotationService";
import ErrorHandlerService from '@/services/ErrorHandlerService';
import foodService from "@/services/v3/foodService";
//import Pagination from "@/components/cliente/base-components/Pagination";
import Paginacao from "@/components/cliente/base-components/Pagination";
import NewProductModal from "@/modals/new-product/new-product.modal";
import { perm_mixin } from '@/mixins/permission.mixin'
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import StandardButton from "@/components/ui/buttons/Standard-Button.vue";

export default {
    mixins: [ perm_mixin, loaderMixin ],
    props: ["quotation", "payment_types", "add_only", "to_request", "closeDialog" ],
	components: {
		Paginacao,
		StandardButton,
        'new-product-modal': NewProductModal
   },
  	data() {
		return {
			showNewProductModal: true,
			products: [],
			cancelSource: null,
			page: 1,
			loading: true,
			total_items : 0,
			items_by_page_value: 15,
            adding: false,
			categories: [],
			product_name: "",
            cat: "",
            new_product: null,
			filter300: false,
			service: new QuotationService(),
			foodService: new foodService(),
			searchStamp: undefined,
			modal: {
				title: "adicionar produto",
				subtitle: 'Escolha um item da base padrao do Club ou inclua um novo produto',
				icon_title: 'fas fa-cart-plus',
				cancel_text: 'Voltar',
				style: {
					width: "90%",
                    height: "700px"
				}
			},
		};
	},
	watch: {
		'cat': function() {
			this.load_products()
		}
	},
  	methods: {
		closeNewProductModal: function() {
          this.showNewProductModal = false;
        },
		cancelSearch () {
			if (this.cancelSource) {
				this.cancelSource.cancel('Start new search, stop active search');

			}
		},
		process_event(e, product) {
			let key = getKey(e)

			let idx = parseInt(e.target.dataset.idx);

			let inputs = this.$refs["product-amount"];
			inputs.sort((a, b) => {
				let pos1 = parseInt(a.dataset.idx);
				let pos2 = parseInt(b.dataset.idx);
				if (pos1 > pos2) return 1;
				if (pos2 > pos1) return -1;
				return 0;
			})

			let next_idx = idx+1 == inputs.length ? 0 : idx+1;

			let prev_idx = idx == 0 ? inputs.length-1 : idx-1;

			function getKey(e) {

				if (e.key) return e.key;

				let keyFromCode = String.fromCharCode(e.keyCode);

				if (keyFromCode) return keyFromCode;

				// add here the tricky keys that you use in your app
				if (e.keyCode === 13) return "Enter";
				if (e.keyCode === 16) return "Shift";
				if (e.keyCode === 8) return "Tab";
				// etc
			}

			if(key == "ArrowUp") {
				e.preventDefault();
				if(product.new_amount > 0){
                    product.checked = true;
                    // this.add_product(product);
                };
				inputs.filter(i => prev_idx == i.dataset.idx).forEach(i => i.focus());
			} else if(key == "ArrowDown") {
				e.preventDefault();
				if(product.new_amount > 0){
                    product.checked = true;
                    // this.add_product(product);
                };
				inputs.filter(i => next_idx == i.dataset.idx).forEach(i => i.focus());
			} else if(key == "Enter") {
				e.preventDefault();
				if(product.new_amount > 0){
                    product.checked = true;
                    this.add_product(product, product);
                };
				inputs.filter(i => idx == i.dataset.idx).forEach(i => i.focus());
			} else if(key == "Tab") {
				e.preventDefault();
				if(product.new_amount > 0){
                    product.checked = true;
                    // this.add_product(product);
                };
				inputs.filter(i => next_idx == i.dataset.idx).forEach(i => i.focus());
			}
		},
		close() {
			this.$emit("close");
		},
		is_focused(idx, product){
			let inputs = this.$refs["product-amount"];
			this.products.forEach(p => p.focused = false)
			// if(inputs && inputs[idx].is(":focus")) {
				this.$set(product, "focused", true);
				this.$forceUpdate();
			// }
		},
		load_provider_default_categories() {
			this.service.load_provider_default_categories()
			.then(response => response.data)
			.then(data => {
				this.categories = data.categorias;
			})
		},
		nameSearch(){
			const stamp = Date.now()
			this.searchStamp = stamp
			setTimeout(() => {
				if(this.searchStamp == stamp){
					this.load_products()
				}
			}, 500);
		},
		load_products() {
			this.loading = true
			this.cancelSearch(() => { this.loading = true });
			if (this.selectDate) {
				var day = new Date();
				day.setDate(day.getDate()-this.selectDate);
			} else var day = undefined

			let params = {
				fu: '',
				start_at: day,
				page: this.page,
				limit: this.filter300 ? '300' : '',
				name: this.product_name,
				category_id: this.cat.cat_id
			};

			// if (this.filters && this.filters.category) params.category_id = this.filters.category.id
			// if (this.filters && this.filters.name) params.name = this.filters.name
			this.foodService.list(params, this.cancelSource).then(response => response.data).then(data => {
				this.loading = false
				if (data.data) {
					this.products = data.data
					this.total_items = data.meta.total
				}
				else {
					this.products = data.sort((a,b)=> a.name > b.name ? 1 : -1)
					this.total_items = 0
				}

			}).catch(error => {
				ErrorHandlerService.handle(error, this.$store);
			});
		},
		build_product(prod) {
			return {
				cliente: this.service.getClientId(),
				pro_id: prod.id,
				pro_descricao: prod.name,
				pro_ean: prod.ean,
				pro_observacao: prod.pro_observacao,
				pro_embalagem_text: prod.packing,
				pro_unidade_compra: prod.purchase_unit,
				pro_generico: prod.is_generic,
				categoria_select: { cat_id: 2 }
			};
		},
		add_product(prod, product_ref = {}) {
			console.log('asdasd');
			var payload = this.build_product(prod)
			console.log(payload);
            this.adding = true;
            if(prod.checked && !prod.is_saved) {
				this.loading = true
                this.service.insert_client_product(payload)
                .then(response => response.data)
                .then(data => {
                    prod.pro_id = data.produto
                    prod.added = true
                    prod.is_saved = true
                    product_ref.pro_id = data.produto
                    product_ref.added = true
                    product_ref.is_saved = true
                    this.adding = false
					this.loading = false
                    this.$emit("add_product", prod)
                    this.$set(prod, 'checked', true)
                    this.$set(prod, 'saved', true)
                }).catch(error => {
                    this.adding = false
					this.loading = false
                    if(error.response.data.mensagem == "Código já existe") {
                        prod.pro_id = error.response.data.pro_id
                        prod.is_saved = true
						product_ref.error = true
                        product_ref.is_saved = true
						this.snack('Produto já requisitado', 'error')
                        this.$emit("add_product", prod);
                        this.$set(prod, 'saved', true)
                    } else if(error.response.data.mensagem == "Erro com categoria") {
                        const notification = {
                            type: 'error',
                            message: 'Cliente não trabalha com essa categoria de produto.'
                        }
                        this.$store.dispatch('notification/add', notification)
                        product_ref.new_amount = 0
                        product_ref.error = true
                    } else {
                        this.$set(prod, 'saved', false)
                    }
                    this.$forceUpdate()
                    ErrorHandlerService.handle(error, this.$store);
                });
            }

        },
        emit_product(prod) {
            this.$emit("add_product", prod)
        },
		update_page(page) {
			this.page = page;
			this.load_products();
		}
	},
	mounted() {
		this.load_provider_default_categories();
		this.load_products();
	}
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
  font-size: 1vw;
}
.modal-content-container{
  background-color: white;
  min-width: 50vw;
  border-radius: 12px;
}
.add-product__main-container {
  width: 80vw;
  height: 110vh;
}
.new-product__header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px 10px 0px 0px;
  background-color: var(--primary-color);
}
.add-product-mobile-header{
	display: none;
}
.new-product__header-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 2.5em;
}
.new-product__closebtn-icon {
  color: #ffffff;
  font-size: 3em;
  cursor: pointer;
}
.standard-btn {
  width: 10vw;
  margin: 1em 2em;
}
.new-product-btn {
  margin-top: 1.4em;
  margin-left: 1em;
}
.standard-buttons__wrapper {
  display: flex;
  align-items: flex-end;
  margin: 2em 1.2em;
}
.new-product__input-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}
.new-product__input-title {
  color: #898989;
  font-weight: 400;
  font-size: 1.8em;
  letter-spacing: 0.15px;
  margin-bottom: 0.3em;
}
.new-product__input {
  width: 20vw;
  padding: 8px 10px;
  border: 1px solid #c2c2c3;
  border-radius: 5.23px;
  font-weight: 400;
  font-size: 1.2em;
  color: #A1A1A1;
}
.new-quotation__new-product__input {
  width: 5vw;
  margin-right: 5px;
  height: 4vh;
  padding: 0px 10px;
  border: 1px solid #c2c2c3;
  border-radius: 5.23px;
  font-weight: 400;
  font-size: 1.2em;
  color: #A1A1A1;
}

.select-input {
  width: 20vw !important;
}
.modal-table-container{
  min-height: 40vh;
  margin: 2em;
}
.modal-table-header{
  display: flex;
  background: #FFFEFC;
  border: 0.5px solid #E5E5E5;
  padding: 1vh 0;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 17px;
  color: #605F5F;
  margin-bottom: 1vh;
}
.modal-table-colum{
  padding-left: 0.5vw;
}
.modal-table-row{
  display: flex;
  font-weight: 300;
  font-size: 1.2em;
  color: #605F5F;
  margin-bottom: 2vh;
}
.overflow-container{
  overflow-x: auto;
  max-height: 60vh;
}
.seemore-button-containear{
  display: flex;
  margin: 0 auto;
  overflow-x: auto;
  max-width: 50vw;
}
.page-table-line-special{
  background: #F7F7F7;
}
.product-width {
  width: 60%;
}
.package-width {
  width: 20%;
}
.code-width {
  width: 20%;
}
.mobile-product-info{
	display: none;
}
.new-product-300-filter{
	width: 20%;
	display: flex;
	justify-content: center;
	gap: 5%;
	align-items: center;
	color: #898989;
    font-weight: 400;
    font-size: 1.4em;
	cursor: pointer;
}
.productAdded{background-color: lightgreen;}
.productError{background-color: lightcoral;}

@media only screen and (max-width: 800px) {
	.modal-mask{font-size: 2vw;}
  .standard-buttons__wrapper {
	flex-wrap: wrap;
  }
  .standard-btn {
	display: none;
  }
  .new-product-btn {
	width: 60vw;
	margin: 0.5em auto;
  }
  .mobile-product-info{
	display: flex;
	justify-content: space-between;
	align-items: center;
  }
  .new-product__input {
	width: 47vw;
  }
  .select-input {
	width: 47vw !important;
  }
  .new-product-300-filter{
	width: 50vw;
  }
  .new-product__table-container {
	margin: 1em;
    max-height: 25vh;
  }
  .overflow-container {
	max-height: 65vh;
	overflow-y: auto;
  }
  .modal-table-header{display: none;}
  .modal-table-row{
	flex-direction: column;
	gap: 1vh;
  }
  .modal-table-colum{
	flex: unset;
	width: 100%;
	display: flex;
	justify-content: space-between;
	text-align: end;
  }
  .add-product-mobile-header{
	display: flex;
	font-weight: 700;
	font-size: 1.2em;
  }
  .mobile-product-name{
	font-weight: bold;
  }
  .desktop{
	display: none;
  }
}

@media only screen and (max-width: 500px) {
	.modal-mask{font-size: 3vw;}
	.add-product__main-container{
		width: 110vw
	}
	.form-check-label{width: 50%}
}

	@import "./add-product.modal";
</style>
